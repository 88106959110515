import { Node, mergeAttributes, Mark, markPasteRule, nodeInputRule } from '@tiptap/core'

import CustomImageNodeView from '@/components/CustomImageNodeView'

import { Extension, VueNodeViewRenderer } from '@tiptap/vue-3'
import Dragable from '@/components/Dragable'
import TextStyle from '@tiptap/extension-text-style'
import FontFamily from '@tiptap/extension-font-family'
import Heading from '@tiptap/extension-heading'
import Document from '@tiptap/extension-document'
import Paragraph from '@tiptap/extension-paragraph'
import Text from '@tiptap/extension-text'
import Italic from '@tiptap/extension-italic'
import BlockQuote from '@tiptap/extension-blockquote'
import Bold from '@tiptap/extension-bold'
import TextAlign from '@tiptap/extension-text-align'
import Image from '@tiptap/extension-image'
import DropCursor from '@tiptap/extension-dropcursor'
import Table from '@tiptap/extension-table'
import TableRow from '@tiptap/extension-table-row'
import TableCell from '@tiptap/extension-table-cell'
import TableHeader from '@tiptap/extension-table-header'
import DonutChart from '@/components/charts/DonutChart'
import TwitterEmbed from '@/components/TwitterEmbed'
import History from '@tiptap/extension-history'
import Underline from '@tiptap/extension-underline'
import { Color } from '@tiptap/extension-color'
import Link from '@tiptap/extension-link'
import Focus from '@tiptap/extension-focus'
import HardBreak from '@tiptap/extension-hard-break'
import BubbleMenu from '@tiptap/extension-bubble-menu'
import BulletList from '@tiptap/extension-bullet-list'
import ListItem from '@tiptap/extension-list-item'
import OrderedList from '@tiptap/extension-ordered-list'

import { Plugin, PluginKey } from 'prosemirror-state'
import { find } from 'linkifyjs'

const DragableNode = Node.create({
  name: 'draggableItem',
  group: 'block',
  content: 'block+',
  draggable: true,
  parseHTML() {
    return [
      {
        tag: '*[data-type="draggable-item"]'
      }
    ]
  },

  renderHTML({ HTMLAttributes }) {
    return ['div', mergeAttributes(HTMLAttributes, { 'data-type': 'draggable-item' }), 0]
  },

  addNodeView() {
    return VueNodeViewRenderer(Dragable)
  }
})
const CustomUnderline = Underline.extend({
  addAttributes() {
    return {
      'data-color': {
        default: 'black',
        renderHTML: (attributes) => {
          if (!attributes['data-color']) {
            return {}
          }

          return {
            'data-color': attributes['data-color'],
            style: `text-decoration:underline;text-decoration-color:${attributes['data-color']}`
          }
        }
      }
    }
  },

  renderHTML({ HTMLAttributes }) {
    return [
      'u',
      mergeAttributes(this.options.HTMLAttributes, HTMLAttributes, {
        class: ''
      }),
      0
    ]
  },
  addCommands() {
    return {
      setUnderlineColor:
        (opts) =>
        ({ commands }) => {
          console.log('here')
          let underLineColor = opts?.color
          if (underLineColor) {
            return commands.updateAttributes('underline', {
              'data-color': underLineColor
            })
          }
        }
    }
  }
})

const DonutChartNode = Node.create({
  name: 'donutChart',
  group: 'block',
  content: 'inline*',
  selectable: false,
  draggable: false,

  addAttributes() {
    return {
      chartLabels: {
        default: ['labels']
      },
      chartDatasets: {
        default: [{ data: [10], backgroundColor: ['green'] }]
      },
      title: {
        default: ''
      },
      valueSuffix: {
        default: ''
      },
      valuePrefix: {
        default: ''
      },
      chartType: {
        default: 'donut'
      }
    }
  },

  addCommands() {
    return {
      updateChart:
        (options) =>
        ({ commands }) => {
          const { labels, datasets } = options

          return commands.updateAttributes('donutChart', {
            ...options,
            chartLabels: JSON.stringify(labels),
            chartDatasets: JSON.stringify(datasets)
          })
        },

      setChart:
        (options) =>
        ({ commands }) => {
          const { labels, datasets } = options

          return commands.insertContent({
            type: 'donutChart',
            attrs: {
              ...options,
              chartDatasets: [JSON.stringify(datasets)],
              chartLabels: [JSON.stringify(labels)]
            }
          })
        }
    }
  },

  parseHTML(element) {
    return [
      {
        tag: 'DonutChart'
      }
    ]
  },

  renderHTML({ HTMLAttributes }) {
    /**
     * This is a temporary hack for some reason '' gets parsed into 0
     */
    let attrs = HTMLAttributes
    if (!HTMLAttributes.title) {
      attrs.title = ' '
    }
    if (!HTMLAttributes.valuePrefix) {
      attrs.valuePrefix = ' '
    }
    if (!HTMLAttributes.valueSuffix) {
      attrs.valueSuffix = ' '
    }

    let el = ['DonutChart', mergeAttributes(attrs), 0]

    return el
  },

  addNodeView(e) {
    return VueNodeViewRenderer(DonutChart)
  }
})

const Iframe = Node.create({
  name: 'iframe',
  group: 'block',
  content: 'block',
  selectable: true,
  draggable: true,
  addAttributes() {
    return {
      src: {
        default: null
      },
      frameborder: {
        default: 0
      },
      allowfullscreen: {
        default: true
      },
      allow: {
        default: 'accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture'
      },
      width: {
        default: '100%'
      },

      height: {
        default: '400px'
      },
      scrolling: {
        default: 'no'
      }
    }
  },
  parseHTML() {
    return [{ tag: 'iframe' }]
  },
  renderHTML({ HTMLAttributes }) {
    return [
      'div',
      { style: 'display:flex;justify-content:center;', contenteditable: true },
      ['iframe', mergeAttributes(HTMLAttributes), 0]
    ]
  },
  addCommands() {
    return {
      setYoutubeIframe:
        (options) =>
        ({ commands }) => {
          {
            const youtubeId = options.url

            return commands.insertContent({
              type: 'iframe',
              attrs: {
                src: `https://www.youtube.com/embed/${youtubeId}`,
                frameborder: 0,
                allowfullscreen: true,
                allow: 'accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture',
                width:
                  options.width && options.width.toString().length ? `${options.width}` : '100%',
                height:
                  options.height && options.height.toString().length ? `${options.height}` : '400px'
                // You can set the width and height here also
              }
            })
          }
        },
      setIframeFromUrl:
        (options) =>
        ({ commands }) => {
          {
            const url = options.url

            return commands.insertContent({
              type: 'iframe',
              attrs: {
                src: url,
                frameborder: 0,
                allowfullscreen: true,
                allow: 'accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture',
                width:
                  options.width && options.width.toString().length ? `${options.width}` : '100%',
                height:
                  options.height && options.height.toString().length ? `${options.height}` : '400px'

                // You can set the width and height here also

                // You can set the width and height here also
              }
            })
          }
        },
      setIframeFromEmbedContent:
        (options) =>
        ({ commands }) => {
          {
            const url = options.url
            return commands.insertContent(url)
          }
        },

      setFbIframe:
        (options) =>
        ({ commands }) => {
          const fbLink = options.url
          return commands.insertContent({
            type: 'iframe',
            attrs: {
              src: `https://www.facebook.com/plugins/post.php?href=${fbLink}`,
              frameborder: 0,
              allowfullscreen: true,
              width: options.width && options.width.toString().length ? `${options.width}` : '100%',
              height:
                options.height && options.height.toString().length ? `${options.height}` : '400px',
              allow: 'accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture'
              // You can set the width and height here also
            }
          })
        },
      setFbIframeFromEmbedCode:
        (options) =>
        ({ commands }) => {
          const iframe = options.url

          return commands.insertContent(iframe)
        },
      setTwitterIframe:
        /*** No LONGER USING THIS KEEPING IT FOR REPORTS THAT ALREADY DO HAVE IT */


          (options) =>
          ({ commands }) => {
            const twitterLink = options.url
            return commands.insertContent({
              type: 'iframe',
              attrs: {
                src: `https://twitframe.com/show?url=${twitterLink}`,
                frameborder: 0,
                allowfullscreen: true,
                width:
                  options.width && options.width.toString().length ? `${options.width}` : '100%',
                height:
                  options.height && options.height.toString().length
                    ? `${options.height}`
                    : '400px',
                allow: 'accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture'
                // You can set the width and height here also
              }
            })
          }
    }
  }
})
const TwitterEmbedNode = Node.create({
  name: 'twitterEmbed',
  group: 'block',
  content: 'block',
  selectable: true,
  draggable: true,
  addAttributes() {
    return {
      embedContent: {
        default: null
      },
      justifyContent: {
        default: 'center'
      },
      width: {
        default: 550
      }
    }
  },

  parseHTML() {
    return [
      {
        tag: 'TwitterEmbed'
      }
    ]
  },

  renderHTML({ HTMLAttributes }) {
    let el = [
      'TwitterEmbed',
      mergeAttributes(HTMLAttributes, {
        contenteditable: true
      }),
      0
    ]

    /*     let el = [
      'div',
      { 'data-type': 'draggable-item' },
      ['TwitterEmbed', mergeAttributes(HTMLAttributes, { contenteditable: false })]
    ] */

    return el
  },

  addNodeView() {
    return VueNodeViewRenderer(TwitterEmbed)
  },

  addCommands() {
    return {
      setTwitterEmbedContentOrUrl:
        (options) =>
        ({ commands }) => {
          const embedContent = options.url
          return commands.insertContent({
            type: 'twitterEmbed',
            attrs: {
              embedContent: JSON.stringify(decodeURIComponent(embedContent)),
              justifyContent: options.justifyContent
            }
          })
        }
    }
  }
})
const CustomImage = Image.extend({
  addAttributes() {
    return {
      src: {
        default: null
      },
      alt: {
        default: null
      },
      title: {
        default: null
      },
      width: {
        default: '200px'
      },
      height: {
        default: '300px'
      },
      changeable: {
        default: false
      }
    }
  },
  renderHTML({ HTMLAttributes }) {
    return [
      'img',
      mergeAttributes(this.options.HTMLAttributes, HTMLAttributes, {
        'object-fit': 'contain'
      })
    ]
  },
  addCommands() {
    return {
      setImage:
        (options) =>
        ({ commands }) => {
          return commands.insertContent({
            type: 'image',
            attrs: { ...options }
          })
        },
      setImageSize:
        (options) =>
        ({ commands }) => {
          return commands.updateAttributes('image', {
            attrs: { ...options }
          })
        }
    }
  }
})

const CustomLink = Link.extend({
  addOptions() {
    return {
      openOnClick: true,
      linkOnPaste: true,
      HTMLAttributes: {
        target: '_blank',
        rel: 'noopener noreferrer nofollow'
      }
    }
  },

  addAttributes() {
    return {
      href: {
        default: null
      },
      target: {
        default: this.options.HTMLAttributes?.target
      }
    }
  },
  addProseMirrorPlugins() {
    const plugins = []

    plugins.push(
      new Plugin({
        key: new PluginKey('handleClickLink'),
        props: {
          handleClick: (view, pos, event) => {
            const attrs = this.editor.getAttributes('link')
            const link = event.target?.closest('a')

            if (link && attrs.href) {
              window.open(attrs.href, attrs.target)

              return true
            }

            return false
          }
        }
      })
    )

    if (this.options.linkOnPaste) {
      plugins.push(
        new Plugin({
          key: new PluginKey('handlePasteLink'),
          props: {
            handlePaste: (view, event, slice) => {
              const { state } = view
              const { selection } = state
              const { empty } = selection

              if (empty) {
                return false
              }

              let textContent = ''

              slice.content.forEach((node) => {
                textContent += node.textContent
              })

              const link = find(textContent).find(
                (item) => item.isLink && item.value === textContent
              )

              if (!textContent || !link) {
                return false
              }

              this.editor.commands.setMark(this.type, {
                href: link.href
              })

              return true
            }
          }
        })
      )
    }

    return plugins
  }
})
const FontSize = Extension.create({
  name: 'fontSize',
  addOptions() {
    return {
      types: ['textStyle']
    }
  },

  addGlobalAttributes() {
    return [
      {
        types: ['textStyle'],
        attributes: {
          fontSize: {
            default: null,
            parseHTML: (element) => element.style.fontSize.replace(/['"]+/g, ''),
            renderHTML: (attributes) => {
              if (!attributes.fontSize) {
                return {}
              }

              return {
                style: `font-size: ${attributes.fontSize}`
              }
            }
          }
        }
      }
    ]
  },

  addCommands() {
    return {
      setFontSize:
        (fontSize) =>
        ({ chain }) => {
          return chain().setMark('textStyle', { fontSize }).run()
        },
      unsetFontSize:
        () =>
        ({ chain }) => {
          return chain().setMark('textStyle', { fontSize: null }).removeEmptyTextStyle().run()
        }
    }
  }
})
const CustomTable = Table.configure({ resizable: true, allowTableNodeSelection: true })
const CustomFocus = Focus.configure({
  mode: 'all',
  className: 'has-focus'
})
const CustomBubbleMenu = BubbleMenu.configure({
  shouldShow: ({ editor, view, state, oldState, from, to }) => {
    // only show the bubble menu for images and links

    return editor.isActive('image') || editor.isActive('link') || editor.isActive('donutChart')
  },
  element: document.querySelector('#main')
})

export {
  FontFamily,
  Heading,
  Document,
  Paragraph,
  Text,
  Italic,
  Bold,
  TextAlign,
  TextStyle,
  DragableNode,
  CustomImage,
  Image,
  DropCursor,
  Table,
  TableCell,
  TableRow,
  TableHeader,
  DonutChartNode,
  Iframe,
  BlockQuote,
  TwitterEmbedNode,
  History,
  Underline,
  CustomUnderline,
  Color,
  CustomLink,
  CustomTable,
  CustomFocus,
  HardBreak,
  CustomBubbleMenu,
  BulletList,
  OrderedList,
  ListItem,
  FontSize
}

const unwrap = (obj) => JSON.parse(JSON.stringify(obj))
export function isNumberOrFloat(value) {
  let val = Number(value)
  return typeof Number(val) == 'number' && !isNaN(val)
}

export { unwrap }

// get image as blob
import AxiosClient from './AxiosClient'

export function fileReaderAsPromise(blob) {
  return new Promise((resolve, reject) => {
    let reader = new FileReader()
    reader.readAsDataURL(blob)
    reader.onloadend = () => {
      resolve(reader.result)
    }

    reader.onerror = reject
  })
}

function getFileBlob(url) {
  return new Promise((resolve, reject) => {
    var xhr = new XMLHttpRequest()
    xhr.open('GET', url)
    xhr.responseType = 'blob'
    xhr.addEventListener('load', function () {
      resolve(xhr.response)
    })
    xhr.send()
  })
}
export async function urlToBlob(url) {
  try {
    const image = await getFileBlob(url)

    return image

    //console.log(fileType)
    //const blob = new Blob([res.data], { type: `image/${fileType}` })
    //const file = await fileReaderAsPromise(blob)

    /*   return new File([file], `brand-kit-image-${new Date()}.${fileType}`.replace(' ', '-'), {
      type: `image/f${fileType}`
    }) */
  } catch (e) {
    console.log(e)
    throw e
  }
}

export function formatDateShort(value) {
  if (!value) return 'N/A'
  const date = new Date(value)
  return date.toLocaleDateString(['en-US'], {
    month: 'short',
    day: '2-digit',
    year: 'numeric'
  })
}

const firebaseConfig = {
  apiKey: process.env.VUE_APP_FB_API_KEY,
  authDomain: process.env.VUE_APP_AUTH_DOMAIN,
  projectId: process.env.VUE_APP_PROJECT_ID,
  storageBucket: process.env.VUE_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_FB_APP_ID,
  measurementId: process.env.VUE_APP_MEASUREMENT_ID
}
/* const config = {
  apiKey: 'AIzaSyCxokor8DXlddLrlFZsUrDNsPkRsufQMsA',
  authDomain: 'projekt-lavender.firebaseapp.com',
  databaseURL: 'https://projekt-lavender.firebaseio.com',
  projectId: 'projekt-lavender',
  storageBucket: 'projekt-lavender.appspot.com',
  messagingSenderId: '57654898746'
} */

export { firebaseConfig }

import cloneDeep from 'lodash.clonedeep'
import { random } from '@thinknimble/tn-utils'
import Model, { fields } from '@thinknimble/tn-models'
import { objectToCamelCase, objectToSnakeCase } from '@thinknimble/tn-utils'
//import { generateHTML } from '@tiptap/vue-3'

const emptyDoc = {
  type: 'doc',
  content: [
    {
      type: 'paragraph',
      content: [
        {
          type: 'text',
          text: ' '
        }
      ]
    }
  ]
}
export function generateInitialTextJson() {
  const json = {
    type: 'doc',
    content: [
      {
        type: 'heading',
        content: [
          {
            type: 'text',
            text: 'Example for cover sections '
          }
        ],
        attrs: {
          level: 1
        }
      },
      {
        type: 'paragraph',
        content: [
          {
            type: 'text',
            text: 'Example for cover section '
          }
        ]
      }
    ]
  }

  return json
}
export function generateInitialLetterStoryContentJson() {
  const json = {
    type: 'doc',
    content: [
      {
        type: 'heading',
        content: [
          {
            type: 'text',
            text: 'Enter Story Title'
          }
        ],
        attrs: {
          level: 1,
          textAlign: 'left'
        }
      },
      {
        type: 'paragraph',
        content: [
          {
            type: 'text',
            text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco.'
          }
        ],
        attrs: {
          textAlign: 'left'
        }
      }
    ]
  }

  return json
}
export function generateInitialTitlePageContent() {
  const json = {
    type: 'doc',
    content: [
      {
        type: 'paragraph',
        content: [
          {
            type: 'text',
            text: ' '
          }
        ],
        attrs: {
          textAlign: 'left'
        }
      },
      {
        type: 'paragraph',
        content: [
          {
            type: 'text',
            text: ' '
          }
        ],
        attrs: {
          textAlign: 'left'
        }
      },
      {
        type: 'paragraph',
        content: [
          {
            type: 'text',
            text: ' '
          }
        ],
        attrs: {
          textAlign: 'left'
        }
      },
      {
        type: 'paragraph',
        content: [
          {
            type: 'text',
            text: ' '
          }
        ],
        attrs: {
          textAlign: 'left'
        }
      },
      {
        type: 'paragraph',
        content: [
          {
            type: 'text',
            text: ' '
          }
        ],
        attrs: {
          textAlign: 'left'
        }
      },

      {
        type: 'heading',
        content: [
          {
            type: 'text',
            text: 'Sample Title '
          }
        ],
        attrs: {
          level: 1,
          textAlign: 'center'
        }
      },
      {
        type: 'paragraph',
        content: [
          {
            type: 'text',
            text: 'Sample Content '
          }
        ],
        attrs: {
          textAlign: 'center'
        }
      }
    ]
  }

  return json
}
export function generateTemplateBlockQuote() {
  const json = {
    type: 'doc',
    content: [
      {
        type: 'paragraph',
        content: [
          {
            type: 'image',
            attrs: {
              alt: 'logo',
              src: `${window.location.origin}/static/blockquote.svg`,
              title: 'logo',
              width: '100px',
              textAlign: 'center'
            }
          }
        ],
        attrs: {
          textAlign: 'center'
        }
      },

      {
        type: 'heading',
        content: [
          {
            marks: [{ type: 'textStyle', attrs: { color: 'rgba(170,170,170,1)' } }],
            type: 'text',
            text: 'Enter quote...'
          }
        ],
        attrs: {
          level: 3,
          textAlign: 'center'
        }
      },
      {
        type: 'paragraph',
        content: [
          {
            marks: [{ type: 'textStyle', attrs: { color: 'rgba(170,170,170,1)' } }],
            type: 'text',
            text: 'ENTER ATTRIBUTION... '
          }
        ],
        attrs: {
          textAlign: 'center'
        }
      }
    ]
  }

  return json
}
export function generateTemplateDonateContent(link = '') {
  const json = {
    type: 'doc',
    content: [
      {
        type: 'heading',
        content: [
          {
            marks: [{ type: 'textStyle', attrs: { color: 'rgba(255,255,255,1)' } }],
            type: 'text',
            text: 'Enter call to action...'
          }
        ],
        attrs: {
          level: 3,
          textAlign: 'center'
        }
      },
      {
        type: 'heading',
        content: [
          {
            type: 'text',
            text: 'Make a Contribution',
            marks: [
              { type: 'textStyle', attrs: { color: 'rgba(255,255,255,1)' } },
              {
                type: 'link',
                attrs: {
                  href: link,
                  target: '_blank'
                }
              }
            ]
          }
        ],
        attrs: {
          level: 1,
          textAlign: 'center'
        }
      }
    ]
  }

  return json
}
export function generateInitialContactPageContent() {
  const json = {
    type: 'doc',
    content: [
      {
        type: 'paragraph',
        content: [
          {
            type: 'image',
            attrs: {
              alt: 'logo',
              src: 'https://images.unsplash.com/photo-1549924231-f129b911e442?crop=entropy&cs=srgb&fm=jpg&ixid=Mnw4MDYyM3wwfDF8c2VhcmNofDl8fGxvZ298ZW58MHx8fHwxNjM2NDk0NTMy&ixlib=rb-1.2.1&q=85',
              title: 'logo',
              textAlign: 'center'
            }
          }
        ],
        attrs: {
          textAlign: 'center'
        }
      },

      {
        type: 'heading',
        content: [
          {
            type: 'text',
            text: 'Street Address'
          }
        ],
        attrs: {
          level: 3,
          textAlign: 'center'
        }
      },
      {
        type: 'heading',
        content: [
          {
            type: 'text',
            text: 'City, State, Zip'
          }
        ],
        attrs: {
          level: 3,
          textAlign: 'center'
        }
      },
      {
        type: 'heading',
        content: [
          {
            type: 'text',
            text: 'Phone Number'
          }
        ],
        attrs: {
          level: 3,
          textAlign: 'center'
        }
      }
    ]
  }

  return json
}
function generateInitialImageJson(imageSrc) {
  const json = {
    type: 'doc',
    content: [
      {
        type: 'image',
        attrs: {
          alt: '',
          src: imageSrc,
          title: ''
        }
      }
    ]
  }

  return json
}
export function generateInitialTableJson({
  cols = 3,
  rows = 2,
  includeHeader = true,
  includeTitle = false,
  headerRow = [0],
  data = [
    ['col1', 'col2', 'col3'],
    [
      'Triple click to select a cell for more options',
      'click and drag to select multiple cells',
      'hover over the edges to resize'
    ]
  ]
} = {}) {
  let tableRows = []

  if (!Array.isArray(headerRow)) {
    headerRow = [headerRow]
  }
  for (let i = 0; i < rows; i++) {
    let rowAndColumns = []
    for (let j = 0; j < cols; j++) {
      if (includeHeader && headerRow.includes(i)) {
        rowAndColumns.push({
          type: 'tableHeader',
          attrs: { colspan: 1, colwidth: null, rowspan: 1 },
          content: [
            {
              type: 'paragraph',
              content: [
                {
                  type: 'text',

                  text: data[i][j] && data[i][j].length ? data[i][j] : ' '
                }
              ]
            }
          ]
        })
      } else {
        rowAndColumns.push({
          type: 'tableCell',
          attrs: { colspan: 1, colwidth: null, rowspan: 1 },
          content: [
            {
              type: 'paragraph',
              content: [
                {
                  type: 'text',
                  text: data[i][j] && data[i][j].length ? data[i][j] : ' '
                }
              ]
            }
          ]
        })
      }
    }
    tableRows.push({ type: 'tableRow', content: rowAndColumns })
  }
  const json = {
    type: 'doc',
    content: [
      {
        type: 'table',
        content: tableRows
      }
    ]
  }
  if (includeTitle) {
    json.content = [
      {
        type: 'heading',
        content: [
          {
            type: 'text',
            text: 'Example for cover section '
          }
        ]
      },
      ...json.content
    ]
  }
  console.log(json)
  return json
}
function generateInitialDonutChartJson(chartSetup) {
  const json = {
    type: 'doc',
    content: [
      {
        type: 'donutChart',
        attrs: {
          chartDatasets: [JSON.stringify(chartSetup.datasets)],
          chartLabels: [JSON.stringify(chartSetup.labels)]
        }
      }
    ]
  }
  return json
}

class BgImageConfig extends Model {
  static size = new fields.Field({ defaultVal: 'cover' })
  // this one refers to the bg color
  static bgColorOpacity = new fields.Field({ defaultVal: 0, readOnly: false })
  static bgAlt = new fields.CharField({ defaultVal: '' })
  static bgTitle = new fields.CharField({ defaultVal: '' })
  static copyEntity(data = BgImageConfig.create()) {
    // format it the way it is
    let deepCopy = cloneDeep(data)
    Object.keys(data).forEach((k) => {
      if (k == 'id') {
        deepCopy['id'] = random.randomString()
      }
    })

    return deepCopy
  }
  get toDict() {
    let data = {}
    Object.entries(this).forEach(([key, val]) => {
      if (key != '_fields') {
        data[key] = val
      }
      if (
        data[key] instanceof Model ||
        (Array.isArray(data[key]) && data[key].length && data[key][0] instanceof Model)
      ) {
        if (Array.isArray(data[key])) {
          data[key] = data[key].map((value) => {
            return value.toDict
          })
        } else data[key] = data[key].toDict
      }
    })
    return data
  }
}

export default class CoverSection extends Model {
  static id = new fields.IdField({ readOnly: false })

  static sectionHeader = new fields.Field({ defaultVal: 'Untitled Chapter' })
  static order = new fields.IntegerField()
  static sectionBgImage = new fields.CharField()

  static generatedMarkup = new fields.Field({ defaultVal: '', readOnly: true })
  static generatedJson = new fields.Field({ defaultVal: emptyDoc, readOnly: false })
  static sectionBgColor = new fields.Field({ defaultVal: 'rgba(250, 250, 250, 0)' })
  static sections = new fields.ModelField({
    defaultVal: [],
    ModelClass: CoverSection,
    readOnly: false,
    many: true
  })
  static sectionHeight = new fields.IntegerField()
  static sectionWidth = new fields.IntegerField()
  static sectionFlex = new fields.IntegerField({ defaultVal: 12 })
  // below are used for subsections mostly
  static isSectionContainer = new fields.BooleanField({ defaultVal: false })
  static isInline = new fields.BooleanField({ defaultVal: false }) // show columns inline or as multiline (aka flex flow)
  static isCoverSection = new fields.BooleanField({ defaultVal: false })
  static sectionBgImageConfig = new fields.ModelField({
    readOnly: false,
    ModelClass: BgImageConfig,
    defaultVal: BgImageConfig.create({})
  })
  static includeInWeb = new fields.Field({ defaultVal: true })
  static includeInPrint = new fields.Field({ defaultVal: true })
  clone() {
    return cloneDeep()
  }

  static copyEntity(data = CoverSection.create()) {
    // format it the way it is
    let deepCopy = cloneDeep(data)
    Object.keys(data).forEach((k) => {
      if (k == 'id') {
        deepCopy['id'] = random.randomString()
      }
      if (
        data[k] instanceof Model ||
        (Array.isArray(data[k]) && data[k].length && data[k][0] instanceof Model)
      ) {
        if (Array.isArray(data[k])) {
          deepCopy[k] = data[k].map((value) => value.constructor.copyEntity(value))
        } else deepCopy[k] = data[k].constructor.copyEntity(data[k])
      }
    })

    return deepCopy
  }

  static toAPI(obj, fields = [], excludeFields = []) {
    // By default, send the whole object
    let data = {}

    // If it's a partial update, get only the fields specified
    if (fields.length > 0) {
      fields.forEach((field) => {
        data[field] = obj[field]
      })
    } else {
      data = { ...obj }
    }

    // Delete private '_fields' member
    delete data['_fields']
    // format it the way it is expected
    Object.keys(data).forEach((k) => {
      if (
        data[k] instanceof Model ||
        (Array.isArray(data[k]) && data[k].length && data[k][0] instanceof Model)
      ) {
        if (Array.isArray(data[k])) {
          data[k] = data[k].map((value) => value.constructor.toAPI(value))
        } else data[k] = data[k].constructor.toAPI(data[k])
      }
    })
    // Remove read only and excluded fields
    ;[...this.getReadOnlyFields(), ...excludeFields].forEach((item) => {
      delete data[item]
    })

    return objectToSnakeCase(data)
  }
  static fromAPI(json = {}) {
    return new this(objectToCamelCase(json))
  }
  get toDict() {
    let data = {}

    Object.entries(this).forEach(([key, val]) => {
      if (key != '_fields') {
        data[key] = val
      }

      if (
        data[key] instanceof Model ||
        (Array.isArray(data[key]) && data[key].length && data[key][0] instanceof Model)
      ) {
        if (Array.isArray(data[key])) {
          data[key] = data[key].map((value) => {
            return value.toDict
          })
        } else data[key] = data[key].toDict
      }
    })
    return data
  }
}

export { emptyDoc }
//:section_bg_image,:generated_json,  :sections=[]

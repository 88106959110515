import { inject, defineComponent, createApp, getCurrentInstance } from 'vue'
import { ref as gRef, getStorage, getDownloadURL, uploadBytes } from '@firebase/storage'
import { $vfm } from 'vue-final-modal'

import { formatDateShort, formatNumberAsUSD } from '@thinknimble/tn-utils'
import firebaseFunctions from '@/composables/firebaseFunctions'
import LoadingModal from '@/components/modals/LoadingModal'
import ImageOptionsModal from '@/components/modals/ImageOptionsModal'
import ImageUploadModal from '@/components/modals/ImageUploadModal'
import HelpModal from '@/components/modals/HelpModal'
import ContributionLinkModal from '@/components/modals/ContributionLinkModal'
import EmbeddedContentModal from '@/components/modals/EmbeddedContentModal'
import BaseModal from '@/components/modals/_BaseModal'
import CreditCardModal from '@/components/modals/CreditCardModal'
import SubscriptionModal from '@/components/modals/SubscriptionModal'
import ImageMetaModal from '@/components/modals/ImageMetaModal'
import UpgradeButton from '@/components/UpgradeButton'
import BaseComponent from '@/components/__BaseComponent'
import FileUpload from '@/components/FileUpload'
import FontUploadForm from '@/views/brandkit/FontUploadForm'
import ImageListModal from '@/components/modals/ImageListModal'
import ImagesFromBrandKit from '@/components/ImagesFromBrandKit'

export default function CommonModals(emit = null) {
  const instance = getCurrentInstance()
  async function showLoadingModal(title = null, subTitle = null, clickToClose = false) {
    await showBaseModal(
      'LoadingModal',
      title,
      subTitle,
      '',
      {
        'click-to-close': clickToClose,
        'lock-scroll': false,
        hideClose: true
      },
      {},
      { 'modal-content-1': { component: LoadingModal } }
    )
  }
  async function showHelpModal(
    title = null,
    subTitle = null,
    msg = null,
    additionalBindings = {},
    listeners = {},
    slots = {}
  ) {
    await showBaseModal(
      'HelpModal',
      title,
      subTitle,
      msg,
      { 'lock-scroll': false, ...additionalBindings },
      listeners,
      slots
    )
  }

  async function showCreditCardModal(
    title = null,
    subTitle = null,
    message = null,
    slots = {},
    additionalBindings = {}
  ) {
    await $vfm.show({
      component: CreditCardModal,
      on: {
        newPaymentToken: async (e) => {
          // hack to find bug
          await additionalBindings.newPaymentToken(e)
        }
      },
      bind: {
        title,
        subTitle,
        message,
        'lock-scroll': false
      },
      slots: slots
    })
  }
  async function showSubscriptionModal(
    title = null,
    subTitle = null,
    message = null,
    slots = {},
    additionalBindings = {}
  ) {
    await $vfm.show({
      component: SubscriptionModal,
      on: { 'lock-scroll': false, ...additionalBindings },
      bind: {
        title,
        subTitle,
        message
      },
      slots: slots
    })
  }

  async function showImageMetaModal(imageUrl, imageAlt, imageTitle, onBindings) {
    await $vfm.show({
      component: ImageMetaModal,
      on: onBindings,
      bind: {
        name: 'ImageMetaModal',
        title: 'Edit Image',
        subTitle: '',
        message: 'Add meta data to your image for accessibility',
        imageUrl,
        imageAlt,
        imageTitle,
        'lock-scroll': false
      }
    })
  }
  async function showImageUploadModal(listeners = {}) {
    await $vfm.show({
      component: ImageUploadModal,
      bind: { 'lock-scroll': false },
      on: {
        ...listeners
      }
    })
  }
  async function showContributionLinkModal(link = '', componentListeners = {}) {
    await showBaseModal(
      'ContributionLinkModal',
      'Enter a Link',
      '',
      '',
      { 'lock-scroll': false },
      {},
      {
        'modal-content-1': {
          component: ContributionLinkModal,
          bind: {
            previousLink: link
          },
          on: {
            ...componentListeners
          }
        }
      }
    )
    /*     await $vfm.show(
      {
        component: ContributionLinkModal,
        bind: { 'lock-scroll': false },
        on: {
          closed: (e, val) => {},
          'contribution-link': (e) => {
            if (editor.isActive('link')) {
              editor.commands.updateAttributes('link', { href: e })
            } else {
              editor.commands.setLink({ href: e, target: '_blank' })
            }

            $vfm.hideAll()
          }
        }
      },
      { link: '' }
    ) */
  }
  async function showEmbedModal(type, cb = null) {
    await $vfm.show(
      {
        component: EmbeddedContentModal,
        bind: { 'lock-scroll': false },
        on: {
          closed: (e, val) => {},

          'new-embed-data': (e) => {
            cb(e)
            $vfm.hideAll()
          }
        }
      },
      { type: type }
    )
  }

  /**
   *  Will replace all modal classes to use this base
   * @param {*} name of the modal for using modal.hide
   * @param {*} title  text to show, if null will remove div
   * @param {*} subTitle subTitle text to show, if null will remove div
   * @param {*} msg msg text to show, if null will remove div
   * @param {*} additionalBindings bindings to the to pass props these are for the modal component itself not the slot
   * @param {*} listeners to listen to applied to the 'on' function
   * @param {*} slots Add components through slots {modal-content-{key}:{MODAL_OBJECT}}, key starts from 1
   * if passing listeners/bindings remember to pass them in an object with {"on":{...listeners},bind:{}}
   */
  async function showBaseModal(
    name,
    title = null,
    subTitle = null,
    msg = null,
    additionalBindings = {},
    listeners = {},
    slots = {}
  ) {
    await $vfm.show({
      component: BaseModal,
      bind: {
        name: name,
        title: title,
        subTitle: subTitle,
        message: msg,
        slotCount: slots ? Object.keys(slots).length : 0,
        ...additionalBindings
      },
      on: { ...listeners },
      slots: slots
    })
  }

  async function showUpgradeFromFreeModal(listeners = {}) {
    await showBaseModal(
      'UpgradeFromBasicModal',
      'Yearly',
      'Upgrade Your Plan',
      'With a Yearly Pro plan you can create and publish an unlimited number of reports! You can also add your brand fonts, color palettes and upload images.',
      {},
      { ...listeners },
      { 'modal-content-1': { component: UpgradeButton } }
    )
  }
  async function showImageOptionsModal(image = null) {
    function onSetBg(modalValue) {
      console.log(modalValue)
      emit('update:sectionBgImage', modalValue.url),
        emit('update:sectionBgImageSize', modalValue.backgroundSize),
        emit('update:sectionBgImageAlt', modalValue.imageAlt),
        emit('update:sectionBgImageTitle', modalValue.imageTitle),
        $vfm.hideAll()
    }
    await showBaseModal(
      'ImageOptionsModal',
      'Customize image',
      'Lorem Ipsum',
      '',
      { image },
      { 'set-bg': (e) => onSetBg(e) },
      {
        'modal-content-1': {
          component: ImageOptionsModal,
          bind: { currentImage: image },
          on: {
            'set-bg': (e) => {
              onSetBg(e)
            }
          }
        }
      }
    )
  }
  async function showWelcomeToFreeModal() {
    await showBaseModal(
      'WelcomeToFree',
      'Welcome to Yearly!',
      'Getting Started',
      '',
      { 'lock-scroll': false },
      {},
      {
        'modal-content-1': {
          component: BaseComponent,
          bind: {
            content: `
            <h5 class="title is-5">1. Tutorial</h5>
              <iframe width="560" height="315" src="https://www.youtube.com/embed/OHBXp2Be6hI" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              `
          }
        },

        'modal-content-2': {
          component: BaseComponent,
          bind: {
            content: `
            <h5 class="title is-5">2. Use our <a href="https://yearly.report/faq/">FAQ</a> </h5>
          
              `
          }
        },
        'modal-content-3': {
          component: BaseComponent,
          bind: {
            content: `
            <h5 class="title is-5">3. Chat with us on the right of your screen</h5>
              `
          }
        },
        'modal-content-4': {
          component: BaseComponent,
          bind: {
            content: `
            <h5 class="title is-5">4.  Email us questions at hello@yearly.report</h5>
           
              `
          }
        }
      }
    )
  }

  async function showUploadFontModal(fontStyle, fontName, actions) {
    await showBaseModal(
      'BrandKitFontModal',
      ' ',
      'Upload your font',
      '',
      { 'lock-scroll': false },
      {},
      {
        'modal-content-1': {
          component: FontUploadForm,
          bind: {
            fontName,
            fontStyle,
            'lock-scroll': false
          },
          on: {
            ...actions
          }
        }
      }
    )
  }
  async function showUploadImageModal(actions) {
    await showBaseModal(
      'ImageUploadModal',
      ' ',
      'Upload your image',
      '',
      { 'lock-scroll': false },
      {},
      {
        'modal-content-1': {
          component: FileUpload,
          bind: {
            fileTypes: ['image/*']
          },
          on: {
            ...actions
          }
        }
      }
    )
  }
  async function showUnsubscribeModal(
    currentPlanLabel,
    currentPlanEndDate,
    currentPlanOptions,
    listeners = {}
  ) {
    await showBaseModal(
      'UnsubscribeModal',
      `Unsubscribe from ${currentPlanLabel}`,
      'Are you sure?',
      `Your plan will be unsubscribed on  ${formatDateShort(currentPlanEndDate)}`,
      {},
      {},
      {
        'modal-content-1': currentPlanOptions,
        'modal-content-2': {
          component: UpgradeButton,
          bind: {
            buttonFor: 'UNSUBSCRIBE'
          },
          on: { ...listeners }
        }
      }
    )
  }

  async function showForceLogoutModal(slots = {}) {
    await showBaseModal(
      'UserForceLogout',
      '',
      'Please Sign out',
      'It appears you have an active session elsewhere please sign out or click below to force sign out of all current sessions',
      { 'lock-scroll': false, clickToClose: false, hideClose: true },
      {},
      slots
    )
  }

  const getFileBlob = function (url, cb) {
    var xhr = new XMLHttpRequest()
    xhr.open('GET', url)
    xhr.responseType = 'blob'
    xhr.addEventListener('load', function () {
      cb(xhr.response)
    })
    xhr.send()
  }
  function imageLoadCb(res) {
    instance.emit('image-from-unsplash', res)
  }

  async function showImageUnsplashModal() {
    await showBaseModal(
      'ImageUnsplashModal',
      '',
      'Search for an Image',
      '',
      {},
      { lockScroll: false },
      {
        'modal-content-1': {
          component: ImageListModal,
          on: {
            'image-ready': async (e) => {
              await getFileBlob(e, imageLoadCb)
            }
          }
        }
      }
    )
  }

  async function showBrandKitImagesModal() {
    await showBaseModal(
      'BrandKitImageshModal',
      '',
      'Select an Image',
      '',
      {},
      { lockScroll: false },
      {
        'modal-content-1': {
          component: ImagesFromBrandKit,
          on: {
            'brand-kit-image': async (e) => {
              //onSaveAndUse(e)
              instance.emit('brand-kit-image-ready', e)
            }
          }
        }
      }
    )
  }

  return {
    $vfm,
    showImageUnsplashModal,
    showLoadingModal,
    showHelpModal,
    showImageOptionsModal,
    showImageUploadModal,
    showContributionLinkModal,
    showEmbedModal,
    showCreditCardModal,
    showSubscriptionModal,
    showBaseModal,
    showImageMetaModal,
    showUpgradeFromFreeModal,
    showWelcomeToFreeModal,
    showUploadFontModal,
    showUploadImageModal,
    showUnsubscribeModal,
    showForceLogoutModal,
    showBrandKitImagesModal
  }
}

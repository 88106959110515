<template>
  <vue-final-modal
    v-slot="{ close }"
    v-bind="$attrs"
    classes="modal-container"
    content-class="modal-content"
    :name="name"
    @before-close="onBeforeClosed"
  >
    <div class="box">
      <span class="modal__close" @click="close">x</span>
      <span class="modal__title">Upload CSV data for Table</span>
      <div class="modal__content">
        <FileUpload
          ref="uploader"
          @fileLoaded="setFile"
          :clearContent="clearFile"
          :fileTypes="['.csv']"
        >
        </FileUpload>
        <div class="form" v-if="showForm">
          <div class="field">
            <label class="label">Inlcude Header</label>
            <p class="help is-primary">
              Do you want to include a table header for the column names?
            </p>
            <div class="control">
              <select v-model="tableUploadForm.field.includeHeader.value" class="input">
                <option :value="true">Yes</option>
                <option :value="false">No</option>
              </select>
            </div>
          </div>
          <div class="field">
            <label class="label">Row Header</label>

            <div class="control">
              <div class="list">
                <ul>
                  <div
                    class="list-item"
                    :key="i"
                    v-for="(row, i) in tableUploadForm.field.tableData.value"
                  >
                    <li class="is-flex is-align-items-center">
                      <input
                        type="radio"
                        :name="`rowHeader-${i}`"
                        :checked="tableUploadForm.field.headerRow.value.includes(i)"
                        @change="toggleHeaderRow(i)"
                      />
                      <p style="margin-left: 0.5rem">{{ row }}</p>
                    </li>
                  </div>
                </ul>
              </div>
            </div>
            <p class="help is-primary">Select the Row with the header data</p>
          </div>
        </div>
      </div>

      <button @click="close" class="button">Cancel</button>
      <button class="button is-primary" :disabled="!tableUploadForm.isValid" @click="onSubmit">
        Submit
      </button>
    </div>
  </vue-final-modal>
</template>

<script>
import { onMounted, inject, ref, watchEffect, computed } from 'vue'

import CommonModals from '@/composables/commonModals'

import FileUpload from '@/components/FileUpload'
import Form, { FormField } from '@thinknimble/tn-forms'
import { RequiredValidator, MinLengthValidator } from '@thinknimble/tn-validators'

function csvToArray(text) {
  let p = '',
    row = [''],
    ret = [row],
    i = 0,
    r = 0,
    s = !0,
    l
  for (l of text) {
    if ('"' === l) {
      if (s && l === p) row[i] += l
      s = !s
    } else if (',' === l && s) l = row[++i] = ''
    else if ('\n' === l && s) {
      if ('\r' === p) row[i] = row[i].slice(0, -1)
      row = ret[++r] = [(l = '')]
      i = 0
    } else row[i] += l
    p = l
  }
  return ret
}

class TableUploadForm extends Form {
  static includeTitle = new FormField({ value: false })
  static includeHeader = new FormField({ value: true })
  static headerRow = new FormField({ value: [0] })

  static tableData = new FormField({
    value: [],
    validators: [new RequiredValidator(), new MinLengthValidator()]
  })
  get totalRows() {
    if (
      !this.field.includeHeader.value &&
      (this.field.headerRow.value || this.field.headerRow.value == 0)
    ) {
      return this.field.tableData.value.length
    }
    return this.field.tableData.value.length
  }
  get dataSplit() {
    if (this.field.tableData.isValid) {
      /*  return this.field.tableData.value.map((r) => {
        return r.split(',')
      }).flat(1) */
      return this.field.tableData.value.map((r) => csvToArray(r)).flat(1)
    }
    return []
  }
  get totalColumns() {
    if (this.field.tableData.isValid) {
      return this.dataSplit[0].length
    }
    return 0
  }
  get tableValue() {
    return {
      ...this.value,
      data: this.dataSplit,
      rows: this.totalRows,
      cols: this.totalColumns
    }
  }
}
export default {
  name: 'CSVUpload',
  inheritAttrs: false,
  components: { FileUpload },
  props: {
    showModal: {
      type: Boolean,
      default: false
    },
    name: {
      default: 'CsvUploadModal',
      type: String
    }
  },
  emits: ['table-data-ready'],
  setup(_, { emit }) {
    const clearFile = ref(false)
    const $vfm = inject('$vfm')
    const file = ref(null)
    const loadingFileData = ref(false)
    const { showLoadingModal } = CommonModals()
    const tableUploadForm = ref(new TableUploadForm())
    const showForm = computed(
      () => tableUploadForm.value && tableUploadForm.value.field.tableData.isValid
    )

    function toggleHeaderRow(index) {
      let existingIndex = tableUploadForm.value.field.headerRow.value.findIndex((i) => i == index)
      if (~existingIndex) {
        tableUploadForm.value.field.headerRow.value = [
          ...tableUploadForm.value.field.headerRow.value.splice(existingIndex)
        ]
        return
      }
      tableUploadForm.value.field.headerRow.value = [
        ...tableUploadForm.value.field.headerRow.value,
        index
      ]
    }
    function onBeforeClosed() {
      return
    }
    function setFile(f) {
      if (!f) {
        tableUploadForm.value.field.tableData.value = []
        return
      }
      if (!['text/csv', 'application/vnd.ms-excel'].includes(f.type)) {
        alert('Please Upload a csv document')
        clearFile.value = true
        return
      }
      if (f) {
        file.value = f
      }
    }
    watchEffect(async () => {
      if (file.value) {
        await showLoadingModal(
          'Loading Uploaded Document',
          'Just give us a moment to load this csv'
        )
        loadingFileData.value = true
        let data = await file.value.text()
        tableUploadForm.value.field.tableData.value = data.split('\n')
        tableUploadForm.value.field.tableData.validate()
        loadingFileData.value = false

        await $vfm.hide('LoadingModal')
      }
    })
    async function onSubmit() {
      await showLoadingModal(
        'Loading Table Data',
        'Just give us a moment to load this csv into the table'
      )
      tableUploadForm.value.validate()
      if (!tableUploadForm.value.isValid) {
        await $vfm.hide('LoadingModal')
        return
      }

      emit('table-data-ready', tableUploadForm.value.tableValue)
      await $vfm.hide('LoadingModal')
    }
    return {
      setFile,
      showForm,
      onSubmit,
      tableUploadForm,
      toggleHeaderRow,
      onBeforeClosed,
      clearFile
    }
  }
}
</script>

<style lang="scss" scoped></style>

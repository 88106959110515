<template>
  <modals-container></modals-container>

  <alert-alert position="lt" />
  <NavBar v-if="showNav" />
  <router-view> </router-view>
</template>

<script>
//import { useMeta, useActiveMeta } from 'vue-meta'

import { computed, onBeforeUnmount, watchEffect, inject } from 'vue'
import { useRouter } from 'vue-router'

import yearlyStore from '@/composables/yearlyStore'
import firebaseFunctions from '@/composables/firebaseFunctions'
import CommonModals from '@/composables/commonModals'
import ForceLogout from '@/components/ForceLogout'
//import trackingFunctions from '@/composables/trackingFunctions'

import NavBar from '@/components/NavBar'
export default {
  components: { NavBar },
  setup() {
    const router = useRouter()
    const showNav = computed(() => {
      return ['Account', 'DashboardPage', 'BrandKitPage'].includes(router.currentRoute.value.name)
    })
    const { user, showLogoutModal, isLoggedIn, logout, singleSessionId } = yearlyStore()
    const { showForceLogoutModal, $vfm } = CommonModals()
    const { userStateChanged, signOutUser, setUserSession } = firebaseFunctions()
    // const { trackLogin, REGISTRATION_STEP_AUTH } = trackingFunctions()

    onBeforeUnmount(() => {
      userStateChanged()
    })
    watchEffect(async () => {
      if (showLogoutModal.value && isLoggedIn.value) {
        await showForceLogoutModal({
          'modal-content-1': {
            component: ForceLogout,
            on: {
              'force-logout': () => {
                setUserSession(user?.value?.firebaseId, singleSessionId.value, true, Date.now())
              },
              'cancel-login': async () => {
                await signOutUser()
                logout()
                router.push({ name: 'LoginPage' })
              }
            }
          }
        })
      } else {
        await $vfm.hide('UserForceLogout')
      }
    })

    return {
      showNav,
      user,
      showLogoutModal
    }
  }
}
</script>

<style lang="scss">
* {
  padding: 0;
  margin: 0;
  text-decoration: none;
  list-style: none;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
}

/* ::-webkit-scrollbar {
  height: 12px;
  width: 12px;
  background: #000;
}

::-webkit-scrollbar-thumb {
  background: #393812;
  -webkit-border-radius: 0.2ex;
  -webkit-box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.75);
}

::-webkit-scrollbar-corner {
  background: #000;
}
 */
html {
  font-family: 'museo-sans', sans-serif;
  font-size: 12px;
  line-height: 1.3;
  font-weight: 500;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  overflow: auto;
  -ms-overflow-style: none;
  overflow: -moz-scrollbars-none;
  background-color: white !important;
  height: 100%;
  &::-webkit-scrollbar {
    width: 0 !important;
  }
}
body {
  font-family: 'museo-sans', sans-serif;
  background-color: white;
  height: 100%;
}
//#eff4f7
#app {
  @include until(1280px) {
    padding: 0px;
  }
  padding: 0px;
}

#nav {
}

#nav a {
  font-weight: bold;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>

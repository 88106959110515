<template>
  <div class="box">
    <form class="is-flex is-justify-content-center is-flex-direction-column" @submit.prevent>
      <div class="columns">
        <div class="column">
          <TnFormField
            :errors="creditCardForm.name.errors"
            @blur="creditCardForm.name.validate()"
            v-model:modelValue="creditCardForm.name.value"
            labelText="Cardholder Name"
          />
        </div>
        <div class="column">
          <TnFormField :errors="creditCardForm.cardNumber.errors" labelText="Card Number">
            <template #field-input>
              <div id="card-number"></div>
            </template>
          </TnFormField>
        </div>
      </div>

      <div class="columns">
        <div class="column">
          <TnFormField :errors="creditCardForm.cardExpiry.errors" labelText="Exp.">
            <template #field-input>
              <div id="card-expiry"></div>
            </template>
          </TnFormField>
        </div>
        <div class="column">
          <TnFormField :errors="creditCardForm.cardCvc.errors" labelText="CVC">
            <template #field-input>
              <div id="card-cvc"></div>
            </template>
          </TnFormField>
        </div>
        <div class="column">
          <TnFormField
            v-model:modelValue="creditCardForm.addressZipCode.value"
            :errors="creditCardForm.addressZipCode.errors"
            @blur="creditCardForm.addressZipCode.validate()"
            labelText="Zip Code"
            helpText="Used for verification"
          />
        </div>
      </div>
      <button
        class="button"
        :class="{ 'is-primary': creditCardForm.isValid }"
        :disabled="!creditCardForm.isValid"
        @click="createToken"
      >
        Save Card
      </button>
    </form>
  </div>
</template>

<script>
import { ref, inject, onMounted, onBeforeUnmount, computed } from 'vue'

import Form, { FormField, RequiredValidator, Validator } from '@thinknimble/tn-forms'
import yearlyStore from '@/composables/yearlyStore'

import TnFormField from '@/components/FormField'

class StripeFieldValidator extends Validator {
  constructor({ message = 'Card Info Error', code = 'card_info_error', field = null } = {}) {
    super({ message, code })
    this.field = field
  }
  call(value) {
    if (this.field.errors.length) {
      throw new Error(
        JSON.stringify({
          code: this.code,
          message: this.message
        })
      )
    }
  }
}

class CompleteCreditCardForm extends Form {
  static name = new FormField({})
  static addressZipCode = new FormField({})
  static paymentProcessorCustomerId = new FormField({})
  static paymentProcessorSourceId = new FormField({})
  static institution = new FormField({})
  static last4 = new FormField({})
}

class CreditCardForm extends Form {
  static cardNumber = new FormField({ validators: [new RequiredValidator()] })
  static cardExpiry = new FormField({ validators: [new RequiredValidator()] })
  static cardCvc = new FormField({ validators: [new RequiredValidator()] })
  static name = new FormField({ validators: [new RequiredValidator()] })
  static addressZipCode = new FormField({ validators: [new RequiredValidator()] })
}

export default {
  components: { TnFormField },
  emits: ['newPaymentToken'],
  setup(_, { emit }) {
    const { user } = yearlyStore()
    const $alert = inject('$alert')
    const $stripe = inject('$stripe')
    const token = ref(null)
    const cardNumber = ref(null)
    const cardExpiry = ref(null)
    const cardCvc = ref(null)
    const creditCardForm = ref(new CreditCardForm())
    const completeCreditCardForm = ref(new CompleteCreditCardForm())
    const stripeElements = computed(() => {
      return $stripe.elements()
    })

    async function createToken() {
      const { token, error } = await $stripe.createToken(cardNumber.value, {
        name: creditCardForm.value.value.name,
        address_zip: creditCardForm.value.value.addressZipCode
      })
      if (error) {
        $alert.alert({ message: error.message, type: 'error' })
        return
      }

      emit('newPaymentToken', token.id)
    }
    function registerStripeErrorListener(e) {
      if (!e.empty && e.error) {
        creditCardForm.value.field[e.elementType].errors = [e.error]
        console.log(creditCardForm.value.field[e.elementType])
      } else if (e.complete && !e.error) {
        creditCardForm.value.field[e.elementType].errors = []
        creditCardForm.value.field[e.elementType].value = 'PLACEHOLDER'
      }
    }
    onMounted(() => {
      const style = {
        base: {
          fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
          fontSmoothing: 'antialiased',
          fontSize: '14px',
          '::placeholder': {
            color: '#aab7c4'
          }
        },
        invalid: {
          color: '#fa755a',
          iconColor: '#fa755a'
        }
      }
      const classes = {
        base: 'CustomInputElement'
      }

      cardNumber.value = stripeElements.value.create('cardNumber', { style })
      cardNumber.value.mount('#card-number')
      cardExpiry.value = stripeElements.value.create('cardExpiry', { style })
      cardExpiry.value.mount('#card-expiry')
      cardCvc.value = stripeElements.value.create('cardCvc', { style })
      cardCvc.value.mount('#card-cvc')

      cardNumber.value.on('change', registerStripeErrorListener)
      cardExpiry.value.on('change', registerStripeErrorListener)
      cardCvc.value.on('change', registerStripeErrorListener)

      creditCardForm.value.addValidator(
        'cardNumber',
        new StripeFieldValidator({ field: creditCardForm.value.cardNumber })
      )
      creditCardForm.value.addValidator(
        'cardCvc',
        new StripeFieldValidator({ field: creditCardForm.value.cardCvc })
      )
      creditCardForm.value.addValidator(
        'cardExpiry',
        new StripeFieldValidator({ field: creditCardForm.value.cardExpiry })
      )
    })
    onBeforeUnmount(() => {
      try {
        cardNumber.value.destory()
        cardExpiry.value.destroy()
        cardCvc.value.destroy()
      } catch (e) {
        return
      }
    })

    return {
      createToken,
      stripeElements,
      creditCardForm
    }
  }
}
</script>

<style lang="scss" scoped>
:deep(.StripeElement) {
  -moz-appearance: none;
  -webkit-appearance: none;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border: 1px solid transparent;
  border-radius: 4px;
  -webkit-box-shadow: none;
  box-shadow: none;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  font-size: 1rem;
  height: 2.5em;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  line-height: 1.5;
  padding-bottom: calc(0.375em - 1px);
  padding-left: calc(0.625em - 1px);
  padding-right: calc(0.625em - 1px);
  padding-top: calc(0.675em - 1px);
  position: relative;
  vertical-align: top;

  background-color: white;
  border-color: #dbdbdb;
  border-radius: 4px;
  color: #363636;

  -webkit-box-shadow: inset 0 1px 2px rgba(10, 10, 10, 0.1);
  box-shadow: inset 0 1px 2px rgba(10, 10, 10, 0.1);
  max-width: 100%;
  width: 100%;
  min-width: 10rem;

  /*   input {
    border: 1px solid black !important;
  }
  input[field-name='cardNumber'] {
    border: 1px solid black !important;
  } */
}
</style>

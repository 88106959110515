import { useStore } from 'vuex'
import { computed } from 'vue'

export default function yearlyStore() {
  const store = useStore()

  const setUser = (data) => {
    store.commit('SET_USER', data)
  }
  const setUserAuth = (data) => {
    store.commit('SET_USER_AUTH', data)
  }
  const setUserAccount = (data) => {
    store.commit('SET_USER_ACCOUNT', data)
  }
  const setToken = (data) => {
    store.commit('SET_USER_TOKEN', data)
  }
  const setBrandKit = (data) => {
    store.commit('SET_USER_BRAND_KIT', data)
  }
  const logout = () => {
    store.commit('LOG_OUT')
  }
  const setLogoutModal = (data) => {
    store.commit('SET_SHOW_LOGOUT_MODAL', data)
  }
  const refreshUser = async () => {
    await store.dispatch('refreshUser')
  }
  const refreshBrandKit = async () => {
    await store.dispatch('refreshBrandKit')
  }
  const setNewReportVersion = (reportId, versionId, report) => {
    store.commit('SET_NEW_REPORT_VERSION', { reportId, versionId, report })
  }
  const user = computed(() => store.getters.user)
  const token = computed(() => store.getters.token)
  const isLoggedIn = computed(() => store.getters.user?.firebaseId && store.getters.token)
  const state = computed(() => store.state)
  const brandKit = computed(() => store.getters.brandKit)
  const hasBrandKit = computed(() => store.getters.hasBrandKit)
  const hasTrial = computed(() => store.getters.isFreeTrialPlan)
  const hasLegacy = computed(() => store.getters.isLegacyPlan)
  const hasPremium = computed(() => store.getters.canAccessPremiumFeatures)
  const hasPremiumPlus = computed(() => store.getters.canAccessPremiumPlusFeatures)
  const singleSessionId = computed(() => store.getters.singleSessionId)
  const showLogoutModal = computed(() => store.getters.showLogoutModal)
  const storedReportVersions = computed(() => store.getters.storedReportVersions)

  return {
    store,
    setUser,
    setToken,
    user,
    token,
    state,
    isLoggedIn,
    brandKit,
    hasBrandKit,
    setBrandKit,
    hasPremium,
    hasPremiumPlus,
    logout,
    setUserAuth,
    setUserAccount,
    refreshUser,
    hasLegacy,
    hasTrial,
    refreshBrandKit,
    singleSessionId,
    showLogoutModal,
    setLogoutModal,
    storedReportVersions,
    setNewReportVersion
  }
}

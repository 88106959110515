<template>
  <vue-final-modal
    v-bind="$attrs"
    classes="modal-container"
    content-class="modal-content"
    :name="name"
    v-slot="{ close }"
  >
    <div class="box">
      <div class="modal__heading">
        <div class="modal__close">
          <button @click="close" class="button is-rounded">
            <span class="icon is-small">
              <i class="fas fa-times"></i>
            </span>
          </button>
        </div>
        <div class="modal__title has-text-centered">
          <h2 v-if="title && title.length" class="title is-2">{{ title }}</h2>
          <p v-if="title && title.length" class="subtitle">{{ subTitle }}</p>
        </div>
      </div>
      <div class="modal__content">
        <slot name="modal-message">
          <p v-if="message && message.length">{{ message }}</p>
        </slot>

        <slot name="modal-content">
          <SubscriptionOptions @subscription-updated="closeAllOnSuccess" :user="user" />
        </slot>
      </div>
    </div>
  </vue-final-modal>
</template>

<script>
import { onMounted, inject, ref, watchEffect, computed } from 'vue'

import SubscriptionOptions from '@/views/Dashboard/__SubscriptionOptions.vue'

import yearlyStore from '@/composables/yearlyStore'

export default {
  name: 'HelpModal',
  inheritAttrs: false,
  components: { SubscriptionOptions },
  props: {
    name: {
      type: String
    },
    showModal: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ''
    },
    subTitle: {
      type: String,
      default: ''
    },
    message: {
      type: String,
      default: ''
    }
  },
  emits: [],
  setup(_, { emit }) {
    const $vfm = inject('$vfm')
    const { user } = yearlyStore()
    async function closeAllOnSuccess() {
      await $vfm.hideAll()
    }
    return {
      user,
      closeAllOnSuccess
    }
  }
}
</script>

<style lang="scss" scoped>
.is-pointer {
  &:hover {
    cursor: pointer;
  }
}
/* Enter and leave animations can use different */
/* durations and timing functions.              */
.slide-fade-enter-active {
  transition: all 0.9s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.9s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
}
.modal__content {
  margin: 1rem;
}
</style>

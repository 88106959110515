<template>
  <vue-final-modal
    v-bind="$attrs"
    classes="modal-container"
    content-class="modal-content"
    :name="name"
    v-slot="{ close }"
  >
    <div class="box">
      <div class="modal__heading">
        <div class="modal__close">
          <button @click="close" class="button is-rounded">
            <span class="icon is-small">
              <i class="fas fa-times"></i>
            </span>
          </button>
        </div>
        <div class="modal__title has-text-centered">
          <h2 v-if="title && title.length" class="title is-2">{{ title }}</h2>
          <p v-if="title && title.length" class="subtitle">{{ subTitle }}</p>
        </div>
      </div>
      <div class="modal__content">
        <slot name="modal-message">
          <p v-if="message && message.length">{{ message }}</p>
        </slot>

        <div class="form">
          <div class="field">
            <label class="label">Image Alt Text</label>
            <div class="control">
              <input
                class="input"
                :class="{ 'is-danger': !imageForm.imageAlt.isValid }"
                type="email"
                placeholder="Enter Value"
                v-model="imageForm.imageAlt.value"
              />
            </div>
            <p class="help is-primary">This is useful for Accessibility screens</p>
          </div>
          <div class="field">
            <label class="label">Image Title</label>
            <div class="control">
              <input
                class="input"
                :class="{ 'is-danger': !imageForm.imageTitle.isValid }"
                type="email"
                placeholder="Enter Value"
                v-model="imageForm.imageTitle.value"
              />
            </div>
            <p class="help is-primary">This title will appear on hovering your over the image</p>
          </div>

          <button class="button is-primary" @click="onSubmit">Done</button>
        </div>
      </div>
    </div>
  </vue-final-modal>
</template>

<script>
import { onMounted, inject, ref, toRefs } from 'vue'
import Form, { FormField } from '@thinknimble/tn-forms'
import { useModelWrapper } from '@/composables/modelValue'
import slider from 'vue3-slider'
import { ImageForm } from '../../services/coverSections/forms'


export default {
  name: 'ImageMetaModal',
  inheritAttrs: false,

  props: {
    name: {
      type: String,
      default: 'Name'
    },
    showModal: {
      type: Boolean,
      default: false
    },
    imageUrl: {
      type: String,
      required: true
    },
    imageTitle: {
      type: String,
      required: false
    },
    imageAlt: {
      type: String,
      required: false
    },
    imageWith: {
      type: Number,
      required: false
    },
    title: {
      type: String,
      default: ''
    },
    subTitle: {
      type: String,
      default: ''
    },
    message: {
      type: String,
      default: ''
    }
  },
  components: { slider },
  emits: ['set-image-meta'],
  setup(props, { emit }) {
    const $vfm = inject('$vfm')

    const { imageUrl, imageAlt, imageTitle, imageWidth } = toRefs(props)

    const imageForm = ref(
      new ImageForm({
        imageUrl: imageUrl.value,
        imageTitle: imageAlt.value,
        imageAlt: imageTitle.value
      })
    )
    function onSubmit() {
      console.log(imageForm.value)
      emit('set-image-meta', imageForm.value.value)
    }

    return {
      imageForm,
      onSubmit
    }
  }
}
</script>

<style lang="scss" scoped></style>

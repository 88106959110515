import Client from '../AxiosClient'
import { ModelAPI, ApiFilter } from '@thinknimble/tn-models'
import { objectToCamelCase, objectToSnakeCase } from '@thinknimble/tn-utils'
import { apiErrorHandler } from '../api'

export default class PaymentAPI extends ModelAPI {
  static FILTER_MAPS = {
    ...PaymentAPI.FILTER_MAPS,
    firebaseId: ApiFilter.create({ key: 'firebase_id' })
  }
  static ENDPOINT = (firebaseId) => {
    return `api/v1/accounts/${firebaseId}/payment_methods/`
  }

  get client() {
    return Client
  }
  async list(firebaseId, { filters = {}, pagination = {} }) {
    const url = PaymentAPI.ENDPOINT(firebaseId)
    const params = ApiFilter.buildParams(PaymentAPI.FILTER_MAPS, {
      ...filters
    })
    try {
      const res = await this.client.get(url, { params })
      return this.cls.fromAPI(res.data.payment_method)
    } catch (e) {
      apiErrorHandler({
        apiName: 'Error Retrieving Subscriptions {PAYMENT_METHODS}',
        enable400Alert: false,
        enable401Alert: true,
        rethrowErrors: true
      })(e)
    }
  }
  async retrieve_payment_method(firebaseId) {
    const url = PaymentAPI.ENDPOINT(firebaseId)
    try {
      const res = await this.client.get(url)

      return this.cls.fromAPI(res.data.payment_method)
    } catch (e) {
      apiErrorHandler({
        apiName: 'Error Retrieving Payment Method {LIST}',
        enable400Alert: false,
        enable401Alert: true,
        rethrowErrors: true
      })(e)
    }
  }
  async create(firebaseId, data) {
    const url = PaymentAPI.ENDPOINT(firebaseId)
    const d = this.cls.toAPI(data)
    try {
      const res = await this.client.post(url, d)
      return this.cls.fromAPI(res.data.payment_method)
    } catch (e) {
      apiErrorHandler({
        apiName: 'Error Creating/Updating Payment Methods {LIST}',
        enable400Alert: false,
        enable401Alert: true,
        rethrowErrors: true
      })(e)
    }
  }
  async delete(firebaseId) {
    const url = PaymentAPI.ENDPOINT(firebaseId)

    try {
      await this.client.delete(url)
    } catch (e) {
      apiErrorHandler({
        apiName: 'Error Deleting Payments',
        enable400Alert: false,
        enable401Alert: true,
        rethrowErrors: true
      })(e)
    }
  }
}

<template>
  <div class="tile is-ancestor is-vertical box">
    <div class="tile">
      <div class="tile is-parent" :class="{ 'is-vertical': layout !== 'horizontal' }">
        <div
          :key="index"
          class="tile is-child"
          :class="layout == 'horizontal' ? `is-${12 / plansCount}` : ''"
          v-for="(plan, index) in plans"
        >
          <div
            class="card tile is-vertical"
            style="height: 100%"
            :style="!isVertical ? 'margin:0 0.2rem' : ''"
            @click="togglePlan(plan.key)"
          >
            <header class="card-header">
              <p class="card-header-title">{{ plan.label }}</p>
              <p
                class="card-header-title is-clickable"
                v-if="currentPlan?.category == plan.key || (!currentPlan && plan.key == FREE)"
              >
                Current Plan
              </p>
              <template v-else>
                <p
                  class="card-header-title is-clickable"
                  v-if="plan.level < plans.find((p) => p.key == currentPlan?.category)?.level"
                >
                  Included in current subscription
                </p>
                <p
                  class="card-header-title is-clickable"
                  v-else
                  @click="onNewPlanSelected(plan.key)"
                >
                  <span :class="{ 'is-underlined': newPlan.formVal.category == plan.key }">{{
                    newPlan.formVal.category == plan.key ? 'Selected Plan' : 'Select Plan'
                  }}</span>

                  <span
                    class="icon is-small"
                    v-if="newPlan.formVal.category == plan.key"
                    style="position: absolute; right: 17px; color: lightgreen"
                  >
                    <i class="fas fa-check fa-2x"></i>
                  </span>
                </p>
              </template>
              <button class="card-header-icon" aria-label="more options">
                <span class="icon" v-if="layout !== 'horizontal'">
                  <i class="fas fa-angle-down" aria-hidden="true"></i>
                </span>
              </button>
            </header>
            <transition name="fade">
              <div class="card-content tile is-parent" v-show="expandPlan(plan.key)">
                <div class="tile" :class="layout == 'horizontal' ? 'is-vertical' : ''">
                  <div class="tile is-child">
                    <strong v-if="plan.level > 1"
                      >Everything included in
                      {{ plans.find((p) => p.level == plan.level - 1).label }} PLUS:</strong
                    >
                    <strong v-else>Your free trial includes:</strong>
                    <ul>
                      <li :key="index" v-for="(opt, index) in plan.options" class="help">
                        <span class="icon is-medium" style="color: green">
                          <i class="fas fa-check"></i> </span
                        >{{ opt }}
                      </li>
                    </ul>
                  </div>
                  <div class="tile is-child is-vertical">
                    <div class="tile is-flex is-justify-content-center">
                      <div
                        style="display: flex; justify-content: space-evenly"
                        v-if="plan.key !== FREE"
                      >
                        <span class="mx-1">
                          <FormField labelText="Organization Size">
                            <template #field-input>
                              <div class="select">
                                <select :disabled="true" class="input" v-model="newPlan.tier.value">
                                  <option :key="i" :value="opt.value" v-for="(opt, i) in tierOpts">
                                    {{ opt.label }}
                                  </option>
                                </select>
                              </div>
                            </template></FormField
                          >
                        </span>
                      </div>
                    </div>

                    <div class="tile is-flex is-justify-content-center">
                      <div
                        style="display: flex; justify-content: space-evenly"
                        v-if="plan.key !== FREE"
                      >
                        <div>
                          <FormField labelText="">
                            <template #field-input>
                              <div
                                class="
                                  is-flex is-justify-content-space-evenly is-align-items-center
                                "
                              >
                                <label class="label mt-2 mx-2">Annually</label>

                                <ToggleCheckbox
                                  v-model="newPlan.frequency.value"
                                  onValue="monthly"
                                  offValue="annually"
                                  onColor="#00d1b2"
                                  offColor="#00d1b2"
                                  :disabled="currentPlan && currentPlan.category !== FREE"
                                />
                                <label class="label mx-2">Monthly</label>
                              </div>
                            </template></FormField
                          >
                          <div class="has-text-centered">
                            <small class="">Pay Annually <b>and save 20%</b></small>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="tile is-flex is-justify-content-center">
                      <PlanBite
                        :plan="plan"
                        :currentPlan="currentPlan"
                        :newPlan="newPlan"
                        :currentPaymentMethod="currentPaymentMethod"
                        @showCreditCardModal="
                          showCreditCardModal(
                            'Add Card',
                            null,
                            null,
                            {},
                            updateOrCreatePaymentMethodBinding
                          )
                        "
                        @unsubscibe="onShowUnsubscribeModal"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </transition>
          </div>
        </div>
      </div>
    </div>

    <div class="block"></div>
    <div class="panel">
      <header class="panel-heading" id="payment-panel">
        <p>Payment</p>
      </header>
      <template v-if="newPlanSelected">
        <div class="panel-block" v-if="newPlan.category.value !== FREE">
          <div class="content" v-if="newPlan.category !== FREE && currentPaymentMethod">
            <FormField labelText="Current Card">
              <template #field-input>
                <InlineCreditCardView
                  :last4="currentPaymentMethod?.last4"
                  :institution="currentPaymentMethod?.institution"
                />
              </template>
            </FormField>
            <span
              class="is-clickable is-underlined"
              @click="
                showCreditCardModal('Add Card', null, null, {}, updateOrCreatePaymentMethodBinding)
              "
              >change card</span
            >
          </div>
          <div class="content" v-else>
            <CreditCardComponent
              @newPaymentToken="
                (e) => {
                  ifNewCardsaveCardToDb(e, user.firebaseId)
                }
              "
            />
          </div>
        </div>

        <div class="panel-block">
          <div class="content" v-if="newPlan?.category.value == FREE"></div>
        </div>
      </template>
      <template v-else>
        <div class="panel-block">Select a Plan to get started</div>
      </template>
    </div>
    <div class="panel">
      <div class="panel-heading">Order Details</div>
      <div class="panel-block">
        <div v-if="newPlanSelected">
          <p>
            {{ getPlanOrderDetails().planMessage }}
            <span v-if="getPlanOrderDetails().pricingAndBilling">
              {{ getPlanOrderDetails().pricingAndBilling }}
            </span>
            <span v-if="validPromoCode" class="help"
              ><i> - Your plan includes a promo code!</i></span
            >
          </p>
          <p>
            Payment Method:
            <span v-if="!getPlanOrderDetails().paymentMethod" class="tag">No Payment Needed</span>
            <InlineCreditCardView
              v-else-if="getPlanOrderDetails().paymentMethod && currentPaymentMethod"
              :last4="currentPaymentMethod?.last4"
              :institution="currentPaymentMethod?.institution"
            />
            <span v-else-if="getPlanOrderDetails().paymentMethod && !currentPaymentMethod"
              >Please add a payment method</span
            >
          </p>
        </div>
        <p v-else-if="currentPlan && !newPlanSelected">No Changes</p>
      </div>
      <div class="panel-block">
        <div style="width: 100%">
          <a @click="showPromoField = !showPromoField">Have a promo code?</a>
        </div>
      </div>
      <transition name="fade">
        <div class="panel-block" v-if="showPromoField">
          <FormField
            :disabled="!newPlanSelected"
            labelText="Promo Code"
            v-model="newPlan.code.value"
            @keyup.enter="validatePromoCode"
            :helpText="newPlan.code.length ? 'Press Enter to validate code' : ''"
          />
          <button
            class="warn button is-small"
            style="margin: 10px 0 1px 10px"
            @click="validatePromoCode"
            :disabled="!newPlanSelected"
          >
            <span class="icon is-small">
              <i class="fas fa-check fa-xs"></i>
            </span>
          </button>
          <p v-if="validPromoCode" class="help">
            ** Promotional rates will reset to the original amount upon renewal.
          </p>
        </div>
      </transition>
      <div class="panel-block">
        <slot
          name="subscription-save-btn"
          :newSelectedPlan="newPlan"
          :isDisabled="
            isLoading ||
            !newPlan.isValid ||
            (!currentPaymentMethod && newPlan.category.value !== FREE)
          "
          :saveUpdates="onUpdateSubscription"
        >
          <button
            :disabled="
              isLoading ||
              !newPlan.isValid ||
              (!currentPaymentMethod && newPlan.category.value !== FREE)
            "
            class="button is-primary is-centered"
            @click="onUpdateSubscription(newPlan)"
          >
            {{
              !newPlan.isValid || (!currentPaymentMethod && newPlan.category.value !== FREE)
                ? 'Select a plan'
                : 'Start new plan'
            }}
          </button>
        </slot>
      </div>
      <div class="panel-block" v-if="currentPlan">
        <p class="help">
          Note: If you are upgrading from Yearly Pro on an annual plan you will only pay the
          difference in the plan
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { onBeforeMount, onMounted, ref, toRefs, watchEffect, inject, computed } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { formatDateShort, formatNumberAsUSD } from '@thinknimble/tn-utils'

import Subscription, { SubscriptionForm } from '@/services/subscriptions/'
import Payment from '@/services/payments/'

import yearlyStore from '@/composables/yearlyStore'
import commonModals from '@/composables/commonModals'
import subscriptionFunctions from '@/composables/subscriptionFunctions'
import trackingFunctions from '@/composables/trackingFunctions'

import FormField from '@/components/FormField.vue'
import InlineCreditCardView from '@/components/InlineCreditCardView'

import ToggleCheckbox from '@/components/ToggleCheckbox'

import CreditCardComponent from '../../components/CreditCardComponent.vue'
import PlanBite from '@/views/Dashboard/__PlanBite'

export default {
  components: {
    FormField,
    InlineCreditCardView,

    CreditCardComponent,
    PlanBite,
    ToggleCheckbox
  },
  props: {
    preSelectedPlan: {
      type: Object,
      default: null
    },
    layout: {
      type: String,
      default: 'vertical'
    }
  },
  emits: ['subscription-updated', 'new-plan-selected'],
  setup(props, { emit }) {
    const $alert = inject('$alert')
    const { trackPurchase } = trackingFunctions()

    const { preSelectedPlan, layout } = toRefs(props)
    const { user, hasLegacy } = yearlyStore()
    const showNewCard = ref(false)

    const showPromoField = ref(false)
    const isVertical = computed(() => {
      return layout.value !== 'horizontal'
    })
    const isFirstPaymentMethod = ref(true)
    const validPromoCode = ref(null)
    const validatingPromoCode = ref(false)
    const promoCode = ref(null)

    const { $vfm, showLoadingModal, showCreditCardModal, showHelpModal, showUnsubscribeModal } =
      commonModals()

    const {
      FREE,
      PRO,
      PREMIUM,
      TIER_OPTS,
      PLANS,
      FREE_OPTS,
      PRO_OPTS,
      PREMIUM_OPTS,
      showPlan,
      getPlanOpts,
      togglePlan,
      selectNewPlan,
      currentPlan,
      newPlan,
      newPlanSelected,
      getCurrentPlanAsStaticPlan,
      getNewPlanAsStaticPlan,
      unsubscribeFromPlan,
      currentPaymentMethod,
      getDynamicPlanPricing,
      isLoading,
      plansCount,
      updateSubscription,
      retrieveSubscriptionAndSetForm
    } = subscriptionFunctions()
    function onNewPlanSelected(planKey) {
      emit('new-plan-selected')
      selectNewPlan(planKey)
    }
    function getPlanOrderDetails() {
      // new plan: the new plan
      let planMessage = `New Plan: ${getNewPlanAsStaticPlan.value?.label}`
      // pricing and billing requency
      let pricingAndBilling = () => {
        if (newPlan.value.value.category !== FREE) {
          return `$${Math.floor(newPlan.value.formVal.displayAmount / 100)}, billed ${
            newPlan.value.value.frequency
          }`
        }
        return null
      }
      // payment method
      let paymentMethod = () => {
        if (newPlan.value.value.category == FREE) {
          return false
        }
        return true
      }
      return {
        planMessage,
        pricingAndBilling: pricingAndBilling(),
        paymentMethod: paymentMethod()
      }
    }

    async function validatePromoCode() {
      if (!newPlan.value.code.value.length) {
        $alert.alert({ message: 'Enter a code' })
        return
      }

      try {
        validatingPromoCode.value = true
        const res = await Subscription.api.checkPromoCode(newPlan.value.code.value)
        newPlan.value.code.value = res.code
        newPlan.value.codeAmount.value = res.amount
        newPlan.value.discountType.value = res.category
        validPromoCode.value = true
      } catch (e) {
        validPromoCode.value = false
        $alert.alert({ message: 'Invalid Promo Code', timeout: 1000 })
      } finally {
        validatingPromoCode.value = false
      }
    }

    async function retrieveCardAndSetForm() {
      isLoading.value = true
      try {
        currentPaymentMethod.value = await Payment.api.retrieve_payment_method(
          user.value.firebaseId
        )
      } catch {
        currentPaymentMethod.value = null
      } finally {
        isLoading.value = false
      }
    }

    function expandPlan(planKey) {
      if (layout.value == 'horizontal') {
        return true
      }
      if (showPlan.value == planKey) {
        return true
      }
      return false
    }
    async function onShowUnsubscribeModal() {
      await showUnsubscribeModal(
        getCurrentPlanAsStaticPlan.value.label,
        currentPlan.value.endsAt,
        getPlanOpts(getCurrentPlanAsStaticPlan.value.options),
        {
          unsubscribe: async () => {
            await onUnSubscribeFromPlan()
            $vfm.hide('UnsubscribeModal')
          }
        }
      )
    }
    async function onUnSubscribeFromPlan() {
      isLoading.value = true
      try {
        await unsubscribeFromPlan(user.value.firebaseId)
        currentPlan.value.renew = false
      } finally {
        isLoading.value = false
      }
    }
    const updateOrCreatePaymentMethodBinding = {
      newPaymentToken: async (e) => {
        const firebaseId = user.value.firebaseId
        await ifNewCardsaveCardToDb(e, firebaseId)
        await $vfm.hide('CreditCardModal')
      }
    }
    async function ifNewCardsaveCardToDb(cardToken, firebaseId) {
      if (cardToken) {
        isLoading.value = true
        try {
          // change this to update if a user already has a card on file
          const res = await Payment.api.create(firebaseId, {
            paymentToken: cardToken
          })
          currentPaymentMethod.value = res
          $alert.alert({
            message: 'Your payment method has been updated',
            timeout: 3000,
            type: 'success'
          })
        } catch (e) {
          $alert.alert({
            message:
              'An Error occured updating your payment, please refresh the page and try again',
            timeout: 3000,
            type: 'error'
          })
        } finally {
          isLoading.value = false
        }
      }
    }
    async function onUpdateSubscription(newPlan) {
      const isUpdated = await updateSubscription(newPlan)
      if (isUpdated) {
        trackPurchase(
          newPlan.formVal.category,
          newPlan.formVal.category,
          user.value.firebaseId,
          newPlan?.formVal?.displayAmount / 100 || 0
        )
        emit('subscription-updated')
      }
    }
    onMounted(async () => {
      await retrieveSubscriptionAndSetForm(preSelectedPlan.value)
      await retrieveCardAndSetForm()
    })

    watchEffect(async () => {
      if (isLoading.value) {
        await showLoadingModal()
      } else {
        $vfm.hide('LoadingModal')
      }
    })

    return {
      //
      showPlan,
      togglePlan,
      user,
      currentPlan,
      newPlan,
      currentPaymentMethod,
      freeOpts: FREE_OPTS,
      proOpts: PRO_OPTS,
      premiumOpts: PREMIUM_OPTS,
      PRO,
      PREMIUM,
      FREE,
      plans: PLANS,
      selectNewPlan,
      onNewPlanSelected,
      showNewCard,
      onUpdateSubscription,
      newPlanSelected,
      showCreditCardModal,
      isLoading,
      tierOpts: TIER_OPTS,
      getDynamicPlanPricing,
      updateOrCreatePaymentMethodBinding,
      formatDateShort,
      formatNumberAsUSD,

      validatePromoCode,
      expandPlan,
      validPromoCode,
      validatingPromoCode,
      hasLegacy,
      showPromoField,
      getPlanOrderDetails,
      plansCount,
      onShowUnsubscribeModal,
      ifNewCardsaveCardToDb,
      isVertical
    }
  }
}
</script>

<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
.card-header {
  background-color: $salmon;
}
</style>

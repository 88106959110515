<template>
  <vue-final-modal
    v-slot="{ close }"
    v-bind="$attrs"
    classes="modal-container"
    content-class="modal-content"
    name="embeddedContent"
    @before-open="onOpened"
  >
    <div class="box">
      <div class="modal__heading">
        <div class="modal__close">
          <button @click="close" class="button is-rounded">
            <span class="icon is-small">
              <i class="fas fa-times"></i>
            </span>
          </button>
        </div>
        <div class="modal__title has-text-centered">
          <span class="subtitle">Embed content to report</span>
        </div>
      </div>
      <div class="modal__content">
        <div class="content">
          <form class="form" @submit.prevent="onSubmit">
            <FormFieldVue
              helpText="Use embed codes if the embedable content has that option for best results"
            >
              <template #field-input>
                <select
                  ref="selectEmbedType"
                  v-model="embedForm.field.embedType.value"
                  class="input"
                  :disabled="embedForm.type.value == OTHER"
                >
                  <option :key="v[0]" :value="v[0]" v-for="v in embedTypeOpts">{{ v[1] }}</option>
                </select>
              </template>
            </FormFieldVue>
            <FormFieldVue
              v-if="embedForm.field.embedType.value == LINK"
              @blur="embedForm.field.url.validate()"
              v-model="embedForm.field.url.value"
              :errors="embedForm.field.url.errors"
              placeholder="Enter url to your content"
            />
            <FormFieldVue
              v-else
              @blur="embedForm.field.url.validate()"
              v-model="embedForm.field.url.value"
              :errors="embedForm.field.url.errors"
              placeholder="Enter Embed content"
            />
            <p
              v-if="embedForm.field.embedType.value == LINK"
              @click="showAdvanced = !showAdvanced"
              class="is-pointer"
            >
              + Advanced Options (manual width & height) +
            </p>
            <transition name="slide-fade">
              <div class="content" v-if="showAdvanced && embedForm.field.embedType.value == LINK">
                <div class="is-flex">
                  <FormFieldVue
                    v-model="embedForm.field.width.value"
                    :errors="embedForm.field.width.errors"
                    placeholder="Width"
                    helpText="use %,rem,px or em (leave blank for default)"
                    type="text"
                  />
                  <FormFieldVue
                    v-model="embedForm.field.height.value"
                    :errors="embedForm.field.height.errors"
                    placeholder="Height"
                    helpText="use %,rem,px or em(leave blank for default)"
                    type="text"
                  />
                </div>
              </div>
            </transition>

            <button class="button is-fullwidth is-primary">Submit</button>
          </form>
        </div>
      </div>
    </div>
  </vue-final-modal>
</template>

<script>
import { onMounted, inject, ref, watchEffect } from 'vue'
import Form, { FormField } from '@thinknimble/tn-forms'
import Validator, { RequiredValidator } from '@thinknimble/tn-validators'
import FormFieldVue from '@/components/FormField'

const TWITTER = 'twitter'
const FB = 'facebook'
const YOUTUBE = 'youtube'
const OTHER = 'other'
const LINK = 'link'
const EMBED_CONTENT = 'embedContent'

const EMBED_OPTS = [
  [TWITTER, 'Twitter'],
  [FB, 'Facebook'],
  [YOUTUBE, 'Youtube'],
  [OTHER, 'Other']
]
const EMBED_TYPE_OPTS = [
  [LINK, 'Link'],
  [EMBED_CONTENT, 'Already formatted Content (iframe or blockquote)']
]
class EmbedForm extends Form {
  static type = new FormField({
    validators: [new RequiredValidator()]
  })
  static embedType = new FormField({
    validators: [new RequiredValidator()],
    value: EMBED_TYPE_OPTS[1][0]
  })
  static url = new FormField({ validators: [new RequiredValidator()] })
  static width = new FormField({})
  static height = new FormField({})

  formatUrlForYoutube(val) {
    if (val && ~val.search(/\w*(youtu.be)./)) {
      const newVal = val.replace(/\w*(https:\/\/youtu.be)./, '')
      return newVal
    } else if (val && ~val.search(/(v=)/)) {
      const startOfId = val.search(/(v=)/) + 2
      const endOfId = val.substr(val.search(/(v=)/) + 2, val.length).search(/&/)
      return val.substr(startOfId, endOfId)
    }
    return val
  }
  formatUrlForFacebook(val) {
    return encodeURIComponent(val)
  }
  formatUrlForTwitter(val) {
    return encodeURIComponent(val)
  }
  formatUrlForOther(val) {
    return encodeURIComponent(val)
  }

  formVal() {
    const url = this.field.url.value
    const type = this.field.type.value
    let formattedUrl = ''

    switch (type) {
      case 'youtube':
        if (this.field.embedType.value == 'link') {
          formattedUrl = this.formatUrlForYoutube(url)
          break
        }
        formattedUrl = url
        break
      case 'facebook':
        if (this.field.embedType.value == 'link') {
          formattedUrl = this.formatUrlForFacebook(url)
          break
        }
        formattedUrl = url
        break
      case 'twitter':
        formattedUrl = this.formatUrlForTwitter(url)
        break
      case 'other':
        formattedUrl = url
        break
    }

    return { ...this.value, url: formattedUrl }
  }
}
export default {
  name: 'EmbeddedContentModal',
  inheritAttrs: false,
  components: { FormFieldVue },
  props: {
    showModal: {
      type: Boolean,
      default: false
    }
  },
  emits: ['new-embed-data'],
  setup(_, { emit }) {
    const $vfm = inject('$vfm')
    const embedOpts = new Map(EMBED_OPTS)
    const embedTypeOpts = new Map(EMBED_TYPE_OPTS)
    const embedForm = ref(new EmbedForm())
    const selectEmbedType = ref(null)
    const showAdvanced = ref(false)

    watchEffect(() => {})

    function onSubmit() {
      emit('new-embed-data', embedForm.value.formVal())
    }
    function onOpened(event) {
      embedForm.value.field.type.value = event.ref.params.value.type
    }

    return {
      embedOpts,
      embedForm,
      onSubmit,
      showAdvanced,
      embedTypeOpts,
      selectEmbedType,
      onOpened,
      LINK,
      EMBED_CONTENT,
      FB,
      OTHER,
      YOUTUBE,
      TWITTER
    }
  }
}
</script>

<style lang="scss" scoped>
.is-pointer {
  &:hover {
    cursor: pointer;
  }
}
/* Enter and leave animations can use different */
/* durations and timing functions.              */
.slide-fade-enter-active {
  transition: all 0.9s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.9s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateY(-20px);
  opacity: 0;
}
</style>

<template>
  <PulseLoadingSpinner />
</template>

<script>
import { onMounted, inject, ref, watchEffect } from 'vue'

export default {
  name: 'Loading',
  inheritAttrs: false,
  components: {},
  emits: []
}
</script>

<style lang="scss" scoped>
.is-pointer {
  &:hover {
    cursor: pointer;
  }
}
/* Enter and leave animations can use different */
/* durations and timing functions.              */
.slide-fade-enter-active {
  transition: all 0.9s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.9s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
}
</style>

/**
 *
 * Image Search from Unsplash api
 */

import Client from '../AxiosClient'
import { ModelAPI, ApiFilter } from '@thinknimble/tn-models'
import { objectToCamelCase, objectToSnakeCase } from '@thinknimble/tn-utils'
import { apiErrorHandler } from '../api'

function defaultClientId() {
  return '12c0509629ebc9644a4b9a0c74e0250bdecc009588fb5f60b72cdc02926b6bcc'
}

export class UnsplashAPI extends ModelAPI {
  static FILTER_MAPS = {
    ...UnsplashAPI.FILTER_MAPS,
    perPage: ApiFilter.create({ key: 'per_page' }),
    query: ApiFilter.create({ key: 'query', validators: [] }),
    client_id: ApiFilter.create({
      key: 'client_id',
      validators: [],
      extractor: defaultClientId
    })
  }

  get client() {
    return Client
  }

  async list({ filters = {}, pagination = {} }) {
    const url = 'https://api.unsplash.com/search/photos'

    const options = {
      params: ApiFilter.buildParams(UnsplashAPI.FILTER_MAPS, {
        ...filters,
        page: pagination.page,
        perPage: pagination.perPage,
        client_id: '12c0509629ebc9644a4b9a0c74e0250bdecc009588fb5f60b72cdc02926b6bcc'
      })
    }

    const res = await this.client.get(url, options)

    return {
      ...res.data,
      results: res.data.results.map((i) => objectToCamelCase(i))
    }
  }
}

export class ReportAPI extends ModelAPI {
  static ENDPOINT = '/api/v1/account_reports/'
  static FILTER_MAPS = {
    ...ReportAPI.FILTER_MAPS,
    firebaseId: ApiFilter.create({ key: 'firebase_id' })
  }

  get client() {
    return Client
  }
  async list({ filters = {}, pagination = {} } = {}) {
    // overriding default to use custom endpoint for now
    const firebaseId = filters?.firebaseId
    const url = `/api/v1/accounts/${firebaseId}/get_account_reports/`
    const filtersMap = this.constructor.FILTERS_MAP
    const options = {
      params: ApiFilter.buildParams(filtersMap, {
        ...filters,
        page: pagination.page,
        pageSize: pagination.size
      })
    }
    try {
      const res = await this.client.get(url, options)
      const results = res.data.results
      delete res['results']
      const paginationData = objectToCamelCase({ ...res.data })

      const d = { ...paginationData, results: results.map((x) => this.cls.fromAPI(x)) }
      return d
    } catch (e) {
      const { response } = e

      if (response.status == 401) {
        //   window.location.href = process.env.VUE_APP_MAIN_APP_URL
        console.log('re login required')
      }
      apiErrorHandler({
        apiName: 'Error Retrieving Asset with id',
        enable400Alert: false,
        enable401Alert: true,
        rethrowErrors: true
      })(e)
    }
  }

  async retrieve(id, { filters }) {
    let params = ApiFilter.buildParams(ReportAPI.FILTER_MAPS, { ...filters })

    try {
      const res = await this.client.get(`/api/v1/account_reports/${id}`, { params: params })

      return this.cls.fromAPI(res.data.report)
    } catch (e) {
      const { response } = e
      console.log(e)
      if (response.status == 401) {
        //   window.location.href = process.env.VUE_APP_MAIN_APP_URL
        console.log('re login required')
      }
      apiErrorHandler({
        apiName: 'Error Retrieving Asset with id',
        enable400Alert: false,
        enable401Alert: true,
        rethrowErrors: true
      })(e)
    }
  }
  async retrievePublished(userName, reportSlug) {
    try {
      const res = await this.client.get(
        `/api/v1/accounts/${userName}/account_reports/${reportSlug}`
      )

      return this.cls.fromAPI(res.data.report)
    } catch (e) {
      apiErrorHandler({
        apiName: 'Error Retrieving Asset with id',
        enable400Alert: false,
        enable401Alert: true,
        rethrowErrors: true
      })(e)
    }
  }
  async update(id, report, firebaseId) {
    const data = { report: { ...this.cls.toAPI(report) } }
    let params = ApiFilter.buildParams(ReportAPI.FILTER_MAPS, { firebaseId: firebaseId })

    try {
      const res = await this.client.put(`/api/v1/account_reports/${id}`, data, { params })
      return res.data
    } catch (e) {
      const { response } = e
      console.log(e)
      if (response.status == 401) {
        //   window.location.href = process.env.VUE_APP_MAIN_APP_URL
      }
      apiErrorHandler({
        apiName: 'Error Updating Report',
        enable400Alert: false,
        enable401Alert: true,
        rethrowErrors: true
      })(e)
    }
  }
  async create(firebaseId, report) {
    const data = { report: { ...this.cls.toAPI(report) } }
    let params = ApiFilter.buildParams(ReportAPI.FILTER_MAPS, { firebaseId: firebaseId })

    try {
      const res = await this.client.post(`/api/v1/account_reports/`, data, { params })
      return this.cls.fromAPI(res.data.report)
    } catch (e) {
      const { response } = e
      console.log(e)
      if (response.status == 401) {
        //   window.location.href = process.env.VUE_APP_MAIN_APP_URL
      }
      apiErrorHandler({
        apiName: 'Error Creating Report',
        enable400Alert: false,
        enable401Alert: true,
        rethrowErrors: true
      })(e)
    }
  }
  async publishReport(id, firebaseId, reportSlug) {
    let params = ApiFilter.buildParams(ReportAPI.FILTER_MAPS, { firebaseId: firebaseId })
    try {
      const res = await this.client.put(
        `/api/v1/account_reports/${id}/publish_report`,
        { report_slug: reportSlug },
        {
          params
        }
      )
      return res.data
    } catch (e) {
      apiErrorHandler({
        apiName: 'Error Retrieving Asset with id',
        enable400Alert: false,
        enable401Alert: true,
        rethrowErrors: true
      })(e)
    }
  }
  async duplicateReport(id) {
    try {
      const res = await this.client.get(`/api/v1/account_reports/${id}/duplicate`)
      return res.data
    } catch (e) {
      apiErrorHandler({
        apiName: 'Error Duplicating Report',
        enable400Alert: false,
        enable401Alert: true,
        rethrowErrors: true
      })(e)
    }
  }
  async deleteReport(id) {
    try {
      await this.client.delete(`/api/v1/account_reports/${id}/`)
    } catch (e) {
      apiErrorHandler({
        apiName: 'Error Retrieving Asset with id',
        enable400Alert: false,
        enable401Alert: true,
        rethrowErrors: true
      })(e)
    }
  }
}

export default UnsplashAPI

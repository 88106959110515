<template>
  <slot name="upload-trigger" :clickUploader="onClickFileUploader">
    <button class="button is-fullwidth" v-if="!selectedFile" @click="onClickFileUploader">
      Upload
    </button>
    <span v-if="selectedFile">{{ selectedFile.name }}</span>
    <button class="button is-fullwidth" v-if="selectedFile" @click="onClearInput">Cancel</button>
  </slot>
  <input
    ref="fileUploader"
    hidden
    type="file"
    :accept="fileTypes.join(',')"
    @change="onSelectFile"
  />
</template>

<script>
import { ref, onMounted, computed, watchEffect, toRefs } from 'vue'
export default {
  props: {
    fileTypes: {
      type: Array,
      default: () => []
    },
    clearContent: {
      type: Boolean,
      default: false
    }
  },
  emits: ['fileLoaded', 'blobLoaded', 'contentCleared', 'blobUrlLoaded'],
  setup(props, { emit }) {
    const file = ref(null)
    const selectedFile = ref(null)
    const reader = ref(new FileReader())
    const fileUploader = ref(null)
    const selectedFileString = ref(null)
    const selectedFileUrlString = ref(null)
    const { clearContent } = toRefs(props)
    function onSelectFile(value) {
      selectedFile.value = value.target.files[0]
      reader.value.readAsDataURL(selectedFile.value)
      reader.value.onloadend = () => {
        selectedFileUrlString.value = reader.value.result
        emit('blobUrlLoaded', {
          fileName: selectedFile.value?.name,
          type: selectedFile.value?.type,
          data: selectedFileUrlString?.value
        })
      }

      emit('fileLoaded', selectedFile.value)
    }
    if (fileUploader.value) {
      fileUploader.value.filename = selectedFile.value ? selectedFile.value.name : ''
    }

    function onClickFileUploader() {
      fileUploader.value.click()
    }
    function onClearInput() {
      selectedFile.value = null
      selectedFileString.value = null
      fileUploader.value.filename = ''

      emit('contentCleared')
      emit('fileLoaded', selectedFile.value)
      emit('blobUrlLoaded', selectedFileUrlString.value)
      //emit('blobLoaded', selectedFile.value)
    }
    watchEffect(() => {
      if (clearContent.value) {
        onClearInput()
      }
    })

    return {
      selectedFile,
      reader,
      selectedFileString,
      file,
      onSelectFile,
      onClickFileUploader,
      fileUploader,
      onClearInput
    }
  }
}
</script>

<style lang="scss" scoped></style>

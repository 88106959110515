import { createApp } from 'vue'

import { ColorPicker } from 'vue-color-kit'
import Popper from 'vue3-popper'
import './assets/styles/colorKit.scss'
import './assets/styles/components/_tooltip.scss'
import { vfmPlugin } from 'vue-final-modal'
import '@thinknimble/vue3-alert-alert/dist/vue3-alert-alert.css'
import AlertAlert from '@thinknimble/vue3-alert-alert'
import VueScrollTo from 'vue-scrollto'
import VueTidio from 'vue-tidio'
import VueGtag from 'vue-gtag'

import App from './App.vue'
import router from './router'
import store from './store'
import VueDragResize from './components/VueDragResize'
import MarkupInput from '@/components/MarkupInput'
import directives from './directives/'
import PulseLoadingSpinner from '@/components/PulseLoadingSpinner'

import { StripePlugin, TwitterPlugin, RollbarPlugin } from '@/plugins/'
//import { createMetaManager } from 'vue-meta'

let rollbarConfig = {
  accessToken: process.env?.VUE_APP_ROLLBAR_ACCESS_TOKEN,
  captureUncaught: true,
  captureUnhandledRejections: true,
  payload: {
    // Track your events to a specific version of code for better visibility into version health
    code_version: '1.0.0',
    // Add custom data to your events by adding custom key/value pairs like the one below
    custom_data: `CLIENT-UI_${process.env?.NODE_ENV}`
  }
}
let enableRollbar = !!process.env?.VUE_APP_ROLLBAR_ACCESS_TOKEN ?? false
const app = createApp(App)
const googleMeasurementIds = process.env?.VUE_APP_MEASUREMENT_ARRAY?.split(',')?.map((id) => {
  return { id: id }
})

app
  .use(store)
  .use(router)
  .use(VueScrollTo)
  .use(VueGtag, {
    includes: googleMeasurementIds,
    config: { id: process.env.VUE_APP_MEASUREMENT_ID }
  }) //Accept an array here

  // 'UA-142620177-1'
  // .use(createMetaManager())
  .use(vfmPlugin)
  .use(StripePlugin, {
    pk: process.env.VUE_APP_STRIPE_PK,
    stripeAccount: process.env.STRIPE_ACCOUNT
  })
  .use(RollbarPlugin, rollbarConfig, enableRollbar)
  .use(TwitterPlugin)
  .use(AlertAlert, { timeout: 3000 })
  //.use(VueTidio, { appKey: 'mosphwglhgablk7issmwq6dhaxflqbei', delay: 0 })
  .component('ColorPicker', ColorPicker)
  .component('MarkupInput', MarkupInput)
  .component('VueDragResize', VueDragResize)
  // .component('VDrag', draggable)
  .component('PulseLoadingSpinner', PulseLoadingSpinner)
  .component('Popper', Popper)
  .component('PSL', PulseLoadingSpinner)
// HACK to provide gtag for composition api
app.provide('$gtag', app._context.config.globalProperties.$gtag)
app.provide('$scrollTo', app._context.config.globalProperties.$scrollTo)

directives(app)
app.mount('#app')

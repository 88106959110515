<template>
  <div class="navbar-bg">
    <div class="container">
      <nav class="navbar is-transparent" style="background: transparent">
        <div class="nav-brand"></div>
        <div class="container">
          <div class="navbar-menu">
            <div class="navbar-start">
              <figure @click="onUploadLogo" class="image is-96x96 is-inline-block is-relative">
                <img class="is-rounded" style="height: 100%" :src="logo" />
              </figure>

              <span class="navbar-item">{{ user.organization }}</span>
            </div>
          </div>
        </div>
      </nav>
      <nav
        class="navbar is-transparent"
        role="navigation"
        aria-label="main navigation"
        style="background: transparent"
      >
        <div class="navbar-brand"></div>
        <div class="container">
          <div class="navbar-menu">
            <div class="navbar-start">
              <router-link
                class="navbar-item is-tab"
                :to="{
                  name: 'DashboardPage'
                }"
                >Reports</router-link
              >
              <router-link
                class="navbar-item is-tab"
                :to="{
                  name: 'Account'
                }"
                >Account</router-link
              >
              <router-link
                class="navbar-item is-tab"
                :to="{
                  name: 'BrandKitPage'
                }"
                >Brand Kit</router-link
              >
              <!-- <a class="navbar-item is-tab" :href="`${baseUrl}/dashboard/#/brand-kit`">Brand Kit</a> -->
              <span class="navbar-item is-clickable is-tab" @click="onLogout">Logout</span>
            </div>
          </div>
        </div>
      </nav>
    </div>
  </div>
</template>

<script>
import { computed, ref, inject } from 'vue'
import { useRouter } from 'vue-router'

import UserAccount from '@/services/users/accounts'

import yearlyStore from '@/composables/yearlyStore'
import CommonModals from '@/composables/commonModals'

import firebaseFunctions from '@/composables/firebaseFunctions'

export default {
  components: {},
  setup() {
    const { user, logout, refreshUser } = yearlyStore()
    const { signOutUser, userStateChanged } = firebaseFunctions()
    const { showUploadImageModal, $vfm } = CommonModals()
    const router = useRouter()
    const isLoading = ref(false)
    const $alert = inject('$alert')
    const logo = computed(() => {
      return user?.value?.organizationLogo
        ? user?.value?.organizationLogo
        : require(`@/assets/yearly-logo.svg`)
    })
    async function onLogout() {
      try {
        userStateChanged()
        await signOutUser()
        logout()
        router.replace({ name: 'LoginPage' })
      } catch (e) {
        console.log(e)
      }
    }
    async function onUploadLogo() {
      let action = {
        fileLoaded: async (e) => {
          try {
            isLoading.value = true

            await UserAccount.api.addLogo(user.value.firebaseId, e)
            await refreshUser()
          } catch (e) {
            $alert.alert({
              message: 'An error occured uploading your image, please try again',
              type: 'error'
            })
          } finally {
            isLoading.value = false
            $vfm.hide('ImageUploadModal')
          }
        }
      }
      await showUploadImageModal(action)
    }

    return {
      user,
      onLogout,
      logo,
      onUploadLogo,
      isLoading
    }
  }
}
</script>

<style lang="scss" scoped>
.navbar-bg {
  background: #18186d url('~@/assets/abstract.png') no-repeat center bottom/cover;
}
.navbar-item {
  color: white;
}
img {
  width: 300px;
  height: 200px;
  background: darkgrey;
}
</style>

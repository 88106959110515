import Model, { fields } from '@thinknimble/tn-models'
import { objectToCamelCase, objectToSnakeCase } from '@thinknimble/tn-utils'
import SubscriptionAPI from './api'

export default class Subscription extends Model {
  static api = SubscriptionAPI.create(Subscription)
  static id = new fields.IdField({ readOnly: true })
  static amount = new fields.IntegerField({})
  static amountCents = new fields.IntegerField({})
  static category = new fields.Field({ defaultVal: 'free' })
  static frequency = new fields.CharField({})
  static nextPaymentAt = new fields.Field({ readOnly: true })
  static endsAt = new fields.Field({ readOnly: true })
  static startsAt = new fields.Field({ readOnly: true })
  static status = new fields.CharField({ readOnly: true })
  static renew = new fields.BooleanField({ readOnly: true })
  static paymentToken = new fields.CharField() // used to update payment method with subscription
  static tier = new fields.IntegerField({ defaultVal: 1 })
  static isLegacy = new fields.BooleanField({ defaultVal: false, readOnly: true })

  static fromAPI(json = {}) {
    let tier = 1
    switch (json.tier_key) {
      case 'tier_1':
        tier = 1
        break
      case 'tier_2':
        tier = 2
        break
      case 'tier_3':
        tier = 3
        break
      case 'tier_4':
        tier = 4
        break
      default:
        tier = 1
        break
    }

    if (json.category == 'legacy') {
      json.category = 'free'
      json['isLegacy'] = true
    }

    let data = { ...json, tier }
    return new this(objectToCamelCase(data))
  }
}

import { inject } from 'vue'
import { initializeApp, ref, set } from '@firebase/app'

import {
  getAuth,
  createUserWithEmailAndPassword,
  getIdToken,
  signInWithEmailAndPassword,
  onAuthStateChanged,
  sendPasswordResetEmail,
  confirmPasswordReset,
  signOut,
  checkActionCode
} from 'firebase/auth'
import {
  getDatabase,
  onValue,
  ref as dbRef,
  set as dbSet,
  get as dbGet,
  child as dbChild
} from 'firebase/database'
import { ref as gRef, getStorage, getDownloadURL, uploadBytes } from '@firebase/storage'
import {
  AUTH_INVALID_EMAIL,
  AUTH_USER_DISABLED,
  AUTH_USER_NOT_FOUND,
  AUTH_WRONG_PASSWORD,
  AUTH_TOO_MANY_REQUESTS,
  AUTH_INVALID_ACTION_CODE,
  AUTH_EMAIL_DUPLICATE_EMAIL,
  AUTH_WEEK_PASSWORD
} from '../constants'
import { firebaseConfig } from '@/services/config'
import yearlyStore from '@/composables/yearlyStore'
//import commonModals from '@/composables/commonModals'

import UserAuth from '../services/users/auth/models'

const firebaseAuthErrorCodesInvalid = [
  AUTH_INVALID_EMAIL,
  AUTH_WRONG_PASSWORD,
  AUTH_USER_DISABLED,
  AUTH_USER_NOT_FOUND,
  AUTH_INVALID_ACTION_CODE
]
const firebaseAuthErrorCodesAccountLocked = [AUTH_TOO_MANY_REQUESTS]
const firebaseAuthPasswordResetCodesInvalid = [AUTH_INVALID_ACTION_CODE]
const firebaseAuthCreateUserInvalid = [
  AUTH_EMAIL_DUPLICATE_EMAIL,
  AUTH_INVALID_EMAIL,
  AUTH_WEEK_PASSWORD
]
const firebase = initializeApp(firebaseConfig)
const storage = getStorage(firebase)
const auth = getAuth()
const db = getDatabase(firebase)

export default function firebaseFunctions() {
  const Alert = inject('$alert')

  const { setUserAuth, logout, singleSessionId, setLogoutModal, user } = yearlyStore()
  const checkSingleSession = (firebaseId, sessionId, datetime) => {
    onValue(dbRef(db, `userSession/${firebaseId}`), async (snapshot) => {
      const data = snapshot.val()

      if (!data || !data?.isActive || (data?.sessionId == sessionId && data?.isActive)) {
        setUserSession(firebaseId, sessionId, true, datetime)
        setLogoutModal(false)
      } else {
        setLogoutModal(true)
      }
    })
  }
  const clearUserSession = (firebaseId, sessionId, datetime) => {
    dbGet(dbChild(dbRef(db), `userSession/${firebaseId}`))
      .then((snapshot) => {
        if (snapshot.exists()) {
          const data = snapshot.val()

          if (!data || (data?.sessionId == sessionId && data?.isActive)) {
            setUserSession(firebaseId, null, false, datetime)
          }
        }
      })
      .catch((e) => {
        //fail silently
        console.log(e)
      })
  }
  function setUserSession(firebaseId, sessionId, isActive, datetime) {
    if (!firebaseId) {
      throw JSON.stringify({ code: 'no_firebase_id', message: 'No valid firebase id' })
    }
    dbSet(dbRef(db, `userSession/${firebaseId}`), {
      isActive,
      datetime,
      sessionId
    })
  }

  const userStateChanged = auth.onAuthStateChanged(async (user) => {
    /**
     * Not using firebase listeners as they recommend because of too many issues on when things fire
     * Also unable to unsubscribe as mentioned in the docs
     */
    if (user) {
      await setUserAuth(
        UserAuth.create({
          accessToken: user.accessToken,
          email: user.email,
          refreshToken: user.refreshToken,
          firebaseId: user.uid
        }).toDict
      )
    }
  })

  function uploadImage(imageName, imageData, firebaseId) {
    let url = null
    const storageRef = gRef(storage, `images/${firebaseId}/${imageName}`)
    const uploadTask = uploadBytes(storageRef, imageData)

    return uploadTask
  }

  async function signIn(data = {}) {
    const { email, password } = data
    try {
      const res = await signInWithEmailAndPassword(auth, email, password)

      checkSingleSession(res.user.uid, singleSessionId.value, res.user.metadata.lastLoginAt)

      return res
    } catch (e) {
      const { code, message, name } = e
      if (firebaseAuthErrorCodesInvalid.includes(code)) {
        Alert.alert({ message: 'Invalid email/password combination', type: 'error' })
      } else if (firebaseAuthErrorCodesAccountLocked.includes(code)) {
        Alert.alert({ message: message, type: 'error' })
      } else {
        Alert.alert({ message: message, type: 'error' })
      }
    }
  }
  async function signOutUser() {
    clearUserSession(user.value.firebaseId, singleSessionId.value, Date.now())
    await signOut(auth)
  }

  async function sendPasswordEmail(email) {
    return await sendPasswordResetEmail(auth, email)
  }
  async function checkVerifCode(oobCode) {
    console.log('here')
    return await checkActionCode(auth, oobCode)
  }
  async function resetPassword(code, pass) {
    return await confirmPasswordReset(auth, code, pass)
  }
  async function createAuthForUser(data = {}) {
    const { email, password } = data
    try {
      return await createUserWithEmailAndPassword(auth, email, password)
    } catch (e) {
      const { code, message, name } = e
      switch (code) {
        case AUTH_INVALID_EMAIL:
          Alert.alert({ message: 'Please enter a valid email', type: 'error' })
          break
        case AUTH_EMAIL_DUPLICATE_EMAIL:
          Alert.alert({ message: 'A user with this email already exists', type: 'error' })
          break
        case AUTH_WEEK_PASSWORD:
          Alert.alert({ message: `Please enter a valid password, ${message}`, type: 'error' })
          break
        default:
          Alert.alert({ message: `An unknown error occured ${message}`, type: 'error' })
          break
      }
    }
  }

  return {
    firebase,
    storage,
    uploadImage,
    createUserWithEmailAndPassword,
    signIn,
    onAuthStateChanged,
    sendPasswordEmail,
    firebaseAuthErrorCodesInvalid,
    resetPassword,
    createAuthForUser,
    signOutUser,
    userStateChanged,
    clearUserSession,
    setUserSession,
    checkVerifCode
  }
}

<template>
  <vue-final-modal
    v-slot="{ close }"
    v-bind="$attrs"
    classes="modal-container"
    content-class="modal-content"
    name="imageUpload"
  >
    <div class="box">
      <div class="modal__heading">
        <div class="modal__close">
          <button @click="close" class="button is-rounded">
            <span class="icon is-small">
              <i class="fas fa-times"></i>
            </span>
          </button>
        </div>
        <div class="modal__title has-text-centered">
          <span class="subtitle">Select an Image</span>
        </div>
      </div>
      <div class="modal__content">
        <FileUpload
          ref="uploader"
          @fileLoaded="setFile"
          @blobUrlLoaded="setBlob"
          :fileTypes="['image/*']"
        >
        </FileUpload>
      </div>
      <button class="button is-fullwidth" @click="openImageSearchModal">
        Add From Image Library
      </button>
      <button v-if="hasPremium" @click="openBrandKitImagesModal" class="button is-fullwidth">
        Add From Your Kit
      </button>
      <button v-else @click="showUpgradeModal" class="button is-fullwidth">
        Add From Your Kit
      </button>
      <button @click="close" class="button is-fullwidth is-danger">Cancel</button>
    </div>
  </vue-final-modal>
</template>

<script>
import { onMounted, inject, ref } from 'vue'
import yearlyStore from '@/composables/yearlyStore'

import FileUpload from '@/components/FileUpload'
import CommonModals from '@/composables/commonModals'
import UpgradeButton from '@/components/UpgradeButton'

export default {
  name: 'ImageUpload',
  inheritAttrs: false,
  components: { FileUpload },
  props: {
    showModal: {
      type: Boolean,
      default: false
    }
  },
  emits: ['image-ready', 'blob-ready', 'image-from-unsplash', 'brand-kit-image-ready'],
  setup(_, { emit }) {
    const $vfm = inject('$vfm')
    const file = ref(null)
    const fileBlob = ref(null)
    const url = ref(null)
    const needsUpload = ref(false)
    const { user, brandKit, hasBrandKit, hasPremium } = yearlyStore()
    const {
      showHelpModal,
      showSubscriptionModal,
      showImageUnsplashModal,
      showBrandKitImagesModal
    } = CommonModals()
    function setFile(f) {
      if (f) {
        file.value = f
        needsUpload.value = true

        url.value = URL.createObjectURL(f)
      }
      emit('image-ready', file.value)
    }
    function setBlob(f) {
      if (f) {
        fileBlob.value = f
        needsUpload.value = true

        //url.value = URL.createObjectURL(f)
      }
      emit('blob-ready', file.value)
    }

    const getFileBlob = function (url, cb) {
      var xhr = new XMLHttpRequest()
      xhr.open('GET', url)
      xhr.responseType = 'blob'
      xhr.addEventListener('load', function () {
        cb(xhr.response)
      })
      xhr.send()
    }
    function imageLoadCb(res) {
      emit('image-from-unsplash', res)
    }
    async function openImageSearchModal() {
      await showImageUnsplashModal()
      /*       $vfm.show({
        component: ImageListModal,
        on: {
          'image-ready': async (e) => {
            //onSaveAndUse(e)
            await getFileBlob(e, imageLoadCb)

            $vfm.hideAll()
          }
        }
      }) */
    }

    async function openBrandKitImagesModal() {
      await showBrandKitImagesModal()
    }
    async function showUpgradeModal() {
      await showHelpModal(
        'Upgrade To Yearly Pro or Premium!',
        'This feature is not available for this report',
        'Save photos and logos here by upgrading to Yearly Pro or Premium now',
        {},
        {},
        {
          'modal-content-1': { component: UpgradeButton }
        }
      )
    }

    return {
      setFile,
      openImageSearchModal,
      setBlob,
      openBrandKitImagesModal,
      user,
      hasBrandKit,
      hasPremium,
      showUpgradeModal
    }
  }
}
</script>

<style lang="scss" scoped></style>

import { createStore } from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import User, { UserAccount } from '../services/users/'
import BrandKit from '../services/brandKits/'
import { v4 } from 'uuid'

const STORAGE_HASH = 'Ax4XDbqIDE'
export const STORAGE_KEY = `yearly-app-${STORAGE_HASH}`

const state = {
  auth: null,
  account: null,
  user: null,
  token: null,
  brandKit: null,
  firebaseSingleSessionId: v4(),
  shouldShowLogoutModal: false,
  storedReportVersions: {},
  storedReportVersionsForAccount: null
}
const mutations = {
  SET_REPORTS_SAVED_FOR: (state, payload) => {
    state.storedReportVersionsForAccount = payload
  },
  SET_USER: (state, payload) => {
    state.user = payload
  },
  SET_USER_ACCOUNT: (state, payload) => {
    state.account = payload
  },
  SET_USER_AUTH: (state, payload) => {
    state.auth = payload
  },
  SET_USER_TOKEN: (state, payload) => {
    state.token = payload
  },
  SET_USER_BRAND_KIT: (state, payload) => {
    state.brandKit = payload
  },
  LOG_OUT: (state) => {
    state.token = null
    state.brandKit = null
    state.user = null
    state.account = null
    state.auth = null
    state.shouldShowLogoutModal = false
  },
  SET_SHOW_LOGOUT_MODAL: (state, payload) => {
    state.shouldShowLogoutModal = payload
  },
  SET_NEW_REPORT_VERSION: (state, payload) => {
    let id = payload.reportId

    if (!id) return
    if (state?.storedReportVersions[id]) {
      let versions = state.storedReportVersions[id]
      versions.length >= 5 ? versions.pop() : null
      versions.unshift({
        versionId: payload.versionId,

        report: payload.report,
        saved: new Date()
      })
    } else {
      state.storedReportVersions[id] = [
        {
          versionId: payload.versionId,
          report: payload.report,
          saved: new Date()
        }
      ]
    }
  }
}
const actions = {
  refreshUser: async ({ commit, state }) => {
    try {
      const res = await UserAccount.api.retrieve(state?.auth?.firebaseId)
      console.log(res)
      commit('SET_USER_ACCOUNT', res.toDict)
      if (
        !state?.storedReportVersionsForAccount ||
        state?.storedReportVersionsForAccount != res.firebaseId
      ) {
        state.storedReportVersions = {}
      }
      commit('SET_REPORTS_SAVED_FOR', res.firebaseId)
    } catch (e) {
      console.log(e)
    }
  },
  refreshBrandKit: async ({ commit, state }) => {
    try {
      const res = await BrandKit.api.retrieve(state?.auth?.firebaseId)

      commit('SET_USER_BRAND_KIT', res)
    } catch (e) {
      console.log(e)
    }
  }
}
const modules = {}
const getters = {
  singleSessionId: (state) => {
    return state.firebaseSingleSessionId
  },
  user: (state) => {
    return { ...state.auth, ...state.account }
  },
  token: (state) => {
    return state?.auth?.accessToken
  },
  hasBrandKit: (state) => {
    return !!state.brandKit
  },
  hasAccount: (state) => {
    return !!state.account
  },
  isLoggedIn: (state) => {
    return !!state.auth?.accessToken && !!state.auth?.firebaseId
  },
  brandKit: (state) => {
    return state.brandKit
  },
  isLegacyPlan: (state) => {
    return state?.account?.isLegacy
  },
  isFreeTrialPlan: (state) => {
    return !state?.account?.subscribedPlan || state?.account?.subscribedPlan == 'free'
  },
  canAccessPremiumFeatures: (state) => {
    return (
      state?.account?.subscribedPlan == 'premium' ||
      state?.account?.subscribedPlan == 'premium_plus'
    )
  },
  canAccessPremiumPlusFeatures: (state) => {
    return state?.account?.subscribedPlan == 'premium_plus'
  },
  showLogoutModal: (state) => {
    return state?.shouldShowLogoutModal
  },
  storedReportVersions: (state) => {
    return state?.storedReportVersions
  }
}

const store = createStore({
  state,
  actions,
  mutations,
  modules,
  getters,
  plugins: [
    createPersistedState({
      key: STORAGE_KEY
    })
  ]
})

export default store

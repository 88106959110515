import Model, { fields } from '@thinknimble/tn-models'

export default class UserAuth extends Model {
  static accessToken = new fields.CharField()
  static firebaseId = new fields.CharField()
  static email = new fields.CharField()
  static refreshToken = new fields.CharField()
  get toDict() {
    let data = {}
    Object.entries(this).forEach(([key, val]) => {
      if (key != '_fields') {
        data[key] = val
      }
    })
    return data
  }
}

import firebaseFunctions from '@/composables/firebaseFunctions'
import { ref as gRef, getStorage, getDownloadURL, uploadBytes } from '@firebase/storage'

export default function imageFunctions() {
  const { uploadImage } = firebaseFunctions()
  async function uploadImageAndRetrieveUrl(e = {}, firebaseId) {
    console.log(e, firebaseId)
    let url = null

    await uploadImage(e.name, e, firebaseId).then(async (snapshot) => {
      await getDownloadURL(snapshot.ref).then((URL) => {
        url = URL
      })
    })
    return url
  }
  return {
    uploadImageAndRetrieveUrl
  }
}

import { ModelAPI, objectToCamelCase } from '@thinknimble/tn-models'

import AxiosClient from '../AxiosClient'
import { apiErrorHandler } from '../api'

const LOGIN_ENDPOINT = 'api/login/'
const PASSWORD_RESET_EMAIL_ENDPOINT = ''
const PASSWORD_RESET_ENDPOINT = ''
const REGISTRATION_ENDPOINT = ''
const USERS_ENDPOINT = 'api/users/'

export default class UserAPI extends ModelAPI {
  /**
   * ModelAPI contains methods for list and create (overridden here) and the FILTERS_MAP
   * You may override any of these methods by statically defining them here
   * e.g static FILTERS_MAP={...UserAPI.FILTERS_MAP, <FITERS>}
   *      list({ filters = {}, pagination = {} }){
   *
   * }
   */

  get client() {
    return AxiosClient
  }

  static ENDPOINT = USERS_ENDPOINT
}

import TwitterWidgets from 'twitter-widgets'
import Rollbar from 'rollbar'

export const isSecureHost = (testMode) => {
  if (testMode) return true
  return window.location.hostname === 'localhost' || window.location.protocol === 'https:'
}
export const VUE_STRIPE_VERSION = require('@vue-stripe/vue-stripe/package.json').version
export const STRIPE_PARTNER_DETAILS = {
  name: 'vue-stripe',
  version: VUE_STRIPE_VERSION,
  url: 'https://vuestripe.com',
  partner_id: 'pp_partner_IqtOXpBSuz0IE2'
}

export const INSECURE_HOST_ERROR_MESSAGE =
  'Vue Stripe will not work on an insecure host. Make sure that your site is using TCP/SSL.'
const StripePlugin = {
  install(app, options) {
    if (!isSecureHost(options.testMode)) console.warn(INSECURE_HOST_ERROR_MESSAGE)
    const { pk, stripeAccount, apiVersion, locale } = options
    const stripe = window.Stripe(pk, { stripeAccount, apiVersion, locale })
    stripe.registerAppInfo(STRIPE_PARTNER_DETAILS)
    app.config.globalProperties.$stripe = stripe

    app.provide('$stripe', stripe)
  }
}
const TwitterPlugin = {
  install(app, opts) {
    let twttrPlug = null
    TwitterWidgets.load(function (err, twttr) {
      if (err) {
        //do some graceful degradation / fallback
        console.log('twitterload error', err)
        return
      }
      if (twttr) {
        twttrPlug = twttr
      }
      app.config.globalProperties.$twttr = twttrPlug
      app.provide('$twttr', twttrPlug)
    })
  }
}
const RollbarPlugin = {
  install(app, opts, enable = false) {
    if (!enable) {
      app.config.globalProperties.$rollbar = config
      app.provide('$rollbar', config)
      return null
    }
    let config = new Rollbar({ ...opts })
    app.config.globalProperties.$rollbar = config
    app.provide('$rollbar', config)
  }
}
export { StripePlugin, TwitterPlugin, RollbarPlugin }

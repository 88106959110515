/*
 *  Helper functions to handle routing.
 */

import store from '@/store'
/*
 * vue-router beforeEnter-style function to check user auth
 * status and redirect appropriately.
 */
export function requireAuth(to, from) {
  const user = store.getters.user
  const isLoggedIn = store.getters.isLoggedIn
  const hasAccount = store.getters.hasAccount

  if (!isLoggedIn) {
    return {
      path: '/login',
      query: { redirect: to.fullPath }
    }
  } else if (isLoggedIn && !hasAccount) {
    return {
      name: 'AccountRegistrationPage',
      query: { showModal: true }
    }
  } else {
    return true
  }
}
export function noAuthOnly(to, from) {
  const isLoggedIn = store.getters.isLoggedIn

  if (isLoggedIn) {
    return { name: 'DashboardPage' }
  }
  return
}
export function registrationRouteGuard(to, from) {
  const isLoggedIn = store.getters.isLoggedIn
  const hasAccount = store.getters.hasAccount

  if (isLoggedIn && hasAccount) {
    return { name: 'DashboardPage' }
  }

  if (to.name == 'AccountRegistrationPage' && !isLoggedIn && !hasAccount) {
    return { name: 'AuthRegistrationPage' }
  } else if (to.name == 'AccountRegistrationPage' && isLoggedIn && !hasAccount) {
    return true
  } else if (to.name == 'AuthRegistrationPage' && !isLoggedIn && !hasAccount) {
    return true
  } else if (to.name == 'AuthRegistrationPage' && isLoggedIn && !hasAccount) {
    return { name: 'AccountRegistrationPage' }
  } else if (
    to.name == 'SubscriptionRegistrationPage' &&
    !isLoggedIn &&
    !hasAccount &&
    !from.name == 'AccountRegistrationPage'
  ) {
    return { name: 'AuthRegistrationPage' }
  }
}

export default {
  requireAuth
}

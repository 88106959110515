<template>
  <vue-final-modal
    v-slot="{ close }"
    v-bind="$attrs"
    classes="modal-container"
    content-class="modal-content"
    name="DonutChartOptions"
    @before-open="onOpened"
  >
    <div class="box">
      <div class="modal__heading">
        <div class="modal__close">
          <button @click="close" class="button is-rounded">
            <span class="icon is-small">
              <i class="fas fa-times"></i>
            </span>
          </button>
        </div>
        <div class="modal__title has-text-centered">
          <span class="subtitle">Add Chart to report</span>
        </div>
      </div>
      <div class="modal__content">
        <div class="graph-opts">
          <div class="tab-content">
            <div class="form">
              <FormFieldVue
                v-model="chartForm.title.value"
                placeholder="Report Title"
                helpText="Give your report an easily identifiable name"
                labelText="Title"
              />
              <FormFieldVue
                v-model="chartForm.valueSuffix.value"
                placeholder="%"
                helpText="Add a suffix to your values (%,*$)"
                labelText="Value Suffix"
              />
              <FormFieldVue
                v-model="chartForm.valuePrefix.value"
                placeholder="$"
                helpText="Add a prefix to your values (%,#,*,$)"
                labelText="Value Prefix"
              />
              <FormFieldVue
                v-model="chartForm.valuePrefix.value"
                placeholder="$"
                helpText="Add a prefix to your values (%,#,*,$)"
                labelText="Value Prefix"
              >
                <template #field-input>
                  <div class="select">
                    <select class="input" v-model="chartForm.chartType.value">
                      <option :key="i" :value="opt.key" v-for="(opt, i) in chartTypeOpts">
                        {{ opt.label }}
                      </option>
                    </select>
                  </div></template
                >
              </FormFieldVue>
              <div class="chart-data">
                <button @click="addChartRow()" class="button">Add Row</button>
                <div class="rows">
                  <div class="columns">
                    <div class="column is-3"></div>
                    <div class="column is-3">Label</div>
                    <div class="column is-3">Value</div>
                    <div class="column is-3">Color</div>
                  </div>
                </div>
                <div
                  :key="index"
                  v-for="(dataset, index) in chartForm.datasets.groups"
                  class="rows"
                >
                  <div class="columns">
                    <div class="column is-1">
                      <button @click="deleteRow" class="delete"></button>
                    </div>
                    <div class="column">
                      <FormFieldVue v-model="dataset.label.value" placeholder="Enter a label" />
                    </div>
                    <div class="column">
                      <FormFieldVue
                        type="number"
                        v-model="dataset.field.dataValue.value"
                        placeholder="Value"
                      />
                    </div>

                    <div class="column">
                      <FormFieldVue>
                        <template #field-input>
                          <Popper>
                            <button class="button">
                              <span
                                style="width: 4rem; height: 1rem; border-radius: 2px"
                                :style="`backgroundColor:${dataset.backgroundColor.value}`"
                              ></span>
                            </button>
                            <template #content>
                              <div>
                                <ColorPickerVue
                                  @changeColor="
                                    ($event) => {
                                      dataset.backgroundColor.value = formatFontColor($event)
                                    }
                                  "
                                />
                              </div>
                            </template>
                          </Popper>
                        </template>
                      </FormFieldVue>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="actions" style="display: flex; justify-content: center; margin-top: 1rem">
          <button @click="close" class="button">Cancel</button>
          <button
            v-if="!isUpdate"
            class="button is-primary"
            :disabled="!chartForm.isValid"
            @click="onSubmit"
          >
            Submit
          </button>
          <button v-else class="button is-primary" :disabled="!chartForm.isValid" @click="onUpdate">
            Update
          </button>
        </div>
      </div>
    </div>
  </vue-final-modal>
</template>

<script>
import { onMounted, inject, ref, watchEffect, computed, toRefs } from 'vue'

import Form, { FormField, FormArray } from '@thinknimble/tn-forms'
import { RequiredValidator, MinLengthValidator, NumberValidator } from '@thinknimble/tn-validators'

import ColorPickerVue from '@/components/ColorPicker'
import FormFieldVue from '@/components/FormField'

function addRandomColor() {
  return `rgb(${Math.ceil(Math.random() * 254)},${Math.ceil(Math.random() * 254)},${Math.ceil(
    Math.random() * 254
  )})`
}

class Dataset extends Form {
  static label = new FormField({})
  static dataValue = new FormField()
  static dataValueUnit = new FormField()
  static backgroundColor = new FormField({
    value: addRandomColor()
  })
}
class DatasetForm extends Form {
  static label = new FormField({})
  static dataValue = new FormField()
  static dataValueUnit = new FormField()
  static backgroundColor = new FormField({
    value: addRandomColor()
  })
}

class ChartForm extends Form {
  static chartType = new FormField()
  static title = new FormField()
  static datasets = new FormArray({ name: 'datasets', groups: [new Dataset()] })
  static valuePrefix = new FormField()
  static valueSuffix = new FormField()

  formVal() {
    let valMap = {
      title: this.value.title,
      chartType: this.value.chartType,
      labels: this.value.datasets.map((dataset) => dataset.label),
      datasets: [
        this.value.datasets.reduce(
          (acc, curr) => {
            acc['data'] = [...acc['data'], curr.dataValue]
            acc['backgroundColor'] = [...acc['backgroundColor'], curr.backgroundColor]
            return acc
          },
          { data: [], backgroundColor: [] }
        )
      ],
      valuePrefix: this.value.valuePrefix,
      valueSuffix: this.value.valueSuffix
    }

    return valMap
  }

  static reset() {
    return new this()
  }
  addNewDataset() {
    this.addToArray('datasets', new Dataset({ backgroundColor: addRandomColor() }))
  }
  addExistingDataset(data) {
    //let dataSet = new Dataset({ dataValue: data[1], label: data[0], backgroundColor: data[2] })

    this.addToArray(
      'datasets',
      new Dataset({ dataValue: data[1], label: data[0], backgroundColor: data[2] })
    )
  }
}
export default {
  name: 'DonutChartOptionsModal',
  inheritAttrs: false,
  components: { FormFieldVue, ColorPickerVue },

  props: {
    isUpdate: {
      type: Boolean,
      default: false
    },
    showModal: {
      type: Boolean,
      default: false
    },
    valuePrefix: {
      type: String,
      default: ''
    },
    valueSuffix: {
      type: String,
      default: ''
    },
    chartLabels: {
      type: Array,
      default: () => []
    },
    chartDatasets: {
      type: Array,
      default: () => []
    },
    title: {
      type: String,
      default: ''
    },
    chartType: {
      type: String,
      default: 'donut'
    }
  },
  emits: ['new-chart-data'],
  setup(props, { emit }) {
    const $vfm = inject('$vfm')

    const { title, chartDatasets, chartLabels, valueSuffix, valuePrefix, chartType } = toRefs(props)
    const elementIndex = ref(null)

    const chartTypeOpts = [
      { label: 'Donut', key: 'donut' },
      { label: 'Pie', key: 'pie' }
    ]

    const chartForm = ref(new ChartForm({ chartType: chartType.value }))

    onMounted(() => {})
    //TODO:- Move this to a composable
    function formatFontColor(color) {
      if (color) {
        const { r, g, b, a } = { ...color.rgba }
        return `rgba(${r},${g},${b},${a})`
      }
    }

    function onOpened(event) {
      let chartFormData = {
        title: title.value,
        valueSuffix: valueSuffix.value,
        valuePrefix: valuePrefix.value,
        chartType: chartType.value,
        datasets: []
      }
      chartForm.value = new ChartForm({ ...chartFormData })
      chartLabels.value.forEach((label, index) => {
        // hack formarray creates a blank entry on init we replace this
        if (index == 0) {
          chartForm.value.datasets.groups[0] = new DatasetForm({
            label: label,
            dataValue: chartDatasets.value[0].data[index],
            backgroundColor: chartDatasets.value[0].backgroundColor[index]
          })
        } else {
          chartForm.value.addExistingDataset([
            label,
            chartDatasets.value[0].data[index],
            chartDatasets.value[0].backgroundColor[index]
          ])
        }
        return
      })
    }

    function addChartRow() {
      chartForm.value.addNewDataset()
    }

    function onSubmit() {
      chartForm.value.validate()
      if (!chartForm.value.isValid) {
        return
      }

      emit('new-chart-data', chartForm.value.formVal())
    }
    function deleteRow(i) {
      chartForm.value.removeFromArray('datasets', i)
    }
    function onUpdate() {
      chartForm.value.validate()
      if (!chartForm.value.isValid) {
        return
      }

      emit('update-donut-chart', chartForm.value.formVal())
    }
    return {
      onSubmit,
      chartForm,
      chartTypeOpts,
      addChartRow,
      onOpened,
      onUpdate,
      deleteRow,
      formatFontColor,
      test: new Dataset()
    }
  }
}
</script>

<style lang="scss" scoped></style>

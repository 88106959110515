import { vue, inject } from 'vue'

export default function trackingFunctions() {
  const REGISTRATION_STEP_AUTH = 'create_firebase_user'
  const REGISTRATION_STEP_ACCOUNT = 'create_yearly_account'
  const REGISTRATION_STEP_SUBSCRIPTION = 'create_yearly_subscription'
  const COMPLETE_REGISTRATION = 'complete_registration'

  const gtag = inject('$gtag')

  function trackLogin() {
    gtag.event('login')
  }
  function trackSignup(step) {
    gtag.event(`sign_up_${step}`)
    //fbq('track', 'CompleteRegistration');
  }
  function trackPurchase(planKey, planLabel, firebaseId, totalAmount, promoCode = null) {
    gtag.event('purchase', {
      currency: 'USD',
      transaction_id: firebaseId,
      value: totalAmount,
      affiliation: 'yearly.report',
      coupon: promoCode,
      shipping: 0,
      tax: 0,
      items: [
        {
          item_id: planKey,
          item_name: planLabel,
          affiliation: 'yearly.report',
          coupon: promoCode,
          currency: 'USD',
          discount: 0,
          index: 1,
          item_brand: planLabel,
          item_category: '',
          item_category2: '',
          item_category3: '',
          item_category4: '',
          item_category5: '',
          item_list_id: '',
          item_list_name: '',
          item_variant: '',
          location_id: '',
          price: totalAmount,
          quantity: 1
        }
      ]
    })

    //fbq('track', 'Purchase', { value: totalAmount, currency: 'USD' })
  }
  return {
    trackSignup,
    trackPurchase,
    REGISTRATION_STEP_ACCOUNT,
    REGISTRATION_STEP_AUTH,
    REGISTRATION_STEP_SUBSCRIPTION,
    COMPLETE_REGISTRATION,
    trackLogin
  }
}

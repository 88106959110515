<template>
  <label class="toggle">
    <input
      type="checkbox"
      class="toggle"
      @input="emitValue"
      :value="modelValueRef"
      :checked="isOn"
      :disabled="disabled"
    />
    <div :style="checkboxStyles">
      <div></div>
    </div>
  </label>
</template>

<script>
/**
 * @component ToggleCheckBox
 *
 * @contributors
 *   Bruno Gonzalez
 *
 * Custom <input type="checkbox" /> by Robin, componentized by Bruno. Toggling is animated and styling
 * is controlled by the four SCSS variables defined in the <style /> section.
 *
 * @example
 *   <ToggleCheckBox v-model="showUsers" />
 *
 * Props:
 *  @prop {Boolean} value - Whether the checkbox is checked(true) or not(false).
 *  @prop {Boolean} offColor - Background color to use when the checkbox is 'off'.
 *  @prop {Boolean} onColor - Background color to use when the checkbox is 'on'.
 *  @prop {Boolean} invert - Invert the true/false value of the model. The toggle will be
 *                           'on' when the value is false and 'off' when the value is on.
 *
 * Events:
 *  @event input - Emitted when the checkbox is clicked.
 */
import {
  emit,
  toRefs,
  ref,
  toRef,
  computed,
  watch,
  onBeforeMount,
  onMounted,
  watchEffect
} from 'vue'
import { useModelWrapper } from '@/composables/modelValue'
export default {
  name: 'ToggleCheckBox',
  props: {
    modelValue: {},
    offColor: {
      type: String,
      default: 'lightgray'
    },
    onColor: {
      type: String,
      default: 'blue'
    },
    invert: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    onValue: {
      default: true
    },
    offValue: {
      default: false
    }
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    const { disabled, modelValue, onValue, offValue, offColor, onColor, invert } = toRefs(props)
    const modelValueRef = ref(false)
    const isOn = computed(() => modelValueRef.value == true)
    const checkboxStyles = computed(() => {
      if (disabled.value) {
        return {
          backgroundColor: 'darkgray',
          cursor: 'not-allowed'
        }
      }

      return {
        backgroundColor: invert.value
          ? isOn.value
            ? offColor.value
            : onColor.value
          : isOn.value
          ? onColor.value
          : offColor.value
      }
    })
    onMounted(() => {})
    function emitValue() {
      modelValueRef.value = !modelValueRef.value
      if (modelValueRef.value) {
        emit('update:modelValue', onValue.value)
      } else {
        emit('update:modelValue', offValue.value)
      }
    }
    watchEffect(() => {
      if (modelValue.value == onValue.value) {
        modelValueRef.value = true
      } else {
        modelValueRef.value = false
      }
    })
    return {
      checkboxStyles,
      modelValueRef,
      isOn,
      emitValue
    }
  }
}
</script>

<style lang="scss">
/* apply desired values to the following variables to customize the colors of the checkbox */
$track-color-unchecked: #d7d7dd;
$track-color-checked: #199e54;
$knob-color-unchecked: white;
$knob-color-checked: white;

label.toggle {
  cursor: pointer;
  height: auto;
  width: auto;
}

input[type='checkbox'].toggle {
  display: none;
}

// Unchecked Track
input[type='checkbox'].toggle + div {
  vertical-align: middle;
  width: 40px;
  height: 20px;
  border-radius: 999px;
  background-color: $track-color-unchecked; // $track-color-unchecked
  transition-duration: 0.4s;
  transition-property: background-color, box-shadow;
  box-shadow: inset 0 0 0 0px rgba(151, 109, 109, 0.4);
}

// Checked Track
input[type='checkbox'].toggle:checked + div {
  width: 40px;
  background-position: 0 0;
  background-color: $track-color-checked; // $track-color-checked
}

// Unchecked Knob
input[type='checkbox'].toggle + div > div {
  float: left;
  width: 18px;
  height: 18px;
  border-radius: inherit;
  background: $knob-color-unchecked; // $knob-color-unchecked
  transition-timing-function: cubic-bezier(0.54, 1.85, 0.5, 1);
  transition-duration: 0.4s;
  transition-property: transform, background-color, box-shadow;
  transition-timing-function: cubic-bezier(0.54, 1.85, 0.5, 1);
  transition-duration: 0.4s;
  transition-property: transform, background-color;
  pointer-events: none;
  margin-top: 1px;
  margin-left: 1px;
}

// Checked Knob
input[type='checkbox'].toggle:checked + div > div {
  transform: translate3d(20px, 0, 0);
  transform: translate3d(20px, 0, 0);
  background-color: $knob-color-checked; // $knob-color-checked
}
</style>

import Model, { fields, ModelAPI, ApiFilter } from '@thinknimble/tn-models'
import { objectToSnakeCase } from '@thinknimble/tn-utils'

import Client from '../AxiosClient'
import { apiErrorHandler } from '../api'
export default class BrandKitAPI extends ModelAPI {
  static FILTER_MAPS = {
    ...BrandKitAPI.FILTER_MAPS,
    firebaseId: ApiFilter.create({ key: 'firebase_id' })
  }

  get client() {
    return Client
  }

  async retrieve(firebaseId) {
    const url = `/api/v1/accounts/${firebaseId}/brand_kits/`
    try {
      const res = await this.client.get(url)

      return this.cls.fromAPI(res.data.brand_kit)
    } catch (e) {
      apiErrorHandler({
        apiName: 'Error Retrieving Asset with id',
        enable400Alert: false,
        enable401Alert: true,
        rethrowErrors: true
      })(e)
    }
  }
  async addFontFile(firebaseId, brandKitId, fontId, file) {
    const url = `/api/v1/accounts/${firebaseId}/brand_kits/${brandKitId}/fonts/${fontId}/file`
    const data = new FormData()

    data.append('file', file)
    try {
      const res = await this.client.put(url, data)

      return res
    } catch (e) {
      apiErrorHandler({
        apiName: 'Error Retrieving Asset with id',
        enable400Alert: false,
        enable401Alert: true,
        rethrowErrors: true
      })(e)
    }
  }
  async addFont(firebaseId, brandKitId, data = {}) {
    const url = `/api/v1/accounts/${firebaseId}/brand_kits/${brandKitId}/fonts/`
    delete data['file']
    const d = this.cls.FONT_CLS.toAPI(data)

    try {
      const res = await this.client.post(url, d)

      return this.cls.FONT_CLS.fromAPI(res.data.font)
    } catch (e) {
      apiErrorHandler({
        apiName: 'Error Retrieving Asset with id',
        enable400Alert: false,
        enable401Alert: true,
        rethrowErrors: true
      })(e)
    }
  }
  async deleteBKImage(firebaseId, brandKitId, imageId) {
    const url = `/api/v1/accounts/${firebaseId}/brand_kits/${brandKitId}/purge/`

    try {
      return await this.client.put(url, { attachment_id: imageId })
    } catch (e) {
      apiErrorHandler({
        apiName: 'Error Retrieving Asset with id',
        enable400Alert: false,
        enable401Alert: true,
        rethrowErrors: true
      })(e)
    }
  }
  async addBKImage(firebaseId, brandKitId, file) {
    const url = `/api/v1/accounts/${firebaseId}/brand_kits/${brandKitId}/images/`

    const d = new FormData()
    d.append('image', file)

    try {
      return await this.client.put(url, d)
    } catch (e) {
      apiErrorHandler({
        apiName: 'Error Retrieving Asset with id',
        enable400Alert: false,
        enable401Alert: true,
        rethrowErrors: true
      })(e)
    }
  }
  async updateBKColors(firebaseId, brandKitId, data = []) {
    const url = `/api/v1/accounts/${firebaseId}/brand_kits/${brandKitId}/`

    const d = data.map((c) => this.cls.toAPI(c))

    try {
      return await this.client.put(url, { colors: d })
    } catch (e) {
      console.log(e)
      apiErrorHandler({
        apiName: 'Error Retrieving Asset with id',
        enable400Alert: false,
        enable401Alert: true,
        rethrowErrors: true
      })(e)
    }
  }
  async deleteFont(firebaseId, brandKitId, fontId) {
    const url = `/api/v1/accounts/${firebaseId}/brand_kits/${brandKitId}/fonts/${fontId}/`

    try {
      const res = await this.client.delete(url)

      return res
    } catch (e) {
      apiErrorHandler({
        apiName: 'Error Deleting font',
        enable400Alert: false,
        enable401Alert: true,
        rethrowErrors: true
      })(e)
    }
  }
}

<template>
  <!-- This component is not being used at the moment it is a wrapper around the VueDragResize component for tiptap-->
  <node-view-wrapper class="draggable-item">
    <VDragResize :draggable="true">
      <template #default>
        <node-view-content class="content" />
      </template>
    </VDragResize>
  </node-view-wrapper>
</template>

<script>
import { NodeViewWrapper, NodeViewContent, nodeViewProps } from '@tiptap/vue-3'
import { toRefs } from 'vue'
import VDragResize from '@/components/VueDragResize'

export default {
  props: nodeViewProps,

  components: {
    NodeViewWrapper,
    NodeViewContent,
    VDragResize
  },
  setup(props) {
    const { decorations, getPos, node } = toRefs(props)

    return {
      getPos,
      decorations,
      node
    }
  }
}
</script>

<style lang="scss">
.draggable-item {
  .content {
    flex: 1 1 auto;
  }
}
</style>

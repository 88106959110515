<template>
  <div class="form"></div>
  <div class="columns is-multiline" style="margin-top: 1rem">
    <template v-if="!images?.length"
      ><p>
        No Images added to your brandkit, upload images
        <router-link :to="routeToBrandKit">here</router-link>
      </p></template
    >
    <template v-else>
      <div
        :key="i"
        class="column is-one-quarter-desktop is-half-tablet hover"
        v-for="(image, i) in images"
        @click="onSaveAndUse(image.url)"
      >
        <div class="card is-shadowless">
          <div class="card-image">
            <figure class="image is-3by2">
              <img :src="image.url" :alt="image.altText" style="object-fit: cover" />
            </figure>
          </div>
          <footer class="card-footer"></footer>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { onMounted, inject, ref } from 'vue'
import { useRouter } from 'vue-router'
import yearlyStore from '@/composables/yearlyStore'

export default {
  name: 'ImageListModal',
  inheritAttrs: false,
  components: {},
  props: {
    showModal: {
      type: Boolean,
      default: false
    },
    lockScroll: {
      default: false
    }
  },
  emits: ['brand-kit-image'],
  setup(_, { emit }) {
    const $vfm = inject('$vfm')
    const router = useRouter()
    const { brandKit, hasBrandKit, user } = yearlyStore()

    const images = user.value.isPlanPaid && hasBrandKit.value ? ref(brandKit.value.images) : []
    async function routeToBrandKit() {
      await $vfm.hideAll()
      router.push({ name: 'BrandKitPage' })
    }
    onMounted(async () => {})
    function onSaveAndUse(url) {
      emit('brand-kit-image', url)
    }

    return {
      images,
      routeToBrandKit,

      onSaveAndUse
    }
  }
}
</script>

<style lang="scss" scoped></style>

<template>
  <teleport
    :to="teleportTo"
    :disabled="disableTeleport"
    v-if="isVisible"
    id="teleport-slider"
    name="teleport-to"
  >
    <div class="" ref="colorPickerContainer" id="color-picker-container">
      <h3 class="subtitle is-6">Click to resize image</h3>
      <slider
        :modelValue="sliderValue"
        color="#FB278D"
        :step="20"
        :min="10"
        :max="800"
        track-color="#FEFEFE"
        @change="emitEvent('change', $event)"
        @dragging="emitEvent('dragging', $event)"
        @drag-start="emitEvent('drag-start', $event)"
        @drag-end="emitEvent('drag-end', $event)"
      ></slider>
    </div>
  </teleport>
</template>

<script>
import { toRefs, ref, emit, onMounted, watch, onUnmounted, onBeforeUnmount } from 'vue'

import slider from 'vue3-slider'
export default {
  components: { slider },
  name: 'ImageResizer',
  props: {
    isVisible: {
      type: Boolean,
      default: true
    },
    disableTeleport: {
      type: Boolean,
      default: true
    },
    sliderValue: {
      type: Number,
      default: 10
    },

    teleportTo: {
      type: String,
      default: '#app'
    }
  },
  emits: ['change', 'drag-start', 'drag-end', 'dragging', 'close', 'close:popper'],
  setup(props, { emit }) {
    const { disableTeleport, isVisible } = toRefs(props)

    const colorPickerContainer = ref(null)

    onMounted(() => {
      console.log('mounted')
    })
    watch(isVisible, (currentVal, previousVal) => {
      if (currentVal && !disableTeleport.value) {
        window.addEventListener('click', closeListener)
      }
    })
    function closeListener(event) {
      if (!colorPickerContainer.value?.contains(event.target)) {
        emit('close')

        window.removeEventListener('click', closeListener)
      }
    }
    onBeforeUnmount(() => {
      window.removeEventListener('click', closeListener)
    })

    return {
      colorPickerContainer,
      emitEvent: (eventName, val) => {
        console.log(val)
        emit(eventName, val)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
#color-picker-container {
  padding: 1rem;
  margin-top: 10px;
  width: 200px;
  height: 100px;
  background-color: whitesmoke;
}
</style>

import Client from '../AxiosClient'
import { ModelAPI, ApiFilter } from '@thinknimble/tn-models'
import { objectToCamelCase, objectToSnakeCase } from '@thinknimble/tn-utils'
import { apiErrorHandler } from '../api'

export default class SubscriptionAPI extends ModelAPI {
  static FILTER_MAPS = {
    ...SubscriptionAPI.FILTER_MAPS,
    firebaseId: ApiFilter.create({ key: 'firebase_id' })
  }
  static ENDPOINT = (firebaseId) => {
    return `api/v1/accounts/${firebaseId}/subscriptions/`
  }

  get client() {
    return Client
  }
  async list(firebaseId, { filters = {}, pagination = {} }) {
    const url = SubscriptionAPI.ENDPOINT(firebaseId)
    const params = ApiFilter.buildParams(SubscriptionAPI.FILTER_MAPS, {
      ...filters
    })
    try {
      const res = await this.client.get(url, { params })
      return this.cls.fromAPI(res.data.subscription)
    } catch (e) {
      apiErrorHandler({
        apiName: 'Error Retrieving Subscriptions {LIST}',
        enable400Alert: false,
        enable401Alert: true,
        rethrowErrors: true
      })(e)
    }
  }
  async retrieve(firebaseId) {
    const url = SubscriptionAPI.ENDPOINT(firebaseId)
    try {
      const res = await this.client.get(url)
      return this.cls.fromAPI(res.data.subscription)
    } catch (e) {
      apiErrorHandler({
        apiName: 'Error Retrieving Subscriptions {LIST}',
        enable400Alert: false,
        enable401Alert: true,
        rethrowErrors: true
      })(e)
    }
  }

  async create(firebaseId, data) {
    const url = SubscriptionAPI.ENDPOINT(firebaseId)
    const d = { subscription: this.cls.toAPI(data) }
    try {
      const res = await this.client.post(url, d)
      return this.cls.fromAPI(res.data.subscription)
    } catch (e) {
      apiErrorHandler({
        apiName: 'Error Retrieving Subscriptions {LIST}',
        enable400Alert: false,
        enable401Alert: true,
        rethrowErrors: true
      })(e)
    }
  }
  async unsubscribe(firebaseId, subscriptionId) {
    const url = SubscriptionAPI.ENDPOINT(firebaseId) + subscriptionId

    try {
      await this.client.delete(url)
    } catch (e) {
      apiErrorHandler({
        apiName: 'Error Deleting Subscription',
        enable400Alert: false,
        enable401Alert: true,
        rethrowErrors: true
      })(e)
    }
  }
  async checkPromoCode(code) {
    const url = `api/v1/promo_codes/${code}`
    try {
      const res = await this.client.get(url)
      return res.data
    } catch (e) {
      console.log('there was an error')
      apiErrorHandler({
        apiName: 'Invalid Promo Code',
        enable400Alert: false,
        enable401Alert: true,
        rethrowErrors: true
      })(e)
    }
  }
}

import Form, { FormField } from '@thinknimble/tn-forms'
import {
  RequiredValidator,
  NumberValidator,
  MustMatchValidator,
  EmailValidator
} from '@thinknimble/tn-forms/'

export default class UserForm extends Form {
  static name = new FormField({ validators: [new RequiredValidator()] })
  static username = new FormField({ validators: [new RequiredValidator()] })
  static organization = new FormField({ validators: [new RequiredValidator()] })
  static organizationSize = new FormField({ validators: [new RequiredValidator()] })
  static email = new FormField({ validators: [new RequiredValidator(), new EmailValidator()] })
  static organizationAddress = new FormField({ validators: [] })
  static organizationTwitter = new FormField({ validators: [] })
  static organizationFacebook = new FormField({ validators: [] })
  static organizationYoutube = new FormField({ validators: [] })
  static organizationInstagram = new FormField({ validators: [] })
  static organizationLinkedin = new FormField({ validators: [] })
  static organizationPinterest = new FormField({ validators: [] })
}

export class LoginForm extends Form {
  static email = new FormField({ validators: [new RequiredValidator(), new EmailValidator()] })
  static password = new FormField({ validators: [new RequiredValidator()] })
}

export const EmailField = new FormField({ validators: [new RequiredValidator()] })
export class ResetPasswordForm extends Form {
  static password = new FormField({ validators: [new RequiredValidator()] })
  static confirmPassword = new FormField({ validators: [new RequiredValidator()] })
  static oobCode = new FormField({ validators: [new RequiredValidator()] })
  static dynamicFormValidators = {
    confirmPassword: [new MustMatchValidator({ matcher: 'password' })]
  }
}
export class AuthRegistrationForm extends Form {
  static email = new FormField({ validators: [new RequiredValidator(), new EmailValidator()] })
  static password = new FormField({ validators: [new RequiredValidator()] })
  static confirmPassword = new FormField({ validators: [new RequiredValidator()] })
  static dynamicFormValidators = {
    confirmPassword: [new MustMatchValidator({ matcher: 'password' })]
  }
}

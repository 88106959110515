<template>
  <teleport
    :to="teleportTo"
    :disabled="disableTeleport"
    v-if="isVisible"
    id="teleport-color"
    name="teleport-to"
  >
    <div class="" ref="colorPickerContainer" id="color-picker-container">
      <color-picker
        id="color-picker"
        style="width: 250px"
        :colorsDefault="brandKitColors"
        @changeColor="$emit('changeColor', $event)"
      ></color-picker>
    </div>
  </teleport>
</template>

<script>
import { ColorPicker } from 'vue-color-kit'
import { toRefs, ref, emit, onMounted, watch, onUnmounted, onBeforeUnmount } from 'vue'
import yearlyStore from '@/composables/yearlyStore'

export default {
  components: { ColorPicker },
  name: 'ColorPickerVue',
  props: {
    isVisible: {
      type: Boolean,
      default: true
    },
    disableTeleport: {
      type: Boolean,
      default: true
    },

    teleportTo: {
      type: String,
      default: '#app'
    }
  },
  emits: ['changeColor', 'close', 'close:popper'],
  setup(props, { emit }) {
    const { disableTeleport, isVisible } = toRefs(props)
    function hexToRgbA(hex) {
      var c
      if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
        c = hex.substring(1).split('')
        if (c.length == 3) {
          c = [c[0], c[0], c[1], c[1], c[2], c[2]]
        }
        c = '0x' + c.join('')
        return 'rgba(' + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') + ',1)'
      }
      throw new Error('Bad Hex')
    }

    const { hasBrandKit, brandKit, user } = yearlyStore()
    const brandKitColors = ref([])
    const colorPickerContainer = ref(null)

    if (
      user.value &&
      user.value.isPlanPaid &&
      hasBrandKit.value &&
      brandKit.value?.colors?.length
    ) {
      brandKitColors.value = brandKit.value.colors
        .map((color) => {
          return color.palette.map((c) => hexToRgbA(c))
        })
        .flat()
    }
    onMounted(() => {})
    watch(isVisible, (currentVal, previousVal) => {
      if (currentVal && !disableTeleport.value) {
        window.addEventListener('click', closeListener)
      }
    })
    function closeListener(event) {
      if (!colorPickerContainer.value?.contains(event.target)) {
        emit('close')

        window.removeEventListener('click', closeListener)
      }
    }
    onBeforeUnmount(() => {
      window.removeEventListener('click', closeListener)
    })

    return {
      brandKitColors,
      colorPickerContainer
    }
  }
}
</script>

<style lang="scss" scoped>
:deep(.hu-color-picker) {
  > div > ul {
    display: flex;
    flex-wrap: wrap;
  }
}
</style>

<template>
  <BubbleMenu class="bubble-menu" :editor="editor" v-if="editor && isEnabled" id="main">
    <button
      class="button"
      @click="editor.chain().focus().toggleBold().run()"
      :class="{ 'is-active': editor.isActive('bold') }"
    >
      <span class="icon is-small">
        <i class="fas fa-bold"></i>
      </span>
    </button>
    <button
      class="button"
      @click="editor.chain().focus().toggleItalic().run()"
      :class="{ 'is-active': editor.isActive('italic') }"
    >
      <span class="icon is-small">
        <i class="fas fa-italic"></i>
      </span>
    </button>

    <button
      class="button"
      @click="toggleHeader(1)"
      :class="{ 'is-active': editor.isActive('heading', { level: 1 }) }"
    >
      <span class="title is-4">H1</span>
    </button>
    <button
      class="button"
      @click="toggleHeader(2)"
      :class="{ 'is-active': editor.isActive('heading', { level: 2 }) }"
    >
      <span class="title is-5">H2</span>
    </button>
    <button
      class="button"
      @click="toggleHeader(3)"
      :class="{ 'is-active': editor.isActive('heading', { level: 3 }) }"
    >
      <span class="title is-6">H3</span>
    </button>
    <button
      class="button"
      @click="toggleHeader(4)"
      :class="{ 'is-active': editor.isActive('heading', { level: 4 }) }"
    >
      <span class="title is-6">H4</span>
    </button>
    <button
      class="button"
      @click="toggleHeader(5)"
      :class="{ 'is-active': editor.isActive('heading', { level: 5 }) }"
    >
      <span class="title is-6">H5</span>
    </button>
    <button
      class="button"
      @click="toggleHeader(6)"
      :class="{ 'is-active': editor.isActive('heading', { level: 6 }) }"
    >
      <span class="title is-6">H6</span>
    </button>
    <button
      class="button"
      @click="editor.chain().focus().toggleUnderline().run()"
      :class="{ 'is-active': editor.isActive('underline') }"
    >
      <span class="icon is-small">
        <i class="fas fa-underline"></i>
      </span>
    </button>

    <div class="button" @click="onShowContributionLinkModal">
      <span class="icon is-small">
        <i class="fas fa-link"></i>
      </span>
    </div>
    <div class="button" @click.stop.prevent="showColorPicker = !showColorPicker">
      <span class="icon is-small">
        <i class="fas fa-palette"></i>
      </span>
    </div>
    <ColorPickerVue
      :disableTeleport="false"
      :teleportTo="`#default-menu-${parentMenuId}`"
      :isVisible="showColorPicker"
      @changeColor="updateFontColor"
      @close="onClosePicker"
    />

    <input
      style="max-width: 80px"
      ref="fontSizeField"
      type="number"
      class="input"
      :value="currentSelectedFontSize"
      v-if="editor.isActive('paragraph')"
      @input="onUpdateFontSize($event.target.value)"
    />

    <button
      class="button"
      @click="editor.chain().focus().setTextAlign('left').run()"
      :class="{ 'is-active': editor.isActive({ textAlign: 'left' }) }"
    >
      <span class="icon is-small">
        <i class="fas fa-align-left"></i>
      </span>
    </button>

    <button
      class="button"
      @click="editor.chain().focus().setTextAlign('center').run()"
      :class="{ 'is-active': editor.isActive({ textAlign: 'center' }) }"
    >
      <span class="icon is-small">
        <i class="fas fa-align-center"></i>
      </span>
    </button>
    <button
      class="button"
      @click="editor.chain().focus().setTextAlign('right').run()"
      :class="{ 'is-active': editor.isActive({ textAlign: 'right' }) }"
    >
      <span class="icon is-small">
        <i class="fas fa-align-right"></i>
      </span>
    </button>
    <button
      v-tooltip:top.tooltip="'Remove table'"
      class="button"
      @click="editor.commands.deleteNode('table')"
      v-if="editor.isActive('table')"
    >
      <span class="icon">
        <i class="fas fa-trash"></i>
      </span>
    </button>
    <div v-if="editor.isActive('tableCell')">
      <button class="button" @click="editor.chain().focus().addColumnBefore().run()">
        <span class="icon">
          <img :src="require('@/assets/icons/insert-col-left.png')" />
        </span>
      </button>
      <button class="button" @click="editor.chain().focus().addColumnAfter().run()">
        <span class="icon">
          <img :src="require('@/assets/icons/insert-col-right.png')" />
        </span>
      </button>
      <button
        class="button"
        v-if="editor.isActive('table')"
        @click="editor.chain().focus().deleteColumn().run()"
      >
        <span class="icon"> <img :src="require('@/assets/icons/delete-col.png')" /> </span>
      </button>
      <button class="button" @click="editor.chain().focus().mergeCells().run()">
        <span class="icon">
          <img :src="require('@/assets/icons/merge-cells.png')" />
        </span>
      </button>
      <button class="button" @click="editor.chain().focus().splitCell().run()">
        <span class="icon">
          <img :src="require('@/assets/icons/split-cells.png')" />
        </span>
      </button>
      <button class="button" @click="editor.chain().focus().toggleHeaderColumn().run()">
        <span class="icon">
          <img :src="require('@/assets/icons/toggle-header.png')" />
        </span>
      </button>
    </div>
    <div v-if="editor.isActive('tableRow')">
      <button
        class="button"
        v-if="editor.isActive('table')"
        @click="editor.chain().focus().addRowBefore().run()"
      >
        <span class="icon">
          <img :src="require('@/assets/icons/insert-row-above.png')" />
        </span>
      </button>
      <button
        class="button"
        v-if="editor.isActive('table')"
        @click="editor.chain().focus().addRowAfter().run()"
      >
        <span class="icon">
          <img :src="require('@/assets/icons/insert-row-below.png')" />
        </span>
      </button>
      <button
        class="button"
        v-if="editor.isActive('table')"
        @click="editor.chain().focus().deleteRow().run()"
      >
        <span class="icon">
          <img :src="require('@/assets/icons/delete-row.png')" />
        </span>
      </button>

      <button class="button" @click="editor.chain().focus().toggleHeaderRow().run()">
        <span class="icon">
          <img :src="require('@/assets/icons/toggle-header.png')" />
        </span>
      </button>
    </div>

    <div v-tooltip:bottom.tooltip="'Image Settings'" class="button" v-if="editor.isActive('image')">
      <Popper>
        <span class="icon is-small">
          <i class="fas fa-image"></i>
        </span>

        <template #content>
          <div class="content">
            <button
              class="button"
              v-tooltip:bottom.tooltip="'Change'"
              @click="onShowImageUploadModal"
            >
              <span class="icon is-small">
                <i class="fas fa-sync-alt"></i>
              </span>
            </button>
          </div>
          <div class="content">
            <button
              class="button"
              v-tooltip:bottom.tooltip="'Image Meta'"
              @click="onShowImageMetaModal"
            >
              <span class="icon is-small">
                <i class="fas fa-cog"></i>
              </span>
            </button>
          </div>
          <div class="card" style="min-width: 150px">
            <slider
              :modelValue="getImageWidth()"
              color="#FB278D"
              :step="20"
              :min="10"
              :max="800"
              @drag-end="onImageResize($event)"
            />
          </div>
        </template>
      </Popper>
    </div>
  </BubbleMenu>

  <div
    v-if="isEnabled"
    class="is-flex"
    style="position: absolute; left: 50%; right: 50%; z-index: 500"
    :id="`default-menu-${randomizedMenuId}`"
  >
    <!--   {{ editor.isActive('iframe') }} -->
    <button
      class="button"
      v-tooltip:bottom.tooltip="'Chart'"
      v-if="editor?.isActive('donutChart')"
      @click="openChartModal(true)"
    >
      <span class="icon is-small">
        <i class="fas fa-chart-pie"></i>
      </span>
    </button>
    <button
      class="button"
      v-tooltip:bottom.tooltip="'Delete Chart'"
      v-if="editor?.isActive('donutChart')"
      @click="editor.chain().focus().deleteNode('donutChart').run()"
    >
      <span class="icon is-small">
        <i class="fas fa-trash-alt"></i>
      </span>
    </button>
    <button
      class="button"
      v-tooltip:bottom.tooltip="'Delete Embedded Content'"
      v-if="editor?.isActive('twitterEmbed')"
      @click="editor.chain().focus().deleteNode('twitterEmbed').run()"
    >
      <span class="icon is-small">
        <i class="fas fa-trash-alt"></i>
      </span>
    </button>
    <button
      class="button"
      v-tooltip:bottom.tooltip="'undo'"
      @click="editor.chain().focus().undo().run()"
    >
      <span class="icon is-small">
        <i class="fas fa-undo"></i>
      </span>
    </button>
    <button
      class="button"
      v-tooltip:bottom.tooltip="'redo'"
      @click="editor.chain().focus().redo().run()"
    >
      <span class="icon is-small">
        <i class="fas fa-redo"></i>
      </span>
    </button>
    <button
      class="button"
      v-tooltip:bottom.tooltip="'Text'"
      @click="editor.chain().focus().insertContent({ type: 'paragraph' }).focus().run()"
    >
      <span class="icon is-small">
        <i class="fas fa-pen-nib"></i>
      </span>
    </button>
    <button class="button" @click="expandCustomMenu = !expandCustomMenu">
      <span class="icon is-small">
        <i v-if="!expandCustomMenu" class="fas fa-caret-down"></i>
        <i v-else class="fas fa-caret-up"></i>
      </span>
    </button>
  </div>

  <transition name="slide-fade" style="position: absolute; z-index: 500">
    <div class="additional-elements" v-if="editor && isEnabled && expandCustomMenu">
      <!--     <button class="button" v-tooltip:bottom.tooltip="'Chart'" @click="openChartModal(false)">
      <span class="icon is-small">
        <i class="fas fa-chart-pie"></i>
      </span>
    </button> -->

      <!--       <button class="button" v-tooltip:bottom.tooltip="'Image'" @click="onInsertDraw">
        <span class="icon is-small">
          <i class="fas fa-image"></i>
        </span>
      </button> -->
      <button class="button" v-tooltip:bottom.tooltip="'Image'" @click="onShowImageUploadModal">
        <span class="icon is-small">
          <i class="fas fa-image"></i>
        </span>
      </button>
      <button class="button" v-tooltip:bottom.tooltip="'Chart'" @click="openChartModal(false)">
        <span class="icon is-small">
          <i class="fas fa-chart-pie"></i>
        </span>
      </button>
      <button
        class="button"
        v-tooltip:bottom.tooltip="'Embed Facebook content'"
        @click="showEmbedModal('facebook', addEmbeddableContent)"
      >
        <span class="icon is-small">
          <i class="fab fa-facebook"></i>
        </span>
      </button>
      <button
        class="button"
        v-tooltip:bottom.tooltip="'Embed Youtube content'"
        @click="showEmbedModal('youtube', addEmbeddableContent)"
      >
        <span class="icon is-small">
          <i class="fab fa-youtube"></i>
        </span>
      </button>
      <button
        class="button"
        v-tooltip:bottom.tooltip="'Embed Twitter content'"
        @click="showEmbedModal('twitter', addEmbeddableContent)"
      >
        <span class="icon is-small">
          <i class="fab fa-twitter"></i>
        </span>
      </button>
      <button
        class="button"
        v-tooltip:bottom.tooltip="'Embed content'"
        @click="showEmbedModal('other', addEmbeddableContent)"
      >
        <span class="icon is-small">
          <i class="fas fa-code"></i>
        </span>
      </button>

      <div v-tooltip:bottom.tooltip="'Table'" class="button">
        <Popper>
          <span class="icon is-small">
            <i class="fas fa-table"></i>
          </span>

          <template #content>
            <div class="content">
              <button class="button" @click="addNewTable">New</button>
              <button class="button" @click="openTableModal">Upload</button>
            </div>
          </template>
        </Popper>
      </div>

      <!--   </VueDragResize> -->
    </div>
  </transition>

  <editor-content
    id="test-editor-content"
    :editor="editor"
    class="content"
    ref="markupInputContainer"
  />
</template>

<script>
import { v4 as uuidv4 } from 'uuid'
import debounce from 'lodash.debounce'
import { isNumberOrFloat } from '@/services/utils'
import { toRefs, ref, watch, computed, onMounted, onBeforeUnmount, inject, watchEffect } from 'vue'
import { PluginKey } from 'prosemirror-state'
import { EditorContent, Editor, BubbleMenu, generateHTML } from '@tiptap/vue-3'
import { getDownloadURL } from '@firebase/storage'

import slider from 'vue3-slider'

import { generateInitialTableJson } from '@/services/coverSections/models'
import { Heading, Document, Text, Paragraph } from '@/services/TipTapExtensions'

import { urlToBlob } from '@/services/utils'

import yearlyStore from '@/composables/yearlyStore'
import CommonModals from '@/composables/commonModals'
import imageFunctions from '@/composables/imageFunctions'

import ChartOptionsModal from '@/components/charts/ChartOptionsModal.vue'
import TableDataUpload from '@/components/TableDataUpload'
import ColorPickerVue from '@/components/ColorPicker'
import ResizeSlider from '@/components/ResizeSlider'

export default {
  components: {
    EditorContent,
    BubbleMenu,
    ColorPickerVue,
    slider,
    ResizeSlider
  },
  props: {
    isEnabled: {
      type: Boolean,
      default: true
    },
    generatedJson: {
      type: Object,
      default: () => {}
    },
    headingFont: {
      type: String,
      default: 'inter'
    },
    bodyFont: {
      type: String,
      default: 'inter'
    },
    extensions: {
      type: Array,
      default: () => [Document, Paragraph, Heading, Text]
    },
    parentMenuId: {
      type: String,
      default: ''
    }
  },
  emits: ['update-donut-chart', 'update:generatedJson'],

  setup(props, { emit }) {
    const $vfm = inject('$vfm')
    const {
      showImageOptionsModal,
      showImageUploadModal,
      showContributionLinkModal,
      showEmbedModal,
      showImageMetaModal,
      showLoadingModal
    } = CommonModals()
    const { uploadImageAndRetrieveUrl } = imageFunctions()
    const { generatedJson, headingFont, bodyFont, extensions, isEnabled } = toRefs(props)
    const { user, token, isLoggedIn } = yearlyStore()
    const bubbleMenu = ref(null)
    const fontSizeField = ref(null)
    const randomizedMenuId = uuidv4()

    const toHTML = () => {
      let json = generatedJson.value || {}
      try {
        return generateHTML(json, [...extensions.value])
      } catch (e) {
        console.log('Error showing report on editor, this is most likely a test report')
      }
    }
    const currentNodePos = ref(0)
    const showColorPicker = ref(false)
    const showImageResizer = ref(false)

    const colorPickerContainer = ref(null)
    const markupInputContainer = ref(null)

    const editor = ref(
      new Editor({
        content: toHTML(),
        extensions: [...extensions.value],
        onTransaction: (e) => {
          currentNodePos.value = e.transaction.curSelection.$anchor.pos
        },
        onUpdate: () => {
          emit('update:generatedJson', editor.value.getJSON())
        }
      })
    )
    const currentSelectedFontSize = computed(() => {
      try {
        if (!editor.value.state.selection.empty && editor.value.isActive('paragraph')) {
          // note font size marks appear to be set at this level
          const selection =
            editor.value.state.selection.content()?.content?.content[0]?.content?.content

          const fontSize = selection[0]?.marks
            ?.filter(
              (mark) =>
                Object.keys(mark.attrs).includes('fontSize') &&
                !Number.isNaN(Number.parseInt(mark.attrs.fontSize))
            )
            .map((mark) => Number.parseInt(mark.attrs.fontSize))

          if (fontSize?.length) {
            return Math.max(fontSize)
          }
        }
        return 16
      } catch (e) {
        console.log(e)
        return 16
      }
      //editor.value.isActive('paragraph') ? editor.value.selection.getM : ''
    })

    const expandCustomMenu = ref(false)

    const extensionNames = computed(() => {
      return extensions.value.map((ext) => ext.name)
    })
    const isTextContent = computed(() => {
      return editor.value.isActive('heading') || editor.value.isActive('paragraph')
    })
    const isResizeable = computed(() => {
      return (
        editor.value.isActive('image') ||
        editor.value.isActive('iframe') ||
        editor.value.isActive('TwitterEmbed')
      )
    })

    function updateFontColor(color) {
      if (color) {
        const { r, g, b, a } = { ...color.rgba }
        editor.value.commands.setColor(`rgba(${r},${g},${b},${a})`)
        editor.value.commands.setUnderlineColor({ color: `rgba(${r},${g},${b},${a})` })
      }
    }
    async function onShowContributionLinkModal() {
      let link = editor.value.isActive('link') ? editor.value.getAttributes('link') : null
      await showContributionLinkModal(link?.href || null, {
        'contribution-link': async (e) => {
          if (editor.value.isActive('link')) {
            editor.value.commands.updateAttributes('link', { href: e })
          } else {
            editor.value.commands.setLink({ href: e, target: '_blank' })
          }

          await $vfm.hideAll()
        }
      })
    }
    const onUpdateFontSize = debounce(updateFontSize, 500)
    function updateFontSize(size) {
      let toSize = size
      if (!isNumberOrFloat(size)) {
        toSize = 16
      }
      editor.value.commands.setFontSize(`${toSize}px`)
    }
    function openTableModal() {
      $vfm.show({
        component: TableDataUpload,
        bind: {
          name: 'CSVModal',
          'lock-scroll': false
        },
        on: {
          'table-data-ready': (e) => {
            editor.value.commands.insertContent(generateInitialTableJson(e))
            $vfm.hideAll()
          }
        }
      })
    }
    function addNewTable() {
      editor.value.commands.insertContent(generateInitialTableJson())
      //editor.commands.insertTable({ rows: 3, cols: 3, withHeaderRow: true })
    }
    function toggleHeader(level) {
      if (editor.value.isActive('heading')) {
        let textAlign = 'left'
        if (editor.value.isActive({ textAlign: 'center' })) {
          textAlign = 'center'
        } else if (editor.value.isActive({ textAlign: 'right' })) {
          textAlign = 'right'
        } else if (editor.value.isActive({ textAlign: 'left' })) {
          textAlign = 'left'
        }
        editor.value.chain().focus().toggleHeading({ level: level, textAlign: textAlign }).run()
        return
      }
      editor.value.chain().focus().toggleHeading({ level: level }).run()
      return
    }
    async function onShowImageMetaModal(event) {
      const { width, title, src, alt } = editor.value.view.state?.selection?.node?.attrs

      await showImageMetaModal(src, alt, title, {
        'set-image-meta': async (e) => {
          editor.value.commands.updateAttributes('image', { alt: e.imageAlt, title: e.imageTitle })

          await $vfm.hide('ImageMetaModal')
        }
      })
    }
    function getImageWidth() {
      const width = editor.value ? editor.value?.view?.state?.selection?.node?.attrs?.width : 50

      return parseInt(width)
    }
    function onImageResize(val) {
      editor.value.commands.updateAttributes('image', { width: `${val}px` })
    }

    function addImage(src = null, opts = {}) {
      let { width, alt, title } = opts

      if (!width) {
        width = '500px'
      }
      editor.value
        .chain()
        .focus()
        .setImage({
          src: src,
          width,
          alt: alt,
          title: title
        })
        .run()
    }
    function onInsertDraw() {
      editor.value
        .chain()
        .focus()
        .insertContent('<div data-type="paper" class="paper">Test</div>')
        .run()
    }
    function addChart(chartLabelsAndData) {
      editor.value.chain().focus().setChart(chartLabelsAndData).run()
    }
    function addEmbeddableContent(e) {
      const { type } = e
      switch (type) {
        case 'youtube':
          if (e.embedType == 'link') {
            editor.value.chain().focus().setYoutubeIframe(e).run()
          } else {
            editor.value.chain().focus().setIframeFromEmbedContent(e).run()
          }
          break
        case 'twitter':
          //let scale = ''
          if (e.embedType == 'link') {
            editor.value.chain().focus().setTwitterEmbedContentOrUrl(e).run()
          } else {
            editor.value.chain().focus().setTwitterEmbedContentOrUrl(e).run()
          }
          break
        case 'facebook':
          if (e.embedType == 'link') {
            editor.value.chain().focus().setFbIframe(e).run()
          } else {
            editor.value.chain().focus().setFbIframeFromEmbedCode(e).run()
          }
          break
        case 'other':
          if (e.embedType == 'link') {
            console.log(e)
            editor.value.chain().focus().setIframeFromUrl(e)
          } else {
            editor.value.chain().focus().setIframeFromEmbedContent(e).run()
          }
          break
      }
    }

    function updateData() {
      emit('update-donut-chart', editor.value.getAttributes('donutChart'))
    }

    function onFileLoaded(val) {
      if (val) {
        let imgSrc = URL.createObjectURL(val)
        editor.value.chain().focus().setImage({ src: imgSrc }).run()
      }
    }
    function openChartModal(
      isUpdate = false,
      { chartLabels, chartDatasets, title, valuePrefix, valueSuffix, chartType } = {},
      event = null
    ) {
      if (isUpdate) {
        chartLabels = JSON.parse(editor.value.getAttributes('donutChart').chartLabels)
        chartDatasets = JSON.parse(editor.value.getAttributes('donutChart').chartDatasets)
        title = editor.value.getAttributes('donutChart').title
        valuePrefix = editor.value.getAttributes('donutChart').valuePrefix
        valueSuffix = editor.value.getAttributes('donutChart').valueSuffix
        chartType = editor.value.getAttributes('donutChart').chartType
      }
      $vfm.show({
        component: ChartOptionsModal,
        bind: {
          'lock-scroll': false,
          chartLabels,
          chartDatasets,
          title,
          valuePrefix,
          valueSuffix,
          isUpdate,
          chartType
        },
        on: {
          closed: (e, val) => {},

          'new-chart-data': (e) => {
            addChart(e)
            $vfm.hideAll()
          },
          'update-donut-chart': (e) => {
            editor.value.commands.updateChart(e)
            $vfm.hideAll()
          }
        }
      })
    }
    async function onShowImageUploadModal() {
      await showImageUploadModal({
        'image-ready': async (e) => {
          if (e) {
            try {
              await showLoadingModal('Loading', 'Preparing image for upload')

              // update the image name to be unique by appending the current epoch time
              // a file's name field is read-only, so we need to duplicate the file with new name
              const newFileName = e.name + `_` + new Date().getTime()
              const newFile = new File([e], newFileName)

              const url = await uploadImageAndRetrieveUrl(newFile, user.value.firebaseId)

              addImage(url, {})
            } catch (e) {
              console.log(e)
            } finally {
              await $vfm.hideAll()
            }
          }
        },
        'image-from-unsplash': async (e) => {
          if (e) {
            try {
              await showLoadingModal('Loading', 'Preparing image for upload')
              e.name = `unspash-image-${Date.now()}${e.type.replace('image/', '.')}`
              const url = await uploadImageAndRetrieveUrl(e, user.value.firebaseId)
              addImage(url, {})
            } catch (e) {
              console.log(e)
            } finally {
              await $vfm.hide('LoadingModal')
            }
          }

          $vfm.hide()
        },
        'brand-kit-image-ready': async (e) => {
          if (e) {
            console.log(e)
            try {
              await showLoadingModal('Loading', 'Preparing image for upload')

              const blob = await urlToBlob(e)
              blob.name = `brandkit-image-${Date.now()}${blob.type.replace('image/', '.')}`
              const url = await uploadImageAndRetrieveUrl(blob, user.value.firebaseId)

              addImage(url, {})
            } catch (e) {
              console.log(e)
            } finally {
              await $vfm.hide('LoadingModal')
            }
          }

          $vfm.hide()
        }
      })
    }

    watch(headingFont, (val) => {
      editor.value.commands.selectAll()
      if (val) {
        editor.value.commands.updateAttributes('heading', { fontFamily: val })
      }
    })
    watch(bodyFont, (val) => {
      editor.value.commands.selectAll()
      editor.value.commands.updateAttributes('paragraph', { fontFamily: val })
    })
    watch(
      isEnabled,
      (val) => {
        if (editor.value) {
          editor.value.options.editable = val
        }
      },
      { immediate: true }
    )
    onMounted(() => {
      /*    editor.value.commands.selectAll()
      editor.value.commands.updateAttributes('heading', { fontFamily: headingFont.value })
      editor.value.commands.updateAttributes('paragraph', { fontFamily: bodyFont.value }) */
    })
    onBeforeUnmount(() => {
      editor.value?.destroy()
    })
    function test() {
      console.log(editor.value)
      console.log()
    }
    function onClosePicker() {
      showColorPicker.value = false
    }
    function onCloseImageResizer() {
      showImageResizer.value = false
    }
    return {
      editor,
      extensionNames,
      onFileLoaded,
      updateData,
      addImage,
      addChart,
      addEmbeddableContent,
      onCloseImageResizer,
      openChartModal,
      showEmbedModal,
      openTableModal,
      addNewTable,
      isTextContent,
      updateFontColor,
      test,
      expandCustomMenu,
      currentNodePos,
      toggleHeader,
      bubbleMenu,
      showContributionLinkModal,
      showColorPicker,
      randomizedMenuId,
      colorPickerContainer,
      onClosePicker,
      markupInputContainer,
      isResizeable,
      showImageOptionsModal,
      showImageUploadModal,
      user,
      onShowImageMetaModal,
      getImageWidth,
      onImageResize,
      showImageResizer,
      onShowImageUploadModal,
      onInsertDraw,
      fontSizeField,
      onUpdateFontSize,
      onShowContributionLinkModal,

      currentSelectedFontSize
    }
  }
}
</script>
<style lang="scss" scoped>
@import '@/assets/styles/mixins';
.additional-elements {
  left: 50px;
}
:deep(.ProseMirror) {
  /*   @include fluid-property('padding-top', 4, 4, rem);
  @include fluid-property('padding-bottom', 5, 2, rem);
  @include fluid-property('padding-right', 4, 4, rem);
  @include fluid-property('padding-left', 5, 2, rem); */

  height: 100%;
  overflow-y: scroll;
  ul {
    list-style-type: disc !important;
    display: block;
  }
  ol {
    list-style-type: lower-alpha;
    display: list-item;
    list-style-position: outside;
  }
  h1 {
    @include fluid-property('font-size', 5, 5, em);
  }
  h2 {
    @include fluid-property('font-size', 3, 3, em);
  }
  h3 {
    @include fluid-property('font-size', 2.5, 2.5, em);
  }
  h4 {
    @include fluid-property('font-size', 1.5, 1.5, em);
  }
  h5 {
  }
  h6 {
  }
  p {
    @include fluid-property('font-size', 1, 1, em);
  }
  .has-focus {
    outline-style: dashed;
    outline-color: lightgray;
    outline-width: thin;
  }

  table {
    border-collapse: collapse;
    table-layout: fixed;
    width: 100%;
    margin: 0;
    overflow: hidden;

    td,
    th {
      min-width: 1em;

      padding: 3px 5px;
      vertical-align: top;
      box-sizing: border-box;
      position: relative;

      > * {
        margin-bottom: 0;
      }
    }

    th {
    }

    .selectedCell:after {
      z-index: 2;
      position: absolute;
      content: '';
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background: rgba(200, 200, 255, 0.4);
      pointer-events: none;
    }

    .column-resize-handle {
      position: absolute;
      right: -2px;
      top: 0;
      bottom: -2px;
      width: 4px;
      background-color: #adf;
      pointer-events: none;
      cursor: col-resize;
      &:hover {
        cursor: col-resize;
      }
    }
  }
}

.tableWrapper {
  padding: 1rem 1rem;
  overflow-x: auto;
}

.resize-cursor {
  cursor: ew-resize;
  cursor: col-resize;
}
.slide-fade-enter-active {
  transition: all 0.5s ease-out;
  position: fixed;
}

.slide-fade-leave-active {
  transition: all 0.5s cubic-bezier(1, 0.5, 0.8, 1);
  position: fixed;
}

.slide-fade-leave-to {
  transform: translateX(-500px);
  opacity: 0;
  position: fixed;
}
.slide-fade-enter-from {
  opacity: 0;
  transform: translateX(-500px);
}
#test-editor-content {
  height: 100%;
  padding: 1.4rem 4rem;
  scrollbar-width: thin;
  > :deep(.ProseMirror) {
    &:focus-visible {
      outline-style: dashed;
      outline-color: lightgray;
    }
  }
}
.is-modified-underlined {
  text-decoration: underline;
  -webkit-text-decoration-color: red; /* Safari */
  text-decoration-color: red;
}
</style>

<template>
  <div>
    {{ (image, 'image') }}
    <p>
      {{
        selectedBackgroundSize
          ? imageSizeOptions.find((opt) => opt.backgroundSize == selectedBackgroundSize).description
          : 'Select an option'
      }}
    </p>
  </div>
  <div class="columns is-multiline" style="margin-top: 1rem">
    <div
      :key="i"
      class="column is-one-quarter-desktop is-half-tablet is-clickable"
      v-for="(opt, i) in imageSizeOptions"
      @click="selectedBackgroundSize = opt.backgroundSize"
    >
      <div class="card is-shadowless">
        <div
          style="background-color: black"
          :style="`background-image:url('${image}');background-size:${
            opt.backgroundSize == 'stretch' ? '100% 100%' : opt.backgroundSize
          };
                    background-position:center;
                     background-repeat:no-repeat; width:100px; height:50px;
                     ${
                       imageSizeOptions.findIndex(
                         (opt) => opt.backgroundSize == selectedBackgroundSize
                       ) == i
                         ? 'outline-style: solid;outline-color:blue;'
                         : ''
                     }
                     `"
        ></div>
        <footer class="card-footer">
          <p>
            {{
              opt.backgroundSize.substring(0, 1).toUpperCase() +
              opt.backgroundSize.substring(1, opt.backgroundSize.length)
            }}
          </p>
        </footer>
      </div>
    </div>
  </div>
  <div class="form">
    <div class="field">
      <label class="label">Image Alt Text</label>
      <div class="control">
        <input
          class="input"
          :class="{ 'is-danger': !imageOptForm.imageAlt.isValid }"
          type="email"
          placeholder="Enter Value"
          v-model="imageOptForm.imageAlt.value"
        />
      </div>
      <p class="help is-primary">This is useful for Accessibility screens</p>
    </div>
    <div class="field">
      <label class="label">Image Title</label>
      <div class="control">
        <input
          class="input"
          :class="{ 'is-danger': !imageOptForm.imageTitle.isValid }"
          type="email"
          placeholder="Enter Value"
          v-model="imageOptForm.imageTitle.value"
        />
      </div>
      <p class="help is-primary">This title will be added to the Accessibility reader</p>
    </div>
  </div>

  <div>
    <button @click="onSaveAndUse" class="is-primary is-fullwidth button">Select</button>
    <button @click="close" class="is-fullwidth button">Cancel</button>
  </div>
</template>

<script>
import Form, { FormField } from '@thinknimble/tn-forms'
class ImageOptForm extends ImageForm {
  static imageSize = new FormField({ value: 'contain' })
  static width = new FormField()
  static height = new FormField()
  static wUnit = new FormField()
  static hUnit = new FormField()
}
const imageSizeOptions = [
  {
    backgroundSize: 'cover',
    description:
      'The image will be resized to fit in the container. If the aspect ratios are not the same, then the image will be masked to fit'
  },
  {
    backgroundSize: 'contain',
    description:
      'The image will be resized to fit in the container. If the aspect ratios are off, then the image will be letterboxed'
  },
  {
    backgroundSize: 'auto',
    description:
      'The image will stay at its default size, the default size may sometimes result in a blurry image (if it’s too small).'
  },
  {
    backgroundSize: 'stretch',
    description: 'The image will be streched to fit 100% of the container and 100%'
  }
]
const imageSizeUnits = ['%', 'px']

import { onMounted, inject, ref, toRefs, watchEffect } from 'vue'
import { ImageForm } from '../../services/coverSections/forms'
export default {
  name: 'ImageListModal',
  inheritAttrs: false,
  components: {},
  props: {
    currentImage: {}
  },
  emits: ['set-bg'],
  setup(props, { emit }) {
    const $vfm = inject('$vfm')
    const { currentImage } = toRefs(props)
    let image = ref(null)
    watchEffect(() => {
      image = currentImage.value
    })

    function onSaveAndUse() {
      emit('set-bg', {
        ...imageOptForm.value.value,
        url: image,
        backgroundSize: selectedBackgroundSize.value
      })
    }
    const imageOptForm = ref(new ImageOptForm())
    const selectedBackgroundSize = ref('contain')
    /*     function onOpened(event) {
      image.value = event.ref.params.value.image
    } */

    return {
      image,
      imageSizeOptions,
      imageSizeUnits,
      imageOptForm,

      selectedBackgroundSize,

      onSaveAndUse
    }
  }
}
</script>

<style lang="scss" scoped></style>

<template>
  <span class="tag is-medium">
    <span class="icon is-medium">
      <i :class="institutionLogo"></i>
    </span>

    {{ last4 }}</span
  >
</template>

<script>
const CARD_IMAGES = {
  visa: 'fab fa-cc-visa',
  mastercard: 'fab fa-cc-mastercard',
  amex: 'fab fa-cc-amex',
  discover: 'fab fa-cc-discover',
  other: 'fas fa-credit-card'
}
import { computed, toRefs } from 'vue'
export default {
  props: {
    institution: {
      type: String,
      default: null
    },
    last4: {
      type: [String, Number],
      default: null
    }
  },
  setup(props) {
    const { institution } = toRefs(props)
    const institutionLogo = computed(() => {
      return institution?.value?.length && CARD_IMAGES[institution?.value?.toLowerCase()]
        ? CARD_IMAGES[institution?.value?.toLowerCase()]
        : CARD_IMAGES['other']
    })
    return {
      institutionLogo
    }
  }
}
</script>

<style lang="scss" scoped></style>

<template>
  <template v-if="buttonFor == 'SUBSCRIBE'">
    <button @click="emitUpgrade" class="button is-primary">Upgrade Now</button>
  </template>
  <template v-else>
    <button class="button is-danger" @click="emitUnSubscribe">Unsubscribe</button>
  </template>
</template>

<script>
import CommonModals from '@/composables/commonModals'
import { toRefs } from 'vue'

export default {
  props: {
    buttonFor: {
      type: String,
      default: 'SUBSCRIBE'
    }
  },
  emits: ['upgrade-now', 'unsubscribe'],
  setup(props, { emit }) {
    const { $vfm, showSubscriptionModal } = CommonModals()
    function emitUpgrade() {
      showSubscriptionModal()
    }
    function emitUnSubscribe() {
      emit('unsubscribe')
    }
    return {
      emitUpgrade,
      emitUnSubscribe
    }
  }
}
</script>

<style lang="scss" scoped></style>

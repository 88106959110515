import Client from '../AxiosClient'

import Model, { fields, ModelAPI, ApiFilter } from '@thinknimble/tn-models'

import BrandKitAPI from './api'

export class Font extends Model {
  static id = new fields.IdField()
  static style = new fields.CharField()
  static url = new fields.CharField()
  static isCustom = new fields.BooleanField({ defaultVal: true })
  static name = new fields.CharField()

  get generatedName() {
    if (this.isCustom && !this.name?.length) {
      let fontName = this.url.split('/')[this.url.split('/').length - 1]

      let fontNameWithoutExt = decodeURIComponent(fontName.replace(/\.[^.]*$/, ''))
      return fontNameWithoutExt
    }
    return this.name
  }

  loadFont() {
    if (this.isCustom) {
      let localUrl = this.url
      if (process.env.VUE_APP_IS_LOCAL) {
        // THIS IS A HACK - rails returns a secure url that points to the callee base
        // and then reroutes it fonts/images cannot be loaded when in local active storage(not s3)
        localUrl = this.url.replace('http://localhost:8080', process.env.VUE_APP_DEV_SERVER_BACKEND)
      }
      let fontFace = new FontFace(this.generatedName, `url(${localUrl})`)
      fontFace
        .load()
        .then((x) => {
          document.fonts.add(x)
          return x
        })
        .catch((e) => {
          console.log(e)
          return null
        })
    }
  }
  get toDict() {
    let data = {}
    Object.entries(this).forEach(([key, val]) => {
      if (key != '_fields') {
        data[key] = val
      }
      if (
        data[key] instanceof Model ||
        (Array.isArray(data[key]) && data[key].length && data[key][0] instanceof Model)
      ) {
        if (Array.isArray(data[key])) {
          data[key] = data[key].map((value) => {
            return value.toDict
          })
        } else data[key] = data[key].toDict
      }
    })
    return data
  }
  /*   fromAPI() {
    //HACK rails returns the blob instead of the aws link, it uses the origin as the base url for some reason
  } */
}
export class ColorPalette extends Model {
  static id = new fields.CharField()
  static palette = new fields.ArrayField({ defaultVal: [], type: new fields.CharField() })
  static name = new fields.CharField()
  get toDict() {
    let data = {}
    Object.entries(this).forEach(([key, val]) => {
      if (key != '_fields') {
        data[key] = val
      }
      if (
        data[key] instanceof Model ||
        (Array.isArray(data[key]) && data[key].length && data[key][0] instanceof Model)
      ) {
        if (Array.isArray(data[key])) {
          data[key] = data[key].map((value) => {
            return value.toDict
          })
        } else data[key] = data[key].toDict
      }
    })
    return data
  }
}
export class Images extends Model {
  static id = new fields.CharField({ readOnly: true })
  static url = new fields.CharField({ readOnly: true })
  get toDict() {
    let data = {}
    Object.entries(this).forEach(([key, val]) => {
      if (key != '_fields') {
        data[key] = val
      }
      if (
        data[key] instanceof Model ||
        (Array.isArray(data[key]) && data[key].length && data[key][0] instanceof Model)
      ) {
        if (Array.isArray(data[key])) {
          data[key] = data[key].map((value) => {
            return value.toDict
          })
        } else data[key] = data[key].toDict
      }
    })
    return data
  }
}

export default class BrandKit extends Model {
  static api = BrandKitAPI.create(BrandKit)
  static FONT_CLS = Font
  static COLOR_CLS = ColorPalette
  static IMAGE_CLS = Images
  static id = new fields.CharField()
  static colors = new fields.ModelField({ defaultVal: [], many: true, ModelClass: ColorPalette })
  static images = new fields.ModelField({ defaultVal: [], many: true, ModelClass: Images })
  static fonts = new fields.ModelField({ defaultVal: [], many: true, ModelClass: Font })

  get toDict() {
    let data = {}
    Object.entries(this).forEach(([key, val]) => {
      if (key != '_fields') {
        data[key] = val
      }
      if (
        data[key] instanceof Model ||
        (Array.isArray(data[key]) && data[key].length && data[key][0] instanceof Model)
      ) {
        if (Array.isArray(data[key])) {
          data[key] = data[key].map((value) => {
            return value.toDict
          })
        } else data[key] = data[key].toDict
      }
    })
    return data
  }
}

<template>
  <div class="is-flex is-justify-content-center">
    <button class="button is-primary mx-4" @click="onForceLogout">Force Logout</button>
    <button class="button is-danger mx-4" @click="onCancelLogin">Cancel</button>
  </div>
</template>

<script>
export default {
  emits: ['cancel-login', 'force-logout'],
  setup(_, { emit }) {
    function onForceLogout() {
      emit('force-logout')
    }
    function onCancelLogin() {
      emit('cancel-login')
    }

    return { onForceLogout, onCancelLogin }
  }
}
</script>

<style lang="scss" scoped></style>

<template>
  <div class="card">
    <div class="card-header">
      <p class="card-header-title">{{ plan.label }}</p>
    </div>
    <div class="card-content">
      <template v-if="plan.key == FREE">
        <h4 class="subtitle is-4">No credit card required!</h4>
      </template>
      <template v-else-if="plan.key == currentPlan?.category">
        <ul>
          <li>
            <h2 class="title">${{ Math.floor(currentPlan?.amount) }}</h2>
          </li>
          <li v-if="currentPlan?.category == plan.key">
            <InlineCreditCardView
              :last4="currentPaymentMethod?.last4"
              :institution="currentPaymentMethod?.institution"
            />
            <span class="is-clickable is-underlined" @click="onEmitShowCCModal">change card</span>
          </li>
          <li v-if="currentPlan?.category == plan.key">
            <p class="help">
              Billed {{ currentPlan?.frequency }}, next payment on
              {{ formatDateShort(currentPlan?.nextPaymentAt) }}
            </p>

            <span
              v-if="currentPlan?.renew"
              @click="onEmitUnsubscribe"
              class="is-clickable is-underlined"
              >Unsubscribe</span
            >
            <span v-else class="help is-danger"
              >Your plan will be canceled at the end of your subscription</span
            >
          </li>
        </ul>
        <div class="block has-text-centered">
          <small v-if="newPlan.formVal.frequency == 'monthly'" class="help"
            ><i> *Billed monthly for 12 months </i></small
          >
        </div>
      </template>
      <template v-else>
        <ul>
          <li>
            <h2 class="title">
              ${{ getDynamicPlanPricing(plan.key, newPlan.frequency.value, newPlan.tier.value) }}
            </h2>
          </li>
          <li>
            <h4 class="subtitle is-4">Billed {{ newPlan.formVal.frequency }}</h4>
          </li>
        </ul>
        <div class="block has-text-centered">
          <small v-if="newPlan.formVal.frequency == 'monthly'" class="help"
            ><i> *Pay monthly for 12 months </i></small
          >
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { formatDateShort, formatNumberAsUSD } from '@thinknimble/tn-utils'

import subscriptionFunctions from '@/composables/subscriptionFunctions'
import InlineCreditCardView from '@/components/InlineCreditCardView'

export default {
  components: { InlineCreditCardView },
  props: {
    plan: {},
    currentPlan: {},
    newPlan: {},
    currentPaymentMethod: {}
  },
  emits: ['showCreditCardModal', 'unsubscribe'],
  setup(_, { emit }) {
    /*   const { $vfm, showLoadingModal, showCreditCardModal, showHelpModal } = commonModals()
    const { user, setUser, token, refreshUser, hasLegacy } = yearlyStore() */
    const { FREE, getDynamicPlanPricing } = subscriptionFunctions()

    function onEmitShowCCModal() {
      emit('showCreditCardModal')
    }
    function onEmitUnsubscribe() {
      emit('unsubscibe')
    }
    return {
      getDynamicPlanPricing,
      FREE,
      formatDateShort,
      onEmitShowCCModal,
      onEmitUnsubscribe
    }
  }
}
</script>

<style lang="scss" scoped>
.card-header {
  background-color: $salmon;
}
</style>

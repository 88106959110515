<template>
  <div class="box">
    <div class="content">
      <form class="form" @submit.prevent="onSubmit">
        <FormFieldVue type="text" v-model="link.value" @blur="link.validate()" />
        <p class="help">To add an email address please use the format "mailto:test@test.com"</p>
        <p :key="i" v-for="(error, i) in link.errors">
          {{ error.message }}
        </p>

        <button :disabled="!link.isValid" class="button is-fullwidth is-primary">Submit</button>
      </form>
    </div>
  </div>
</template>

<script>
import { onMounted, inject, ref, watchEffect, toRefs } from 'vue'
import Form, {
  FormField,
  UrlValidator,
  RequiredValidator,
  PatternValidator
} from '@thinknimble/tn-forms'
import FormFieldVue from '@/components/FormField'

export default {
  name: 'ContributionLinkModal',
  inheritAttrs: false,
  components: { FormFieldVue },
  props: {
    previousLink: {
      default: '',
      type: String
    }
  },

  emits: ['contribution-link'],
  setup(props, { emit }) {
    const pattern =
      /^((ftp|http|https):\/\/)|(mailto:)(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-/]))?$/
    const { previousLink } = toRefs(props)
    const link = ref(
      new FormField({
        validators: [new RequiredValidator(), new PatternValidator({ pattern: pattern })]
      })
    )

    function onSubmit() {
      emit('contribution-link', link.value.value)
    }

    link.value.value = previousLink.value

    return {
      link,
      onSubmit
    }
  }
}
</script>

<style lang="scss" scoped>
.is-pointer {
  &:hover {
    cursor: pointer;
  }
}
/* Enter and leave animations can use different */
/* durations and timing functions.              */
.slide-fade-enter-active {
  transition: all 0.9s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.9s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
}
</style>

import Form, { FormField } from '@thinknimble/tn-forms'
import { RequiredValidator, NumberValidator } from '@thinknimble/tn-forms/'

export default class SubscriptionForm extends Form {
  static category = new FormField({ validators: [new RequiredValidator()] })

  static amountCents = new FormField()
  static frequency = new FormField({ validators: [new RequiredValidator()], value: 'monthly' })
  static paymentToken = new FormField()

  static code = new FormField()
  static style = new FormField({ value: 'newly', validators: [new RequiredValidator()] })
  static tier = new FormField({ value: 1 })
  static codeAmount = new FormField({ value: 0 })
  static discountType = new FormField()

  get formVal() {
    const frequencyMultiplier = this.frequency.value == 'monthly' ? 1.2 : 1

    let categoryMultiplier = 0
    switch (this.category.value) {
      case 'premium_plus':
        categoryMultiplier = 500_00 * 1.3
        break
      case 'premium':
        categoryMultiplier = 500_00
        break
      default:
        0
    }
    const tierMultipier = (this.tier.value - 1) * categoryMultiplier

    let amount = this.amountCents.value + tierMultipier
    // this was built to take an amount from the ui and carry out the same calculations on the backend
    // only the tiers were not included
    let serverAmount = amount
    if (this.code.value && this.codeAmount.value) {
      if (this.discountType.value == 'fixed') {
        amount = amount - this.codeAmount.value
      } else if (this.discountType.value == 'discount') {
        amount = amount * ((100 - this.codeAmount.value) / 100)
      }
    }
    let displayAmount =
      this.frequency.value == 'monthly'
        ? (amount * frequencyMultiplier) / 12
        : amount * frequencyMultiplier

    return {
      ...this.value,
      amountCents: serverAmount,
      amount_dollars: serverAmount / 100,
      displayAmount: displayAmount,
      promo_code: this.value.code
    }
  }
}

<template>
  <div v-html="content"></div>
</template>

<script>
export default {
  props: {
    content: {
      type: String,
      default: `send me some html <span>HERe<span>`
    }
  },
  setup() {
    return {}
  }
}
</script>

<style lang="scss" scoped></style>

import axios from 'axios'
import store from '@/store'
import CSRF from '@/services/csrf'
import router from '@/router/'
import { firebaseConfig } from '@/services/config'
import { useRouter } from 'vue-router'
import { $vfm } from 'vue-final-modal'

import { addAlert } from '@thinknimble/vue3-alert-alert'

/**
 *   Get the axios API client.
 *   This conveniently sets the `baseURL` and `headers` for the API client,
 *   so that we don't have to do this in every function that needs to call
 *   the API.
 *   This will automaticaly proxy requrests to /api/
 *   if the /api/ route is already included the vue.config.js will overwrite it
 *
 *
 *   @returns {object} - An instance of the axios API client.
 */

async function retryRequest(refreshUrl, refreshData) {
  try {
    const res = await axios.post(refreshUrl, refreshData)
    return res.data
  } catch (e) {
    console.log(e)
    console.log('Cant Refresh token returned ', e)
    throw e
  }
}
class ApiService {
  static session
  static init
  constructor() {
    let base_url = ''

    if (process.env.VUE_APP_IS_LOCAL && process.env.VUE_APP_IS_LOCAL != 'false') {
      base_url = `${window.location.protocol}//${window.location.host}` //`${process.env.VUE_APP_SERVER_BACKEND}`
    } else {
      base_url = process.env.VUE_APP_DEV_SERVER_BACKEND
    }

    // USE THIS FOR OLD YEARLY ONLY
    //let base_url = `${process.env.VUE_APP_DEV_SERVER_BACKEND}`

    console.debug(`API Service for ${base_url}---> ${process.env.VUE_APP_DEV_SERVER_BACKEND}`)

    ApiService.session = axios.create({
      baseURL: base_url,
      headers: {
        ...CSRF.getHeaders()
      }
    })
    ApiService.session.interceptors.request.use(async (config) => {
      const token = store.getters?.token || null

      if (token) {
        config.headers['Authorization'] = `${token}`
      }

      return config
    })
    ApiService.session.interceptors.response.use(
      async (response) => {
        return response
      },
      async (error) => {
        if (error?.response?.status === 401 && store?.getters?.user?.refreshToken) {
          debugger
          // try to refresh token

          const refreshUrl = `https://securetoken.googleapis.com/v1/token?key=${firebaseConfig.apiKey}`

          const refreshData = {
            grant_type: 'refresh_token',
            refresh_token: store.getters?.user?.refreshToken
          }

          try {
            const res = await retryRequest(refreshUrl, refreshData)
            let retryConfig = error.config
            if (retryConfig?.__retry >= 2) {
              return Promise.reject(error)
            }
            retryConfig['__retry'] = retryConfig?.__retry + 1 || 1

            store?.commit('SET_USER_AUTH', {
              ...store.state.auth,
              accessToken: res.access_token,
              refreshToken: res.refresh_token
            })
            axios.defaults.headers.common['Authorization'] =
              'Bearer ' + store?.getters?.user?.accessToken
            axios.headers
            return ApiService.instance(retryConfig)
          } catch (e) {
            const alert = {
              message:
                'Your session has expired we are unable to refresh your token, please login again',
              timeout: 5000
            }
            addAlert(alert)
            window.location.reload()
            return Promise.reject(error)
          }
        } else if (
          error?.response?.status == 422 &&
          error?.response?.data?.error == 'Account not found'
        ) {
          router.push({ name: 'AccountRegistrationPage', query: { showModal: true } })
        }

        return Promise.reject(error)
      }
    )
  }

  static get instance() {
    if (!ApiService.init) {
      new ApiService()
      ApiService.init = true
    }
    return ApiService.session
  }
}

export default ApiService.instance
// extend and use
export { ApiService }

import Model, { fields } from '@thinknimble/tn-models'
import { objectToCamelCase } from '@thinknimble/tn-utils'
import UserAccountAPI from './api'

export default class UserAccount extends Model {
  static api = UserAccountAPI.create(UserAccount)

  static id = new fields.CharField({ readOnly: true })
  static name = new fields.CharField()
  static email = new fields.CharField()
  static currentPlan = new fields.CharField()
  static isPlanPaid = new fields.BooleanField()
  static subscriptionStatus = new fields.CharField()
  static organization = new fields.CharField()
  static username = new fields.CharField()
  static custom = new fields.BooleanField()
  static organizationAddress = new fields.CharField()
  static organizationFacebook = new fields.CharField()
  static organizationInstagram = new fields.CharField()
  static organizationLinkedin = new fields.CharField()
  static organizationPinterest = new fields.CharField()
  static organizationTwitter = new fields.CharField()
  static organizationYoutube = new fields.CharField()

  static organizationLogo = new fields.CharField()
  static access = new fields.CharField()
  static customerID = new fields.CharField()
  static firebaseId = new fields.CharField()
  static reportsLimitReached = new fields.BooleanField()
  static subscribedPlan = new fields.Field({ readOnly: true })
  static organizationSize = new fields.IntegerField({ defaultVal: 1 })
  static isLegacy = new fields.BooleanField({ defaultVal: false, readOnly: true })

  get toDict() {
    let data = {}
    Object.entries(this).forEach(([key, val]) => {
      if (key != '_fields') {
        data[key] = val
      }
    })

    return data
  }

  static fromAPI(json = {}) {
    if (json.subscribed_plan == 'legacy') {
      json.subscribed_plan = 'free'
      json['isLegacy'] = true
    }
    if (process.env.VUE_APP_IS_LOCAL && json.organizationLogo) {
      // THIS IS A HACK - rails returns a secure url that points to the callee base
      // and then reroutes it fonts/images cannot be loaded when in local active storage(not s3)
      json.organizationLogo = json.organizationLogo.replace(
        'http://localhost:8080',
        process.env.VUE_APP_DEV_SERVER_BACKEND
      )
    }

    let data = { ...json }

    return new this(objectToCamelCase(data))
  }
}

import Model, { fields } from '@thinknimble/tn-models'
import { objectToCamelCase, objectToSnakeCase } from '@thinknimble/tn-utils'
import PaymentAPI from './api'

export default class Payment extends Model {
  static api = PaymentAPI.create(Payment)
  static id = new fields.IdField({ readOnly: true })
  static addressZipCode = new fields.CharField()
  static institution = new fields.CharField({ readOnly: true })
  static last4 = new fields.CharField({ readOnly: true })
  static name = new fields.CharField()
  static paymentProcessorCustomerId = new fields.CharField()
  static paymentProcessorSourceId = new fields.CharField()
  static payment_token = new fields.CharField()
}

<template>
  <node-view-wrapper class="embedded-tweet">
    <node-view-content class="content-dom" contenteditable="false" />
    <div :id="`tweet-${domElUuid}`" class="" style=""></div>
    <!-- <div v-html="htmlContent"></div> -->
  </node-view-wrapper>
</template>

<script>
import { NodeViewWrapper, NodeViewContent, nodeViewProps } from '@tiptap/vue-3'
import { computed, onMounted, ref, toRefs, watchEffect } from 'vue'
import TwitterWidgets from 'twitter-widgets'
import { v4 as uuidv4 } from 'uuid'

export default {
  props: {
    nodeViewProps
  },
  name: 'TwitterEmbed',

  components: {
    NodeViewWrapper,
    NodeViewContent
  },
  setup(props) {
    const { decorations, getPos, node } = toRefs(props)
    const domElUuid = uuidv4()

    const htmlContent = ref(JSON.parse(node.value.attrs.embedContent))
    const width = ref(node.value.attrs.width)
    const justification = ref(node.value.attrs.justifyContent)
    const justify = ref(`justify-content:${justification.value}`)
    const scaleBy = ref(1)

    watchEffect(() => {
      const linkRegex = /https:\/\/twitter.com\/([a-zA-Z0-9])*\/status\/([0-9])*/gm
      const match = htmlContent.value.match(linkRegex)

      if (!match) {
        return
      }
      const matchToArray = match[match.length - 1].split('/')
      const id = matchToArray[matchToArray.length - 1]

      TwitterWidgets.load(function (err, twttr) {
        if (err) {
          //do some graceful degradation / fallback
          console.log(err)
          return
        }

        //console.log(twttr.widgets.createTweet(id, document.getElementById('tweet')))

        twttr.widgets.createTweetEmbed(id, document.getElementById(`tweet-${domElUuid}`), {
          width: width,
          align: justification.value
        })
      })
    })
    onMounted(() => {})
    return {
      getPos,
      decorations,
      node,
      htmlContent,
      justify,
      domElUuid,
      scaleBy
    }
  }
}
</script>

<style lang="scss">
.draggable-item {
  .content {
    flex: 1 1 auto;
  }
}
</style>

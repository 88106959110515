import { ref, computed, inject } from 'vue'
import Subscription, { SubscriptionForm } from '@/services/subscriptions/'
import Payment from '@/services/payments/'
import yearlyStore from '@/composables/yearlyStore'

export default function subscriptionFunctions() {
  const { user, setUser, token, refreshUser, hasLegacy } = yearlyStore()
  const $alert = inject('$alert')
  const $vfm = inject('$vfm')
  const FREE_OPTS = [
    'Create a report and pay when you’re ready to publish',
    'Tell engaging stories with text and photos',
    'Video and social media integration',
    'Charts',
    'Use royalty-free stock photos or your own',
    'Donate Links',
    'Custom colors',
    'Hosted with unique URL',
    'Email and chat support'
  ]
  const PRO_OPTS = [
    'Create and publish unlimited reports',
    'Store your photos, logos and color palette for faster design',
    'Upload your licensed font',
    'White lable: Remove the "Created with Yearly" on the bottom of all reports',
    'Embed code: Easily copy and paste code for your report to live on your website',
    'Faster Customer support'
  ]
  const PREMIUM_OPTS = [
    'Create a web-ready PDF version of your digital report at the click of a button',
    'Choose which pieces of your report to include in the printable version',
    'Save for printing or for offline access',
    'Save time and money over recreating your report as a print ready document'
  ]
  const FREE = 'free'
  const PREMIUM = 'premium_plus'
  const PRO = 'premium'

  const TIER_OPTS = [
    { label: 'Nonprofits under $1M/year in revenue', value: 1 },
    { label: 'Nonprofits between $1M-$2M/year in revenue', value: 2 },
    { label: 'Nonprofits between $2M-$5M/year in revenue', value: 3 },
    { label: 'Nonprofits over $5M/year in revenue', value: 4 }
  ]

  class StaticPlan {
    constructor({
      key = null,
      options = [],
      label = null,
      level = 1,
      tier = 1,
      amountCents = 0,
      amount = 0
    } = {}) {
      Object.assign(this, {
        key,
        options,
        amountCents,
        label,
        level,
        tier,
        amount
      })
    }
    get id() {
      return `${this.key}-${this.tier}`
    }
    static create(opts = {}) {
      return new this(opts)
    }

    toDisplayValue(price) {
      if (price != null && price !== 'undefined' && !isNaN(price)) return (price / 100).toFixed(2)
    }
  }

  const PLANS = [
    StaticPlan.create({
      key: FREE,
      options: FREE_OPTS,

      label: 'Try it Free',
      level: 1,
      tier: 1
    }),
    StaticPlan.create({
      key: PRO,
      options: PRO_OPTS,

      label: 'Yearly Pro',
      level: 2,
      tier: 1,
      amountCents: 750_00,
      amount: 750
    }),
    StaticPlan.create({
      key: PREMIUM,
      options: PREMIUM_OPTS,

      label: 'Yearly Premium',
      level: 3,
      tier: 1,
      amountCents: 975_00,
      amount: 975
    })
  ]
  const showPlan = ref(null)
  const currentPlan = ref(null)
  const newPlanSelected = ref(false)
  const currentPaymentMethod = ref(null)
  const isLoading = ref(false)
  const plansCount = PLANS.length

  const newPlan = ref(new SubscriptionForm({ frequency: 'monthly' }))

  function getPlanOpts(options) {
    let ulList = "<p><strong>You'll lose access to these features:</strong></p><ul>"
    options.forEach(
      (op) =>
        (ulList += `<li>
                      <span class="icon is-medium" style="color: green">
                  <i class="fas fa-check"></i> </span
                >${op}
    </li>`)
    )
    ulList += '</ul>'
    return ulList
  }
  function togglePlan(planName) {
    showPlan.value = planName
  }
  function selectNewPlan(planKey) {
    let plan = PLANS.find((p) => planKey == p.key)

    if (planKey == currentPlan?.value?.category || !plan) {
      return
    }

    newPlan.value = new SubscriptionForm({
      ...newPlan.value.value,
      category: plan?.key,
      amountCents: plan?.amountCents
    })
    newPlanSelected.value = true
  }
  const getCurrentPlanAsStaticPlan = computed(() => {
    return PLANS.find((plan) => plan.key == currentPlan?.value?.category)
  })
  const getNewPlanAsStaticPlan = computed(() => {
    return PLANS.find((plan) => plan.key == newPlan?.value.value?.category)
  })
  async function unsubscribeFromPlan(firebaseId) {
    try {
      await Subscription.api.unsubscribe(firebaseId, currentPlan.value.id)
      $alert.alert({
        message: 'Successfully unsubscribed, your subscription will not renew',
        timeout: 3000,
        type: 'success'
      })
    } catch (e) {
      $alert.alert({
        message: 'An Error occured unsubscribing, please refresh the page and try again',
        timeout: 3000,
        type: 'error'
      })
    }
  }
  function getDynamicPlanPricing(planKey, frequency, tier) {
    let plan = PLANS.find((p) => planKey == p.key)

    let categoryMultiplier = 0
    switch (plan.key) {
      case 'premium_plus':
        categoryMultiplier = 500_00 * 1.3
        break
      case 'premium':
        categoryMultiplier = 500_00
        break
      default:
        0
    }
    const tierMultipier = (tier - 1) * categoryMultiplier
    let priceWithTier = plan.amountCents + tierMultipier
    return Math.floor(
      frequency == 'monthly' ? (priceWithTier * 1.2) / 100 / 12 : priceWithTier / 100
    )
  }
  async function updateSubscription(newSelectedPlan) {
    let planUpdated = false
    if (newSelectedPlan.isValid) {
      try {
        isLoading.value = true
        await Subscription.api.create(user.value.firebaseId, {
          ...newSelectedPlan.formVal
        })
        await refreshUser()
        newPlan.value = new SubscriptionForm()
        newPlanSelected.value = false
        //await retrieveAndSetUser()
        await retrieveSubscriptionAndSetForm()

        $alert.alert({
          message: 'Enjoy your new Plan!',
          timeout: 3000,
          type: 'success'
        })
        planUpdated = true
      } catch (e) {
        $alert.alert({
          message:
            'An Error occured updating your subscription, please refresh the page and try again',
          timeout: 3000,
          type: 'error'
        })
        planUpdated = false
      } finally {
        isLoading.value = false
      }
    }
    return planUpdated
  }
  async function retrieveSubscriptionAndSetForm(preSelectedPlan = null) {
    try {
      isLoading.value = true

      const res = await Subscription.api.retrieve(user.value.firebaseId)
      if (res.status == 'active') {
        currentPlan.value = res

        togglePlan(currentPlan.value.category)

        newPlan.value.frequency.value = currentPlan.value.frequency
        newPlan.value.tier.value = currentPlan.value.tier
        newPlan.value.style.value = 'upgrade'
      }
    } catch (e) {
      currentPlan.value = null
    } finally {
      isLoading.value = false
    }
    if (preSelectedPlan) {
      togglePlan(preSelectedPlan.key)
      selectNewPlan(preSelectedPlan.key)
      newPlan.value.tier.value = preSelectedPlan.tier
      newPlan.value.frequency.value = preSelectedPlan.frequency
      newPlan.value.style.value = preSelectedPlan.style || 'upgrade'
    } else if (!currentPlan.value && !preSelectedPlan?.key) {
      togglePlan(FREE)
    }
  }

  return {
    FREE,
    PRO,
    PREMIUM,
    TIER_OPTS,
    StaticPlan,
    PLANS,
    FREE_OPTS,
    PRO_OPTS,
    PREMIUM_OPTS,
    getPlanOpts,
    togglePlan,
    selectNewPlan,
    updateSubscription,
    currentPlan,
    newPlan,
    newPlanSelected,
    getCurrentPlanAsStaticPlan,
    getNewPlanAsStaticPlan,
    unsubscribeFromPlan,
    showPlan,
    getDynamicPlanPricing,
    currentPaymentMethod,
    isLoading,
    plansCount,
    retrieveSubscriptionAndSetForm
  }
}

<template>
  <node-view-wrapper class="donut-chart">
    <h1 style="text-align: center">{{ title }}</h1>
    <DoughnutChart
      ref="chartRef"
      v-if="chartType == 'donut'"
      :chartData="testData"
      :options="options"
    />
    <PieChart ref="chartRef" v-if="chartType == 'pie'" :chartData="testData" :options="options" />
    <node-view-content class="content-dom" />
  </node-view-wrapper>
</template>

<script>
import { NodeViewWrapper, NodeViewContent, nodeViewProps } from '@tiptap/vue-3'
import { computed, ref, toRefs, watchEffect, watch, onMounted } from 'vue'
import cloneDeep from 'lodash.clonedeep'
import { DoughnutChart, PieChart } from 'vue-chart-3'
import { Chart, registerables } from 'chart.js'

Chart.register(...registerables)

export default {
  props: {
    nodeViewProps
  },
  name: 'DonutChart',

  components: {
    NodeViewWrapper,
    NodeViewContent,
    DoughnutChart,
    PieChart
  },
  setup(props) {
    const { decorations, getPos, node, selected, updateAttributes } = toRefs(props)

    const chartLabels = ref(node.value.attrs.chartLabels)
    const chartDatasets = ref(node.value.attrs.chartDatasets)
    const title = ref(node.value.attrs.title)
    const valuePrefix = ref(node.value.attrs.valuePrefix)
    const valueSuffix = ref(node.value.attrs.valueSuffix)
    const chartRef = ref()
    const chartType = ref(node.value.attrs.chartType)

    const testData = computed(() => {
      let obj = {
        labels: chartLabels.value ? JSON.parse(chartLabels.value) : [],
        datasets: chartDatasets.value ? JSON.parse(chartDatasets.value) : []
      }
      return { ...obj }
    })

    const options = computed(() => ({
      scales: {},
      plugins: {
        legend: {
          position: 'top',

          display: true,
          labels: {
            /*      generateLabels: (e) => {
              return e.data.labels.map((label) => {
                return { text: label }
              })
            } */
          }
        },

        title: {
          display: false,
          text: title.value
        },
        tooltip: {
          callbacks: {
            label: function (context) {
              let tooltipContent = `${context.label}: `
              if (valuePrefix.value) {
                tooltipContent = tooltipContent += valuePrefix.value
              }
              tooltipContent += context.formattedValue

              if (valueSuffix.value) {
                tooltipContent = tooltipContent + valueSuffix.value
              }
              return `${tooltipContent}`
            }
          }
        }
      }
    }))

    watch(
      () => cloneDeep(node.value),
      (val) => {
        chartLabels.value = val.attrs.chartLabels
        chartDatasets.value = val.attrs.chartDatasets
        title.value = val.attrs.title
        valuePrefix.value = val.attrs.valuePrefix
        valueSuffix.value = val.valueSuffix
        chartType.value = val.attrs.chartType
      }
    )
    watchEffect(() => {})

    return {
      testData,
      options,
      chartType,
      getPos,
      decorations,
      node,
      chartRef,
      chartLabels,
      chartDatasets,
      title
    }
  }
}
</script>

<style lang="scss">
.draggable-item {
  .content {
    flex: 1 1 auto;
  }
}
</style>

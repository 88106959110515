import { ModelAPI, objectToCamelCase } from '@thinknimble/tn-models'

import AxiosClient from '@/services/AxiosClient'

import { apiErrorHandler } from '../api'

const LOGIN_ENDPOINT = 'api/login/'
const PASSWORD_RESET_EMAIL_ENDPOINT = ''
const PASSWORD_RESET_ENDPOINT = ''
const REGISTRATION_ENDPOINT = ''
const USERS_ENDPOINT = 'api/users/'

export default class UserAccountAPI extends ModelAPI {
  /**
   * ModelAPI contains methods for list and create (overridden here) and the FILTERS_MAP
   * You may override any of these methods by statically defining them here
   * e.g static FILTERS_MAP={...UserAPI.FILTERS_MAP, <FITERS>}
   *      list({ filters = {}, pagination = {} }){
   *
   * }
   */

  get client() {
    return AxiosClient
  }

  static ENDPOINT = USERS_ENDPOINT

  login(d) {
    const data = { email: d.email.toLowerCase(), password: d.password }
    const promise = AxiosClient.post(LOGIN_ENDPOINT, data).catch((e) =>
      apiErrorHandler({
        apiName: 'Error Retrieving Asset with id',
        enable400Alert: false,
        enable401Alert: true,
        rethrowErrors: true
      })(e)
    )
    return promise
  }

  async create(data) {
    let d = this.cls.toAPI(data)
    console.log(d)
    try {
      const res = await this.client.post(`/api/v1/accounts/`, d)

      return this.cls.fromAPI(res.data.account)
    } catch (e) {
      console.log(e)
      throw e
    }
  }
  async update(firebaseToken, data) {
    let d = this.cls.toAPI(data)
    try {
      const res = await this.client.put(`/api/v1/accounts/${firebaseToken}`, d)

      return this.cls.fromAPI(res.data.account)
    } catch (e) {
      apiErrorHandler({
        apiName: 'Error retrieving user data',
        enable400Alert: false,
        enable401Alert: true,
        rethrowErrors: true
      })(e)
    }
  }
  async addLogo(firebaseToken, data) {
    let d = new FormData()
    d.append('logo', data)
    try {
      const res = await this.client.put(`/api/v1/accounts/${firebaseToken}/logo/`, d)

      return this.cls.fromAPI(res.data.account)
    } catch (e) {
      apiErrorHandler({
        apiName: 'Error retrieving user data',
        enable400Alert: false,
        enable401Alert: true,
        rethrowErrors: true
      })(e)
    }
  }

  async retrieve(firebaseToken) {
    try {
      const res = await this.client.get(`/api/v1/accounts/${firebaseToken}`)

      return this.cls.fromAPI(res.data)
    } catch (e) {
      apiErrorHandler({
        apiName: 'Error retrieving user data',
        enable400Alert: false,
        enable401Alert: true,
        rethrowErrors: true
      })(e)
    }
  }
  toMessageString(data) {
    if (typeof data === 'string' || typeof data === 'number') {
      return `<h2>${String(data)}</h2>`
    } else if (data instanceof Array) {
      return '<h2>' + data.map((i) => String(i)).join(', ') + '</h2>'
    } else if (data instanceof Object) {
      let message = ''
      for (var key in data) {
        message += '<h2>' + key + this.toMessageString(data[key]) + '</h2>'
      }
      return message
    }
  }
}

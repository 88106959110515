import { createRouter, createWebHistory, createWebHashHistory } from 'vue-router'

import { noAuthOnly, registrationRouteGuard, requireAuth } from './routeGuards'

const routes = [
  {
    path: '',
    name: 'HomePage',
    redirect: { name: 'DashboardPage' },
    beforeEnter: (to, from) => {
      return requireAuth(to, from)
    },
    meta: {
      showChat: true
    }
  },
  {
    path: '/about',
    name: 'About',
    beforeEnter: requireAuth,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About'),
    meta: {
      showChat: true
    }
  },
  {
    path: '/builder',
    name: 'BuilderPage',
    //beforeEnter: requireAuth,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "Builder" */ '../views/Builder/BuilderPage.vue'),
    meta: {
      showChat: true
    }
  },
  {
    path: '/login',
    name: 'LoginPage',
    beforeEnter: noAuthOnly,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "user" */ '../views/LoginPage'),
    meta: {
      showChat: true
    }
  },
  {
    path: '/plans',
    name: 'PlansPage',
    // embeddable iframe for marketing site
    meta: {
      showChat: false
    },
    component: () =>
      import(/* webpackChunkName: "embeded" */ '../views/Dashboard/MarketingSitePlans.vue')
  },

  {
    path: '/register/',
    name: 'RegistrationPage',
    redirect: { name: 'AuthRegistrationPage' },
    beforeEnter: registrationRouteGuard,
    children: [
      {
        path: 'auth',
        name: 'AuthRegistrationPage',
        meta: {
          showChat: true
        },
        component: () =>
          import(/* webpackChunkName: "user" */ '../views/UserAuthAccount/_RegistrationAuth.vue')
      },
      {
        path: 'account',
        name: 'AccountRegistrationPage',
        meta: {
          showChat: true
        },
        component: () =>
          import(/* webpackChunkName: "user" */ '../views/UserAuthAccount/_RegistrationAccount.vue')
      },
      {
        path: 'subscription-plan',
        name: 'SubscriptionRegistrationPage',
        meta: {
          showChat: true
        },
        component: () =>
          import(
            /* webpackChunkName: "user" */ '../views/UserAuthAccount/__RegistrationSubscription.vue'
          )
      }
    ],

    meta: {
      showChat: true
    },
    component: () =>
      import(/* webpackChunkName: "user" */ '../views/UserAuthAccount/RegistrationPage.vue')
  },

  {
    path: '/forgot-password/',
    name: 'ForgotPasswordPage',
    meta: {
      showChat: true
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "user" */ '../views/ForgotPasswordPage')
  },
  {
    path: '/reset-password/',
    name: 'ResetPasswordPage',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "user" */ '../views/ResetPasswordPage'),
    meta: {
      showChat: true
    }
  },
  {
    path: '/account',
    name: 'Account',
    beforeEnter: requireAuth,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "dashboard" */ '../views/Dashboard/__AccountPage'),
    meta: {
      showChat: true
    }
  },
  {
    path: '/brand-kit',
    name: 'BrandKitPage',
    meta: {
      showChat: true
    },
    beforeEnter: requireAuth,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/brandkit/BrandKitPage')
  },
  {
    path: '/dashboard',
    name: 'DashboardPage',
    meta: {
      showChat: true
    },
    beforeEnter: requireAuth,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.

    component: () => import(/* webpackChunkName: "about" */ '../views/Dashboard/DashboardPage.vue')
  },
  {
    path: '/preview/:rid/print/',
    name: 'PrintPreviewPage',
    meta: {
      showChat: true
    },

    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "viewer" */ '../views/viewer/PreviewReportPrintable')
  },

  {
    path: '/preview/:rid/',
    name: 'PreviewPage',
    meta: {
      showChat: false
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "viewer" */ '../views/viewer/PreviewReport')
  },
  {
    path: '/from/:userName/:reportSlug',
    name: 'ReportViewerPage',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "viewer" */ '../views/viewer/ReportViewerPage'),
    meta: {
      showChat: false
    }
  },
  {
    path: '/from/:userName/:reportSlug/print',
    name: 'PublishedReportPrintable',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "viewer" */ '../views/viewer/PublishedReportPrintable'),
    meta: {
      showChat: false
    }
  }
]

const router = createRouter({
  // 2021-11-20 William: Disabled for new deployment strategy
  // history: createWebHistory(),
  history: createWebHashHistory(),
  routes
})
router.afterEach((to) => {
  if (window.location == window.parent.location) {
    window.tidioChatApi && window.tidioChatApi.display(to.meta.showChat)
  } else {
    console.log('hide tidio')
    window.tidioChatApi.hide()
  }
})

export default router

<template>
  <div class="pulse-spinner" :class="{ white: color === 'white' }">
    <div class="pulse-spinner__a" :style="{ backgroundColor: color }"></div>
    <div class="pulse-spinner__b" :style="{ backgroundColor: color }"></div>
    <div class="pulse-spinner__c" :style="{ backgroundColor: color }"></div>
  </div>
</template>

<script>
/**
 * A horizontal 'pulse' loading component. Depends on an animations.scss file containing the appropriate
 * animation mixins.
 *
 * @prop {string} color: The color of the bouncing circles in the animation.
 *
 */
export default {
  name: 'PulseLoadingSpinner',
  props: {
    color: {
      type: String,
      default: 'black'
    }
  }
}
</script>

<style lang="scss">
// PULSE ANIMATIONS

// "Mild Pulse" - Scale the item from 100% to 140% and back
//                again over the course of two seconds.
@keyframes mild-pulse {
  0%,
  100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.4);
  }
}
@mixin mild-pulse-animation() {
  animation: mild-pulse 2s infinite;
}

// "Extreme Pulse" - Scale the item from 0% to 100% and back
//                   again over the course of 1.4 seconds.
@keyframes extreme-pulse {
  0%,
  80%,
  100% {
    transform: scale(0);
  }
  40% {
    transform: scale(1);
  }
}
@mixin extreme-pulse-animation() {
  animation: extreme-pulse 1.4s infinite ease-in-out both;
}
// END PULSE ANIMATIONS

// BOUNCE ANIMATIONS
@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes bounce-out {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
// END BOUNCE ANIMATIONS

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.pulse-spinner {
  margin: 0 auto;
  width: 4rem;
  text-align: center;

  & > div {
    // Bouncing circles
    width: 0.8rem;
    height: 0.8rem;
    border-radius: 100%;
    display: inline-block;
    @include extreme-pulse-animation();
  }

  &.white > div {
    background-color: white;
  }

  &__a {
    animation-delay: -0.32s !important;
  }

  &__b {
    animation-delay: -0.16s !important;
  }
}
</style>

<template>
  <div class="form">
    <FormField
      @input="searchImages($event.target.value)"
      iconLeft="fas fa-search"
      rounded
      placeholder="Search"
    />
  </div>
  <div class="columns is-multiline" style="margin-top: 1rem">
    <template v-if="!loadingImages">
      <div
        :key="i"
        class="column is-one-quarter-desktop is-half-tablet hover"
        v-for="(image, i) in images"
        @click="onSaveAndUse(image.urls.full)"
      >
        <div class="card is-shadowless">
          <div class="card-image">
            <figure class="image is-3by2">
              <img :src="image.urls.thumb" :alt="image.altText" style="object-fit: cover" />
            </figure>
          </div>
          <footer class="card-footer"></footer>
        </div>
      </div>
    </template>
    <div v-else class="container">
      <PulseLoadingSpinner />
    </div>
  </div>
</template>

<script>
import { onMounted, inject, ref } from 'vue'

import UnsplashAPI from '@/services/reports/api'
import FormField from '@/components/FormField'
import { debounce } from 'lodash.debounce'

export default {
  name: 'ImageListModal',
  inheritAttrs: false,
  components: { FormField },
  props: {
    showModal: {
      type: Boolean,
      default: false
    },
    lockScroll: { default: false }
  },
  emits: ['image-ready'],
  setup(_, { emit }) {
    const $vfm = inject('$vfm')
    const images = ref([])
    const loadingImages = ref(false)
    /*    const onDelayedSearch = debounce(function () {
      console.log('Function debounced after 1000ms!')
    }, 1000) */
    async function searchImages(val) {
      if (!val.length) {
        val = 'all'
      }
      await listImages({ filters: { query: val } })
    }

    async function listImages({ filters = {}, pagination = {} } = {}) {
      try {
        loadingImages.value = true
        const res = await UnsplashAPI.create().list({ filters, pagination })
        images.value = res.results
      } catch (e) {
        console.log(e)
      } finally {
        loadingImages.value = false
      }
    }
    onMounted(async () => {
      await listImages({ filters: { query: 'all' }, pagination: { perPage: 25 } })
    })
    function onSaveAndUse(url) {
      emit('image-ready', url)
    }

    return {
      listImages,
      images,
      loadingImages,
      searchImages,
      onSaveAndUse
    }
  }
}
</script>

<style lang="scss" scoped></style>

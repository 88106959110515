<template>
  <div class="box">
    <form @submit.prevent>
      <FormField
        labelText="Font File"
        :helpText="'Currently Yearly only supports .otf .ttf .woff .woff2, .tiff'"
        :required="true"
      >
        <template #field-input>
          <FileUpload
            ref="uploader"
            @fileLoaded="setFile"
            :clearContent="clearFile"
            :fileTypes="['.otf', '.ttf', '.woff', '.woff2', '.tiff']"
          />
        </template>
      </FormField>
      <FormField
        v-model:modelValue="fontForm.name.value"
        placeholder="Name"
        type="text"
        @blur="fontForm.name.validate()"
        :errors="fontForm.name.errors"
        @keyup.enter="onUpload"
        :required="true"
        labelText="Name"
      />
      <FormField
        type="text"
        @blur="fontForm.style.validate()"
        :errors="fontForm.style.errors"
        @keyup.enter="onUpload"
        :required="true"
        labelText="Type"
      >
        <template #field-input>
          <select v-model="fontForm.style.value" class="input">
            <option value="title">Title</option>
            <option value="body">Body</option>
          </select>
        </template>
      </FormField>

      <button @click="onUpload" class="button is-primary" :disabled="!fontForm.isValid">
        Save
      </button>
    </form>
  </div>
</template>

<script>
import { ref, toRefs } from 'vue'

import { FontForm } from '@/services/brandKits'

import FileUpload from '@/components/FileUpload'
import FormField from '@/components/FormField'
export default {
  components: { FormField, FileUpload },
  props: { fontName: { type: String, default: '' }, fontStyle: { type: String, default: 'BODY' } },
  emits: ['font-form-ready'],
  setup(props, { emit }) {
    const { fontName, fontStyle } = toRefs(props)
    const clearFile = ref(false)
    const file = ref(null)
    const fontForm = ref(new FontForm({ name: fontName.value, style: fontStyle.value }))
    function setFile(f) {
      if (!f) {
        fontForm.value.file.value = null
        return
      }

      /*       if (!['font/ttf', 'font/otf', 'font/woff', 'font/woff2'].includes(f.type)) {
        alert('Please Upload a font file (ttf, otf, woff, woff2)  document')
        clearFile.value = true
        return
      } */
      if (f) {
        file.value = f
        fontForm.value.file.value = f
        fontForm.value.name.value = f.name.replace(/\.[^.]*$/, '')
      }
    }
    function onUpload() {
      if (!fontForm.value.isValid) {
        fontForm.value.validate()
        return
      }

      emit('font-form-ready', fontForm.value.value)
    }
    return {
      fontForm,
      onUpload,
      clearFile,
      setFile
    }
  }
}
</script>

<style lang="scss" scoped></style>
